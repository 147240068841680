import { ApplicationRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GroupsService } from 'src/app/services/groups.service';
import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from './../../shared/auth/auth.service';

export interface UserLoggedI {
  message?: string;
  key?: string;
  status?: string;
  locationSeller?: LocationSeller;
}
export interface LocationSeller {
  marketplaceId: string;
  sellerId: string;
  accountId: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  @ViewChild('selectGroupName') selectGroupName;

  public isMobile: boolean;
  public isMenuOpened: boolean;
  @Output() toggleMenuEvent : EventEmitter<boolean> = new EventEmitter<boolean>()
  userName: string = 'Fast Admin';
  profile: string = 'null';
  groups;
  selectSeller;
  sellerNames: {
    name: string
  }
  public filteredList5;
  isAdminFastshop: boolean = false;
  companyName: string = 'undefined';
  allCompanyName; 
  selected = '';


  constructor(
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private storageService: StorageService,
    private groupsService: GroupsService,
    private router: Router
    ) {
      this.allCompanyName = this.storageService.getUserSession().locationSeller.sellerId;
      this.companyName = this.storageService.storage.getItem(`sellerId`);
      this.selected = this.companyName;
      this.userName = this.authService.getUserName();
      this.profile = this.storageService.storage.getItem(`profile`);
      var isAdmin = this.profile.indexOf("fastshop") > -1 ;
      if(isAdmin === true) {
      this.isAdminFastshop = true;
      this.groupsService.get().subscribe(
        data => {
          this.groups = data;
          this.groups = data;
          this.groups.sort(function (a,b) {
            return (a.groupName > b.groupName) ? 1 : ((b.groupName > a.groupName) ? -1 : 0);
          })
          if(this.selected === 'undefined') {
            this.selected = this.groups[0].groupName;
            this.storageService.storage.setItem('sellerId', this.selected);
          }
          this.filteredList5 = this.groups.slice();
        }
      );
      } else {
        this.isAdminFastshop = false;
        this.storageService.storage.setItem(`sellerId`, this.allCompanyName[0]);
        this.groups = this.allCompanyName;
        this.groups.sort(function (a,b) {
          return (a.groupName > b.groupName) ? 1 : ((b.groupName > a.groupName) ? -1 : 0);
        })
        this.filteredList5 = this.groups.slice();
      }
     }

  ngOnInit(): void {
    this.selected = this.storageService.storage.getItem(`sellerId`);
    this.isMobile = this.deviceService.isMobile();
  }

  setSellerID(key:UserLoggedI) {
    this.storageService.storage.setItem('sellerId', this.selectGroupName.value);
    this.storageService.storage.removeItem('webstoreActive');
    //window.location.reload();
    this.router.navigateByUrl('home/empresa');
  }

  exitToApp() {
    this.authService.logoutAndRemoveStorage();
  }

  toggleMenu(e){
    this.isMenuOpened = !this.isMenuOpened;
    this.toggleMenuEvent.emit(this.isMenuOpened);
  }

}
