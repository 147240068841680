import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupsService } from 'src/app/services/groups.service';

@Component({
  selector: 'app-gestao-pedidos',
  templateUrl: './gestao-pedidos.component.html',
  styleUrls: ['./gestao-pedidos.component.scss']
})
export class GestaoPedidosComponent implements OnInit {
  @ViewChild('selectGroupName') selectGroupName;
  sellersList;
  sellerListFiltered;

  constructor(
    private groupsService: GroupsService,
  ) {
    this.groupsService.get().subscribe(
      data => {
        this.sellersList = data;
        this.sellersList.sort(function (a, b) {
          return (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0);
        })
        this.sellerListFiltered = this.sellersList.slice();
      }
    );
  }

  ngOnInit(): void {
  }

  getStatusSellerByName() {
    var seller = this.selectGroupName.value;
    console.log(seller)

  }

  filtraLista () {
    if(this.sellerListFiltered.length === 0) {
      this.sellerListFiltered = this.sellersList;
    }
  }


}
