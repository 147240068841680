import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ie' })
export class IEPipe implements PipeTransform {
  transform(value?: string, ...args: any[]) {
    const newValue = value.replace(/\./g, '');
    const isNumber = newValue.match(/[0-9]/);
    if (isNumber) {
      if (newValue.length <= 6) {
        return newValue.replace(/(\d{3})(\d+)/, '$1.$2');
      } else if (newValue.length <= 9) {
        return newValue.replace(/(\d{3})(\d{3})(\d+)/, '$1.$2.$3');
      } else {
        return newValue.replace(/(\d{3})(\d{3})(\d{3})(\d+)/, '$1.$2.$3.$4');
      }
    } else {
      return newValue;
    }
  }
}
