import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//Modules
import {MaterialModule} from './../../../material/material.module';

//Components
import {ErrorMessageComponent} from './error-message.component';

@NgModule({
    declarations: [
      ErrorMessageComponent
    ],
    imports: [
        CommonModule,
        MaterialModule
    ]
})

export class ErrorMessageModule {}
