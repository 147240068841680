import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmarAcaoComponent } from './confirmar-acao.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmarAcaoService {

  constructor(private dialog: MatDialog) {}

  openDialog(data?: any): any {
    return this.dialog.open(ConfirmarAcaoComponent, {
      width: '500px',
      disableClose: true,
      ...(data ? { data } : {}),
    });
  }
}
