import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService, UserLoggedI } from '../../services/storage.service';
import { ParentEventEmitterService } from './../../services/parent-event-emitter.service';
import jtw_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  profile: string = 'null';

  constructor(
    private router: Router,
    private storageService: StorageService,
    private parentEventEmitterService: ParentEventEmitterService
      ) { }

  login(key: UserLoggedI) {
    var token = key.key;
    const tokenData = jtw_decode(token);
    this.storageService.setCurrentUserSession(key);
    this.storageService.storage.setItem('userName', tokenData.username);
    this.storageService.storage.setItem('sellerId', key.locationSeller.sellerId);
    this.storageService.storage.setItem('key', key.key);
    this.storageService.storage.setItem('isLoggedIn', 'true');
    this.verificaPerfilUser(tokenData['cognito:groups']);
    this.storageService.storage.setItem('profile', this.profile);
  }

   verificaPerfilUser(cognitoGroup: any){
    for (let i = 0; i < cognitoGroup.length; i++) {
      var dataArray = cognitoGroup[i];
      var isAdmin = dataArray.indexOf("seller.") > -1 || dataArray.indexOf("fastshop.") > -1;
      if(isAdmin) {
        this.profile = cognitoGroup[i];
      }
    }
  }

  getToken() {
    return window.localStorage.getItem('key');
  }

  getUserName() {
    return window.localStorage.getItem('userName');
  }

  removeToken(key: UserLoggedI) {
    window.localStorage.removeItem('key');
  }

  setUser(data: UserLoggedI) {
    this.storageService.setCurrentUserSession(data);
  }
  
  logoutAndRemoveStorage() {
    this.storageService.storage.clear();
    window.location.href = '';
    this.parentEventEmitterService.emitSignOutMessage();
    //return this.router.navigate(['']);
  }
}
