import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { ResponsavelModel } from './../models/responsavel.model';

import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ResponsaveisService {

  reset(arg0: {}, arg1: { onlySelf: boolean; emitEvent: boolean; }) {
    throw new Error("Method not implemented.");
  };

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { };

  getResponsaveis(): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(  
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/legalcontacts`,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  getResponsavelById(idResponsavel: string): Observable<any> { 
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/legalcontact/${idResponsavel}`,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  saveResponsavelPatch(responsavel: ResponsavelModel) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    if(sellerId == "undefined" || sellerId == undefined || sellerId == null){
      alert("Por favor selecione um Seller"); 
      return;
    }
    return this.http.patch( 
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/insert/legalcontact/`,
      responsavel,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  saveResponsaveisPost(responsavel: ResponsavelModel) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.post( 
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/legalcontact`,
      responsavel,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  updateResponsaveisPath(responsavel: ResponsavelModel, idResponsavel: string, idBase: string) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.patch(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/${idResponsavel}/legalcontact/${idBase}`,
      responsavel,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  deleteResponsavel(idResponsavel: number) {  
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.delete<ResponsavelModel>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/legalcontact/${idResponsavel}`,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }

  // Manipulação de erros
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Error lado do cliente
      errorMessage = error.error.message;
    } else {
      // Error lado do servidor
      errorMessage = `Código de erro: ${error.status},` + `menssagem: ${error.message}`;
    };
    console.log(errorMessage);
    return throwError(errorMessage);
  };

}
