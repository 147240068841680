import { Input } from '@angular/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ActivitiesService } from './../services/activities.service';
import { MatDialog } from '@angular/material/dialog';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IActivity } from './../interfaces/IActivity';

import { environment } from 'src/environments/environment';

import { IncluirArquivoAtividadeComponent } from '../incluir-arquivo-atividade/incluir-arquivo-atividade.component'

export class NgbdModalContent {
  @Input() name;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }
}

@Component({
  selector: 'app-atividade-detalhe',
  templateUrl: './atividade-detalhe.component.html',
  styleUrls: ['./atividade-detalhe.component.scss']
})
export class AtividadeDetalheComponent implements OnInit, OnDestroy {

  public groupCrmCode: any;
  public activityId: any;
  public activity: IActivity;

  public isEditOpen: boolean;
  public isNotasOpen: boolean;
  public formActivity: FormGroup;
  public formNote: FormGroup;

  public linkAttachment: string = '';

  public noteFullText: any;

  public statusActivityOptions = [{
    "statusId": "ENCA",
    "label": "Encaminhado",
  }, {
    "statusId": "REAV",
    "label": "Reavaliar",
  }, {
    "statusId": "ATDM",
    "label": "Em atendimento",
  },{
    "statusId": "OPEN",
    "label": "Pendente",
  },{
    "statusId": "RESL",
    "label": "Resolvido",
  }];

  private subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private activitiesService: ActivitiesService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) {
    this.linkAttachment = environment.sapCrmApi;
   }

  ngOnInit(): void {
    this.createFormActivity();
    this.createFormNote();
    this.listeningRoute();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  toggleEditActivity() {
    this.isEditOpen = !this.isEditOpen;
    this.isNotasOpen = false;
  }

  toggleNotes() {
    this.isNotasOpen = !this.isNotasOpen;
    this.isEditOpen = false;
  }

  openModalDialogAnexo() {
    const subscription = this.activatedRoute.params.subscribe(params => {
      this.groupCrmCode = params['groupCrmCode'];
      this.activityId = params['activityId'];
    });

    this.subscriptions.push(subscription);
    const dialogRef = this.dialog.open(IncluirArquivoAtividadeComponent, {
      height: '500px',
      width: '600px',
      id: 'modalEditUser',
      data: {
        groupCrmCode: this.groupCrmCode,
        activityId: this.activityId,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {this.listeningRoute();}, 2000);
    })
  }

  saveActivity() {

    if (this.formActivity.valid) {

      const formData = Object.assign(this.formActivity.value);

      formData.newNote = null;

      const data = {
        'groupCrmCode': this.groupCrmCode,
        'activityId': this.activityId,
        'formData': formData
      };

      this.activitiesService.saveActivity(data).subscribe(res => {

        console.log(res);
        this.isEditOpen = false;
        this.isNotasOpen = false;
        this.findOne(this.groupCrmCode, this.activityId);

      }, err => {

        console.log(`err`, err);

      });

    } else {

      console.log(`FORM ERROR`, this.formActivity);
    }
  }

  saveNote() {

    if (this.formNote.valid) {

      const formData = Object.assign(this.formNote.value);

      formData.newStatus = this.activity.statusId;
      formData.newDesc = this.activity.description;

      const data = {
        'groupCrmCode': this.groupCrmCode,
        'activityId': this.activityId,
        'formData': formData
      };

      this.activitiesService.saveActivity(data).subscribe(res => {

        console.log(res);
        this.isEditOpen = false;
        this.isNotasOpen = false;
        this.findOne(this.groupCrmCode, this.activityId);

      }, err => {

        console.log(`err`, err);
        // this.activities = [];

      });

    } else {

      console.log(`FORM ERROR`, this.formNote);
      // this.showInvalidFields = true;
    }
  }

  private listeningRoute() {

    const subscription = this.activatedRoute.params.subscribe(params => {
      this.groupCrmCode = params['groupCrmCode'];
      this.activityId = params['activityId'];
      this.findOne(this.groupCrmCode, this.activityId);

    });

    this.subscriptions.push(subscription);
  }

  private findOne(groupCrmCode: any, activityId: any) {

    this.activitiesService.findOne(groupCrmCode, activityId).subscribe(res => {
      this.activity = res.activity;
      // this.noteFullText = this.activity['noteFullText'].replaceAll('<br/>', '\n');
      this.noteFullText = this.activity['noteFullText']

    }, err => {
      console.log(`ERRO WHEN GET ACTIVITY`, err);
    });

  }

  private createFormActivity() {

    this.formActivity = this.formBuilder.group({
      newStatus: ['', Validators.compose([
        Validators.required
      ])],
      newDesc: ['', Validators.compose([
        // Validators.required
      ])],
    });
  }

  private createFormNote() {

    this.formNote = this.formBuilder.group({
      newNote: ['', Validators.compose([
        Validators.required
      ])]
    });
  }

}
