import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

//Modules
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material/material.module';
import { MessageErrorModule } from 'src/app/shared/components/message-error/message-error.module';

//Components
import { SigninComponent } from './signin/signin.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

@NgModule({
    declarations: [
        SigninComponent,
        NewPasswordComponent,
        ChangePasswordComponent,
        ForgotPasswordComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,    
        ReactiveFormsModule,
        RouterModule,
        MessageErrorModule
    ]
})

export class LoginModule {}
