import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ResponsaveisService } from '../../../services/responsaveis.service';
import { AuthService } from '../../auth/auth.service';
import { ErrorMessageService } from '../error-message/error-message.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-delete-contato',
  templateUrl: './delete-responsavel.component.html',
  styleUrls: ['./delete-responsavel.component.scss']
})

export class DeleteResponsavelComponent implements OnInit {

  constructor(
    @Optional() private dialogRef: MatDialogRef<DeleteResponsavelComponent>,
    @Inject(MAT_DIALOG_DATA)public data:{idResponsavel},
    private responsaveisService: ResponsaveisService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService
  ) { };

  ngOnInit(): void {};

  deleteResponsavel() {
    if(this.data.idResponsavel){
      this.deleteResponsavelById();
    }else{
      this.close();
    };
  };

  // Fecha formulario
  close() {
    this.dialogRef.close();
  };

  // Chama o serviço de todos os contatos
  deleteResponsavelById() {
    this.responsaveisService.deleteResponsavel(this.data.idResponsavel).subscribe((ResponseI) => { 
      this.errorMessageService.openDialog({
        message: 'SUCESSO',
        messageCode: 'Responsavel deletado com sucesso.'
      });
      this.dialogRef.close(true);
    },
    (error: newReponseI) => {
      if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
        this.errorMessageService.openDialog({
          message: error.error.statusCustom.accessInfo.message,
          messageCode: error.error.statusCustom.profile
        });
        this.auth.logoutAndRemoveStorage();
      }
      else if (error.status === 403) {
        this.errorMessageService.openDialog({
          message: error.error.statusCustom.accessInfo.message,
          messageCode: error.error.statusCustom.profile
        });
        this.dialogRef.close(true);
      }
      else {
        this.errorMessageService.openDialog({
          message: 'ERRO',
          messageCode: 'Ocorreu uma falha, contate o administrador.'
        });
      }
    }
    );
  };
};
