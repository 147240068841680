import { Component, OnInit, Input } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

import { ContratoDocumentosModel } from '../../models/contratos-documentos.model';
import { ContratoDocumentosService } from '../../services/contrato-documentos.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';

import * as fileSaver from 'file-saver';
import { AuthService } from 'src/app/shared/auth/auth.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number,
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

export enum statusContract {
  negociacao = 1,
  assinatura = 2,
  conclusao = 3
}

@Component({
  selector: 'app-contratos-documentos',
  templateUrl: './contratos-documentos.component.html',
  styleUrls: ['./contratos-documentos.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})

export class ContratosDocumentosComponent implements OnInit {
  contratoDocs: ContratoDocumentosModel[];
  contractStatus: string = '';
  linkDocSign = 'https://www.docusign.com.br';
  linkDocSignContract: string = '';

  allUploadsDocuments: any;
  allDocsignDocumentos: any;
  file: File;
  downloadFile: any;

  idCartaoCnpj;
  lastUpdateCnpj;
  nameSendCnpjDocument;

  idContratoSocial;
  lastUpdateSocialContract;
  nameSendSocialContractDocument;

  isInvalid: boolean = false;
  isInvalidTwo: boolean = false;


  constructor(
    private contratoDocService: ContratoDocumentosService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.getAllContractsDocnSign();
    this.getAllUploadDocuments();
  }

  getAllContractsDocnSign() {
    this.contratoDocService.getContratosDocSign().subscribe(
      (data: ContratoDocumentosModel) => {
        if(data.id !== 'undefined') {
          this.allDocsignDocumentos = data;
          for (let index = 0; index < this.allDocsignDocumentos.length; index++) {
            this.contratoDocs = data[index];
            this.contractStatus = data[index].status;
            this.linkDocSignContract = data[index].envelopeUrl;
          }
          if(this.contractStatus === 'Sent'){
            this.isInvalidTwo = true;
          }
          if (this.contractStatus === 'Completed'){
            this.isInvalidTwo = true;
            this.isInvalid = true;
          }
        }
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
        }
      }
    )

  }

  getAllUploadDocuments() {
    this.contratoDocService.getAllUploadDocuments().subscribe(
      data => {
        this.allUploadsDocuments = data;
        for (let index = 0; index < this.allUploadsDocuments.length; index++) {
          if (this.allUploadsDocuments[index].typeDocument === 'CARTAO_CNPJ') {
            this.idCartaoCnpj = this.allUploadsDocuments[index].id;
            this.lastUpdateCnpj = this.allUploadsDocuments[index].creationDate;
            if (this.lastUpdateCnpj !== null) {
              var a = this.lastUpdateCnpj.split(" ");
              this.lastUpdateCnpj = a[0];
            }
            this.nameSendCnpjDocument = this.allUploadsDocuments[index].userUpload;
          } else if (this.allUploadsDocuments[index].typeDocument === 'CONTRATO_SOCIAL') {
            this.idContratoSocial = this.allUploadsDocuments[index].id;
            this.lastUpdateSocialContract = this.allUploadsDocuments[index].creationDate;
            if (this.lastUpdateSocialContract !== null) {
              var a = this.lastUpdateSocialContract.split(" ");
              this.lastUpdateSocialContract = a[0];
            }
            this.nameSendSocialContractDocument = this.allUploadsDocuments[index].userUpload; 
          }
        }
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
        }
      }
    )
  }

  departamentSplit() {
    let a = this.lastUpdateCnpj;
    a = a.split(" ");
    console.log(a);
  }


  downloadCartaoCnpj() {
    this.contratoDocService.downloadDocuments(this.idCartaoCnpj).subscribe((response: any) => {

      let file = new Blob([response], { type: 'application/pdf' });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
        return;
      }

      var blob = window.URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = blob;
      fileSaver.saveAs(blob, 'CARTAO_CNPJ.pdf');
      this.getAllUploadDocuments();

    });
    (error: newReponseI) => {
      if (error.status === 403) {
        if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          })
          .afterClosed().subscribe(() => {
            this.auth.logoutAndRemoveStorage();
          });
        }
        else {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
        }
      }
      else {
        this.errorMessageService.openDialog({
          message: 'ERRO',
          messageCode: 'Ocorreu uma falha, contate o administrador.'
        });
      }
    }
  }

  downloadContratoSoc() {
    this.contratoDocService.downloadDocuments(this.idContratoSocial).subscribe((response) => {
      let file = new Blob([response], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
        return;
      }
      var blob = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = blob;
      fileSaver.saveAs(blob, 'CONTRATO_SOCIAL.pdf');
    this.getAllUploadDocuments();
      
    });
    (error: newReponseI) => {
      if (error.status === 403) {
        if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          })
          .afterClosed().subscribe(() => {
            this.auth.logoutAndRemoveStorage();
          });
        }
        else {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
        }
      }
      else {
        this.errorMessageService.openDialog({
          message: 'ERRO',
          messageCode: 'Ocorreu uma falha, contate o administrador.'
        });
      }
    }
  };

  uploadCartaoCpnj(event) {
    var filetype = 'CARTAO_CNPJ';
    if (event.target.files) {
      this.file = event.target.files[0];
    }
    this.contratoDocService.uploadDocuments(filetype, this.file).subscribe(
      (resp: ResponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: "Upload do Cartão CNPJ realizado com sucesso!"
        });
        this.getAllUploadDocuments();
      },
      (error: newReponseI) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.messageCode
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else if (error.status === 413) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Tamanho do arquivo excedou o limite estabelecido pelo servidor.'
          });
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      },
    );
  };

  uploadContratoSocial(event) {
    var filetype = 'CONTRATO_SOCIAL';
    if (event.target.files) {
      this.file = event.target.files[0];
      console.log(this.file);
    };
    this.contratoDocService.uploadDocuments(filetype, this.file).subscribe(
      (resp: ResponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: "Upload do Contrato Social realizado com sucesso!"
        });
        this.getAllUploadDocuments();
      },
      (error: newReponseI) => {  
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.messageCode
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else if (error.status === 413) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Tamanho do arquivo excedou o limite estabelecido pelo servidor.'
          });
        }
        else if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
          this.auth.logoutAndRemoveStorage();
        } 
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      },
    );
  };
};
