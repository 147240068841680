import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { NfeService } from '../../../services/nfe.service';
import { NfeModel } from '../../../models/nfe.model';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { AuthService } from '../../auth/auth.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-editar-nfe',
  templateUrl: './editar-nfe.component.html',
  styleUrls: ['./editar-nfe.component.scss']
})
export class EditarNfeComponent implements OnInit {
  public title: string = 'Adicionar email para nota fiscal eletrônica';
  public subTitle: string =
    'Digite o nome e o email de um responsável que irá receber a nota fiscal eletrônica da sua empresa';

  nfe = {} as NfeModel;
  nfes: NfeModel;

  hide = true;

  nfeForm: FormGroup;
  nfeModel: NfeModel;

  constructor(
    @Optional() private dialogRef: MatDialogRef<EditarNfeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { numberInclusion },
    private formBuilder: FormBuilder,
    private nfeService: NfeService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService
  ) { }

  nfe_null = []

  ngOnInit(): void {

    this.nfeService.getNfeById(this.data.numberInclusion).subscribe((nfe: any) => {
      this.nfe = nfe;
      this.f.responsible.setValue(nfe.responsible);
      this.f.email.setValue(nfe.email);
      this.f.numberInclusion.setValue(nfe.numberInclusion);
    });

    this.nfeForm = this.formBuilder.group({
      responsible: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      numberInclusion: ['']
    });
  }

  // Se o nfe sera criado ou atualizado
  saveContact() {
    if (this.nfeForm.invalid) {
      return;
    }
    if (this.nfeForm.valid) {
      const nfe_null = this.nfeForm.getRawValue() as NfeModel;
      if (this.data.numberInclusion) {
        this.update(nfe_null);
      } else {
        this.save(nfe_null);
      }
    }
  }

  save(nfe_null: NfeModel) {
    this.nfeService.saveNfe(nfe_null).subscribe((resp: ResponseI) => {
      this.errorMessageService.openDialog({
        message: 'SUCESSO',
        messageCode: 'Contato criado com sucesso.'
      });
      this.nfeForm.reset({}, { onlySelf: false, emitEvent: false });
      this.dialogRef.close(true)
    },
      (error: newReponseI) => {
        if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.accessInfo.message
          });
          this.auth.logoutAndRemoveStorage();
        }
        if (error.status === 403) {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.accessInfo.message
          });
          this.close();
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.close();
        }
      },
    );
  }

  update(nfe_null: NfeModel) {
    this.nfeService.updateNfe(nfe_null).subscribe((resp: ResponseI) => {
      this.errorMessageService.openDialog({
        message: 'SUCESSO',
        messageCode: 'Contato atualizado com sucesso.'
      });
      this.dialogRef.close(true);
    },
      (error: ResponseI) => {
        this.dialogRef.close(true);
        this.errorMessageService.openDialog({
          message: error.error.message,
          messageCode: error.error.message
        });
      }
    );
  }

  get f() {
    return this.nfeForm.controls;
  }

  disabled() {
    this.hide = false;
  }

  // Fecha formulario
  close() {
    this.dialogRef.close();
  }

  // Limpa formulario
  cleanForm(form: NgForm) {
    form.resetForm();
    this.nfe = {} as NfeModel;
  }

}
