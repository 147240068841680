import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { WebstoreI } from './../models/webstore.model';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class WebstoreService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  save(webstore: WebstoreI[]) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.post(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/webstores`,
      webstore,
      { headers: this.getNewHeaders() }
    );
  }

  update(webstore: WebstoreI[], id: String) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.put(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/webstores/${id}`,
      webstore,
      { headers: this.getNewHeaders() }
    );
  }


  activeAndDeactiveWebstore(activateSeller: Boolean, id: String, webstoreId: string) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    let activateData =
    {
      "activated": activateSeller,
    };
    return this.http.patch(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/webstores/${id}/activate/${webstoreId}`,
      activateData,
      { headers: this.getNewHeaders() }
    );
  }

  activeProducts(id: String, webstoreId: String, activateAutoSaleProduct: Boolean) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    let activateData =
    {
      "activated": activateAutoSaleProduct
    };
    return this.http.patch(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/webstores/${id}/activateAutoSaleProduct/${webstoreId}`,
      activateData,
      { headers: this.getNewHeaders() }
    );
  }

  public getWebstore(): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(`${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/webstores`,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  public getWebstoreById(id: String): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(`${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/webstores/${id}`,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }

}
