import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CommissionI } from 'src/app/models/commission.model';
import { ComissionamentoService } from 'src/app/services/comissionamento.service';
import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ConfirmarAcaoService } from 'src/app/shared/modal/confirmar-acao/confirmar-acao.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { HistoricoModalComponent } from '../historico-modal/historico-modal.component';
import { ProductBranchCategoryService } from './../../../../services/product-branch-category.service';
import * as fileSaver from 'file-saver';


interface newReponseI {
  status: number;
  error: {
    returnObject: {
      body: {
        code: number;
        message: string;
        messageCode: string;
      },
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

@Component({
  selector: 'app-comissao-webstore-detail',
  templateUrl: './comissao-webstore-detail.component.html',
  styleUrls: ['./comissao-webstore-detail.component.scss']
})

export class ComissaoWebstoreDetailComponent implements OnInit {
  logsForm: FormGroup;
  isDateStartInvalid: boolean = false;
  isDateEndInvalid: boolean = false;
  displayedColumnsComissaoCategorias: string[] = [
    'categorias',
    'comissaoFast',
    'comissaoVendedor',
    'inicioVigencia',
    'fimVigencia',
    'buttons',
  ];

  length = 100;
  pageIndex = 0;
  pageSize = 5;
  pagelength = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  @Input() typeCommission;
  typeHistoricActive: string = '';

  dataSourceComissaoByType: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginatorComissaoByType: MatPaginator;


  listSellerCommissionsData;

  webstoreActive: string = '';
  userName: string = '';
  columnActive: string = '';
  validaRegraComissao: boolean = false;

  //Upload planilha
  isAuthenticating: boolean = false;
  file: File;
  timeLoadBar: boolean = false;
  isSuccessUpload: boolean = false;
  isPublished: boolean = false;
  isErrorUpload: boolean = false;
  preview: boolean = false;
  imageUrl = "./../../../assets/svg/outline-backup-24px.svg";

  //Validação se é tela de produtos ou não
  isProduct: boolean = false;


  //filtros
  sellerListFilteredBrand = [];
  sellersListBrand = [];
  timeOut: any;

  @ViewChild('filterSku') filterSku;
  selectedSkuProduct = null;
  sellerListFilteredSku = [
    {
      itemRefId: "",
      name: "",
      skuFast: ""
    }
  ];
  sellersListSku = [
    {
      itemRefId: "",
      name: "",
      skuFast: ""
    }
  ];
  sellersListSkuInitial = [
    {
      itemRefId: "",
      name: "",
      skuFast: ""
    }
  ];

  sellerListFilteredCategory = [];
  sellersListCategory = [];
  sellersListCategoryInitial = [
    {
      codeCategory: "",
      name: ""
    }
  ];
  sellerExIdActive: string =  '';

  dataTable = [
    {        
      id: '',
      commissionMartPlace: '',
      commissionSalesMan: '',
      termStart: '',
      termEnd: '',
      brand: '',
      category: '',
      channel: '',
      dateOfExclusion: '',
      sku: '',
      skuName: '',
      type: '',
      user: '',
      webStore: '',
      buttonUpdate: true,
      buttonCancel: true
    }
  ]

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private errorMessageService: ErrorMessageService,
    private comissionamentoService: ComissionamentoService,
    private confirmarAcao: ConfirmarAcaoService,
    private router: Router,
    private storageService: StorageService,
    private dialog: MatDialog,
    private productBranchCategoryService: ProductBranchCategoryService
  ) {
    this.userName = this.auth.getUserName();
    this.webstoreActive = this.storageService.storage.getItem('webstoreActive');
    this.dataSourceComissaoByType.data = this.dataTable;

    this.productBranchCategoryService.getListSellersExId().subscribe(
      data => {
        const webstoreActive = 'MKT_' + this.webstoreActive;
        data.webStore.forEach(webstore => {
          if(webstoreActive === webstore.webstoreID) {
            this.sellerExIdActive = webstore.sellerIDHub;
          }
        });
      },
      error => {console.log(error)}
    )
  }

  ngOnInit(): void {
    this.logsForm = this.formBuilder.group({
      periodoInicio: ['', Validators.required],
      periodoFim: ['', Validators.required],
    });
    this.listSellerCommissionsData = [];
    this.buscarComissao();
    this.ColumnActive();
    this.traducaoItensPaginacao();
  }

  ngAfterViewInit() {
    this.dataSourceComissaoByType.paginator = this.paginatorComissaoByType;
  }

  goToWebstore(webstore: string) {
    this.storageService.storage.setItem('webstoreActive', webstore);
  }

  applyCommissionRule(comissao: CommissionI) {
    if(comissao.commissionMartPlace == 0 || comissao.commissionSalesMan == 0) {
      this.validaRegraComissao = false;
      return;
    }
    if (comissao.commissionSalesMan == 0 && comissao.commissionMartPlace != 0) {
      if (comissao.commissionMartPlace >= 3 && comissao.commissionMartPlace <= 50) {
        this.validaRegraComissao = true;

      } else {
        this.validaRegraComissao = false;
      }
    }
    if (comissao.commissionSalesMan != 0 && comissao.commissionMartPlace == 0) {
      if (comissao.commissionSalesMan >= 3 && comissao.commissionSalesMan <= 100) {
        this.validaRegraComissao = true;
      } else {
        this.validaRegraComissao = false;
      }
    }
    if (comissao.commissionSalesMan > 0 && comissao.commissionMartPlace > 0) {
      if (comissao.commissionSalesMan >= 3 && comissao.commissionSalesMan <= 100 && comissao.commissionMartPlace >= 3 && comissao.commissionMartPlace <= 50) {
        this.validaRegraComissao = true;

      } else {
        this.validaRegraComissao = false;
      }
    }
  }

  onSubmit() {
    if (this.logsForm.invalid) {
      console.log('form invalido')
      return;
    }
    if (this.logsForm.valid) {
      var dataInicio = this.formatDateDownload(this.logsForm.get('periodoInicio').value);
      var dataFim = this.formatDateDownload(this.logsForm.get('periodoFim').value);
      console.log("DataInicio: ", dataInicio);
      console.log("dataFim: ", dataFim);
      this.downloadComission(dataInicio, dataFim, this.webstoreActive);
    }
  }

  downloadAll() {
    this.comissionamentoService.downloadAllComission(this.webstoreActive).subscribe((response: any) => {

      let file = new Blob([response], { type: 'application/csv' });

      var blob = window.URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = blob;
      fileSaver.saveAs(blob, 'comissionamentoCompleto.csv');
    });
    (error: newReponseI) => {
      if (error.status === 403) {
        if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          })
          .afterClosed().subscribe(() => {
            this.auth.logoutAndRemoveStorage();
          });
        }
        else {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
        }
      }
      else {
        this.errorMessageService.openDialog({
          message: 'ERRO',
          messageCode: 'Ocorreu uma falha, contate o administrador.'
        });
      }
    }
  }

  dataAtualFormatada(){
    var data = new Date(),
        dia  = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0'+dia : dia,
        mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0'+mes : mes,
        anoF = data.getFullYear();
    return anoF+"-"+mesF+"-"+diaF;
}

  validarData(event) {
    var dataAtual = this.dataAtualFormatada();
    var dataInicio = this.logsForm.get('periodoInicio').value;
    this.isDateStartInvalid = false;
    this.isDateEndInvalid = false;

    //VALIDAÇÕES DATA INICIO
    if (!this.logsForm.get('periodoInicio').value) {
      if (this.logsForm.get('periodoInicio').value > this.logsForm.get('periodoFim').value) {
        if (this.logsForm.get('periodoFim').value !== '') {
          this.isDateEndInvalid = true;
        }
        this.logsForm.get('periodoInicio').setValue(null);
        return;
      }
      this.isDateStartInvalid = true;
      this.logsForm.get('periodoInicio').setValue(null);
      return;
    }

    if (this.logsForm.get('periodoInicio').value > this.logsForm.get('periodoFim').value) {
      console.log("Inicio: ",this.logsForm.get('periodoInicio').value);
      console.log("Fim: ",this.logsForm.get('periodoFim').value);
      if (this.logsForm.get('periodoFim').value !== '') {
        this.isDateEndInvalid = true;
      }
      this.logsForm.get('periodoFim').setValue(null);
      return;
    }
  }

  filtraListaBrand(event: any) {
    if (this.timeOut) {
      clearTimeout(this.timeOut)
    }
    var brand = event.searchForm.value.value;
    this.timeOut = setTimeout(() => {
      this.buscarMarca(brand)
    }, 1000)


    if (this.sellerListFilteredBrand.length === 0) {
      this.sellerListFilteredBrand = this.sellersListBrand;
    }
  }

  selectClick(item: any) {
    this.selectedSkuProduct = item;
}

  filtraListaSku(event: any) {
    if (this.timeOut) {
      clearTimeout(this.timeOut)
    }
    var sku = event.searchForm.value.value;
    this.timeOut = setTimeout(() => {
      // this.buscarProdutos(sku)
      var newWebstore = 'MKT_' + this.webstoreActive;

      if(this.sellerExIdActive.length < 1){
        this.sellerExIdActive = 'undefined'
      }
      this.productBranchCategoryService.getSku(this.sellerExIdActive, sku).subscribe(
        success => {
          if (success.content.length > 0) {
            this.sellersListSku = success.content;
            this.sellersListSku.sort(function (a, b) {
              return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
            })
            this.sellerListFilteredSku = this.sellersListSku.slice();
            console.log("this.sellerListFilteredSku : " + this.sellerListFilteredSku);
          }

        },
        (error: newReponseI) => {
          console.log(error);
        }
      )

    }, 1000)

    if (this.sellerListFilteredSku.length === 0) {
      this.sellerListFilteredSku = this.sellersListSkuInitial;
    }
  }

  selectChangeFilter() {
    console.log("Combolist tipo de comissão: " + this.typeCommission + " / " + this.columnActive);
    if (this.typeCommission === 'categoria') {
      this.buscarCategorias();
    } else if (this.typeCommission === 'marca') {
      this.buscarMarca();
    } else if (this.typeCommission === 'produto') {    
      this.buscarProdutos();
    }
  }


  filtraListaCategory(event: any) {
    if (this.timeOut) {
      clearTimeout(this.timeOut)
    }
    var category = event.searchForm.value.value;
    this.timeOut = setTimeout(() => {
      this.buscarCategorias(category)
    }, 1000)

    if (this.sellerListFilteredCategory.length === 0) {
      this.sellerListFilteredCategory = this.sellersListCategoryInitial;
    }
  }

  buscarMarca(brand?: string) {
    this.productBranchCategoryService.getBrands(brand).subscribe(
      success => {
        if (success.length !== 0) {
          this.sellersListBrand = success;
          this.sellersListBrand.sort(function (a, b) {
            return (a > b) ? 1 : ((b > a) ? -1 : 0);
          })
          this.sellerListFilteredBrand = this.sellersListBrand.slice();
        }
      },
      (error: newReponseI) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.body.messageCode
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    )
  }

  buscarProdutos(sku?: string) {
    if(this.sellerExIdActive.length < 1){
      this.sellerExIdActive = 'undefined'
    }
    this.productBranchCategoryService.getSku(this.sellerExIdActive, sku).subscribe(
      success => {
        if (success.content.length > 0) {
          this.sellersListSku = success.content;
          this.sellersListSku.sort(function (a, b) {
            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
          })
          this.sellerListFilteredSku = this.sellersListSku.slice();
          this.sellersListSkuInitial = this.sellerListFilteredSku;
        }

      },
      (error: newReponseI) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.body.messageCode
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    )
  }

  buscarCategorias(category?: string) {
    this.productBranchCategoryService.getCategoryByName(category).subscribe(
      success => {
        if (success.content.length !== 0) {
          this.sellersListCategory = success.content;
          this.sellersListCategory.sort(function (a, b) {
            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
          })
          this.sellerListFilteredCategory = this.sellersListCategory.slice();
        }
      },
      (error: newReponseI) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.body.messageCode
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    )
  }

  buscarComissao() {
    this.comissionamentoService.getCommissionByWebstore(this.webstoreActive).subscribe(
      success => {
        this.listSellerCommissionsData = success.returnObject;
        if (this.typeCommission === 'categoria') {
          this.dataSourceComissaoByType.data = this.listSellerCommissionsData.listCategoryCommissions;
          this.dataSourceComissaoByType.data.forEach(e => {
            e.buttonUpdate = true;
            e.buttonCancel = true;
            if (e.termStart !== null) {
              e.termStart = this.formatDate(e.termStart);
              e.termEnd = this.formatDate(e.termEnd);
            }
          });
          this.typeHistoricActive = 'CATEGORY';
          this.buscarCategorias();
        }
        else if (this.typeCommission === 'marca') {
          this.dataSourceComissaoByType.data = this.listSellerCommissionsData.listBrandCommissions;
          this.dataSourceComissaoByType.data.forEach(e => {
            e.buttonUpdate = true;
            e.buttonCancel = true;
            if (e.termStart !== null) {
              e.termStart = this.formatDate(e.termStart);
              e.termEnd = this.formatDate(e.termEnd);
            }
          });
          this.typeHistoricActive = 'BRAND';
          this.buscarMarca();
        }
        else if (this.typeCommission === 'produto') {
          this.isProduct = true;
          this.dataSourceComissaoByType.data = this.listSellerCommissionsData.listProductCommissions;
          this.dataSourceComissaoByType.data.forEach(e => {
            e.buttonUpdate = true;
            e.buttonCancel = true;
            if (e.termStart !== null) {
              e.termStart = this.formatDate(e.termStart);
              e.termEnd = this.formatDate(e.termEnd);
            }
          });
          this.typeHistoricActive = 'PRODUCT';
          this.buscarProdutos();
        }
        else {
          this.listSellerCommissionsData = [];
          this.dataSourceComissaoByType.data = [];
        }
      },
      (error: newReponseI) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.body.messageCode
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceComissaoByType.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceComissaoByType.paginator) {
      this.dataSourceComissaoByType.paginator.firstPage();
    }
  }

  formatDateDownload(date: any) {
    var dataSplit = date.split('');
    var ano = dataSplit[0] + dataSplit[1] + dataSplit[2] + dataSplit[3];
    var mes = dataSplit[5] + dataSplit[6];
    var dia = dataSplit[8] + dataSplit[9];
    var dataFormatada = dia + '/' + mes + '/' + ano;
    date = dataFormatada;
    return date;
  }

  formatDate(date: any) {
    var dataSplit = date.split('');
    var dia = dataSplit[0] + dataSplit[1];
    var mes = dataSplit[2] + dataSplit[3];
    var ano = dataSplit[4] + dataSplit[5] + dataSplit[6] + dataSplit[7];
    var dataFormatada = ano + '-' + mes + '-' + dia;
    date = dataFormatada;
    return date;
  }

  revertDate(comissao: CommissionI) {
    var dataStartSplit = comissao.termStart.split('-');
    var diaStart = dataStartSplit[2];
    var mesStart = dataStartSplit[1];
    var anoStart = dataStartSplit[0];
    comissao.termStart = diaStart + mesStart + anoStart;
    var dataEndSplit = comissao.termEnd.split('-');
    var diaEnd = dataEndSplit[2];
    var mesEnd = dataEndSplit[1];
    var anoEnd = dataEndSplit[0];
    comissao.termEnd = diaEnd + mesEnd + anoEnd;
    return comissao;
  }

  addItem() {
    if (this.typeCommission === 'categoria') {
      this.listSellerCommissionsData.listCategoryCommissions.unshift(
        {
          id: null,
          commissionMartPlace: 0,
          commissionSalesMan: 100,
          termStart: null,
          termEnd: null,
          brand: null,
          category: null,
          channel: null,
          dateOfExclusion: null,
          sku: null,
          skuName: null,
          type: 'CATEGORY',
          user: this.userName,
          webStore: this.webstoreActive,
          buttonUpdate: false,
          buttonCancel: false
        }
      )
      this.dataSourceComissaoByType.data = this.listSellerCommissionsData.listCategoryCommissions;
    }
    else if (this.typeCommission === 'marca') {
      this.listSellerCommissionsData.listBrandCommissions.unshift(
        {
          id: null,
          commissionMartPlace: 0,
          commissionSalesMan: 100,
          termStart: null,
          termEnd: null,
          brand: null,
          category: null,
          channel: null,
          dateOfExclusion: null,
          sku: null,
          skuName: null,
          type: 'BRAND',
          user: this.userName,
          webStore: this.webstoreActive,
          buttonUpdate: false,
          buttonCancel: false
        }
      )
      this.dataSourceComissaoByType.data = this.listSellerCommissionsData.listBrandCommissions;
    }
    else if (this.typeCommission === 'produto') {
      this.listSellerCommissionsData.listProductCommissions.unshift(
        {
          id: null,
          commissionMartPlace: 0,
          commissionSalesMan: 100,
          termStart: null,
          termEnd: null,
          brand: null,
          category: null,
          channel: null,
          dateOfExclusion: null,
          sku: null,
          skuName: null,
          type: 'PRODUCT',
          user: this.userName,
          webStore: this.webstoreActive,
          buttonUpdate: false,
          buttonCancel: false
        }
      )
      this.dataSourceComissaoByType.data = this.listSellerCommissionsData.listProductCommissions;
    }
  }

  downloadComission(dataInicio: string, dataFim: string, webStore: string) {
    this.comissionamentoService.downloadComission(dataInicio, dataFim, webStore).subscribe((response: any) => {

      let file = new Blob([response], { type: 'application/csv' });

      var blob = window.URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = blob;
      fileSaver.saveAs(blob, 'comissionamento.csv');
    });
    (error: newReponseI) => {
      if (error.status === 403) {
        if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          })
          .afterClosed().subscribe(() => {
            this.auth.logoutAndRemoveStorage();
          });
        }
        else {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
        }
      }
      else {
        this.errorMessageService.openDialog({
          message: 'ERRO',
          messageCode: 'Ocorreu uma falha, contate o administrador.'
        });
      }
    }
  }

  onSelectFile(e) {
    this.isAuthenticating = true;
    this.timeLoadBar = true;
    this.isErrorUpload = false;
    this.isSuccessUpload = false;
    if (e.target.files) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
        this.preview = true;
        this.imageUrl = event.target.result;
        this.isAuthenticating = false;
      }
      setTimeout(() => { this.patchUploadContingencyButton(e); }, 800);
    }
  }
  patchUploadContingencyButton(event) {
    if (event.target.files) {
      this.file = event.target.files[0];
    };
    this.timeLoadBar = true;
    setTimeout(() => {
      this.timeLoadBar = false;
      this.isSuccessUpload = true;
    }, 2000);
  };

  ColumnActive() {
    if (this.typeCommission === 'categoria') {
      this.columnActive = 'categoria';
    }
    else if (this.typeCommission === 'marca') {
      this.columnActive = 'marca';
    }
    else if (this.typeCommission === 'produto') {
      this.columnActive = 'produto';
    }
  }

  uploadContratoSocial(event) {
    if (event.target.files) {
      this.file = event.target.files[0];
      console.log(this.file);
    };
    this.comissionamentoService.uploadDocuments(this.file, this.webstoreActive).subscribe(
      (resp: ResponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: "Arquivo está sendo processado e o resultado será enviado para o e-mail do usuário solicitante do upload. O resultado do processamento também poderá ser consultado na tela de Log do menu Admin."
        });
        this.buscarComissao();
      },
      (error: newReponseI) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.messageCode
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else if (error.status === 413) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Tamanho do arquivo excedou o limite estabelecido pelo servidor.'
          });
        }
        else if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
          this.auth.logoutAndRemoveStorage();
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      },
    );
  };


  firtColumn(row: any) {
    if (this.typeCommission === 'categoria') {
      return row.category;
    }
    else if (this.typeCommission === 'marca') {
      return row.branch;
    }
    else if (this.typeCommission === 'produto') {
      return row.product;
    }
  }

  copyItem(event: CommissionI, index: number) {
    this.dataSourceComissaoByType.data.splice(
      index + 1, 0,
      {
        id: '',
        commissionMartPlace: event.commissionMartPlace,
        commissionSalesMan: event.commissionSalesMan,
        termStart: event.termStart,
        termEnd: event.termEnd,
        brand: event.brand,
        category: event.category,
        channel: event.channel,
        dateOfExclusion: '',
        sku: event.sku,
        skuName: event.skuName,
        type: event.type,
        user: this.userName,
        webStore: this.webstoreActive,
        buttonUpdate: false,
        buttonCancel: false
      }
    )
    this.dataSourceComissaoByType.data = this.dataSourceComissaoByType.data;
  }

  confirmarExclusaoModal(comissao: CommissionI) {
    const dialog = this.confirmarAcao.openDialog({
      title: 'Exclusão de comissão',
      message: 'Deseja realmente excluir está comissão?'
    });

    dialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.excluirComissao(comissao.id);
      }
    });
  }

  excluirComissao(id: string) {
    this.comissionamentoService.deleteCommission(id).subscribe(
      success => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Comissão excluída com sucesso.'
        });
        this.buscarComissao();
      },
      error => {
        this.buscarComissao();
      }
    )
  }

  openDialog(type: string, description: string, skuName?: string): void {
    const dialogRef = this.dialog.open(HistoricoModalComponent, {
      width: '1100px',
      data: {
        type,
        description,
        skuName
      },
    });
  }

  createOrEditComission(comissao: CommissionI) {
    if (comissao.type === "PRODUCT" && this.selectedSkuProduct !== null) {
      if (comissao.sku === this.selectedSkuProduct.skuFast) {
        comissao.skuName = this.selectedSkuProduct.name;
      }
    }

    if (comissao.id === "" || comissao.id === null || comissao.id === undefined) {
      this.criarComissao(comissao);
    } else {
      this.editarComissao(comissao);
    }
  }


  criarComissao(comissao: CommissionI) {
    this.applyCommissionRule(comissao);
    this.revertDate(comissao);
    if (!this.validaRegraComissao) {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Percentual praticado deve estar entre 3% a 50%.'
      });
      return;
    }
    if (comissao.brand === '' && comissao.type === "BRAND") {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor, selecione uma marca'
      });
      return;
    }
    if (comissao.category === '' && comissao.type === "CATEGORY") {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor, selecione uma categoria'
      });
      return;
    }
    if (comissao.sku === '' && comissao.type === "PRODUCT") {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor, selecione um produto'
      });
      return;
    }
    this.comissionamentoService.saveCommission(comissao).subscribe(
      success => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Comissão adicionada com sucesso.'
        });
        this.buscarComissao();
      },
      (error) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
            this.buscarComissao();
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.buscarComissao();
        }
      }
    )
  }

  editarComissao(comissao: CommissionI) {
    this.applyCommissionRule(comissao);
    this.revertDate(comissao);
    if (!this.validaRegraComissao) {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Percentual praticado deve estar entre 3% a 50%.'
      });
      this.buscarComissao();
      return;
    }
    if (comissao.brand === '' && comissao.type === "BRAND") {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor, selecione uma marca'
      });
      return;
    }
    if (comissao.category === '' && comissao.type === "CATEGORY") {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor, selecione uma categoria'
      });
      return;
    }
    if (comissao.sku === '' && comissao.type === "PRODUCT") {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor, selecione um produto'
      });
      return;
    }
    this.comissionamentoService.editCommission(comissao).subscribe(
      success => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Comissão atualizada com sucesso.'
        });
        this.buscarComissao();
      },
      (error) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject
          });
          this.buscarComissao();
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
            this.buscarComissao();
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.buscarComissao();
        }
      }
    )
  }

  changeValue(event: Event, comissao?: CommissionI) {
    // if(comissao.termStart !== null && comissao.termEnd !== null) {
    //   this.errorMessageService.openDialog({
    //     message: 'Atenção',
    //     messageCode: 'A criação e/ou atualização do comissionamento só será efetivada após a ação no campo Vigência (Fim).'
    //   });
    // }
    // return event.toString().replace(".", ",");
    comissao.buttonCancel = false;
    comissao.buttonUpdate = false;
  }

  confirmarUpdateModal(comissao: CommissionI) {
    const dialog = this.confirmarAcao.openDialog({
      title: 'Confirmar atualização',
      message: 'Deseja realmente atualizar esta comissão?'
    });

    dialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.createOrEditComission(comissao);
      }
    });
  }

  confirmarCancelModal(comissao: CommissionI) {
    const dialog = this.confirmarAcao.openDialog({
      title: 'Cancelar alteração',
      message: 'Deseja realmente cancelar a alteração?'
    });

    dialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.buscarComissao();
      }
    });
  }

  traducaoItensPaginacao() {
    this.paginatorComissaoByType._intl.firstPageLabel = 'Primeira página';
    this.paginatorComissaoByType._intl.lastPageLabel = 'Última página';
    this.paginatorComissaoByType._intl.nextPageLabel = 'Proxima página';
    this.paginatorComissaoByType._intl.previousPageLabel = 'Página anterior';
    this.paginatorComissaoByType._intl.itemsPerPageLabel = 'Itens por página';
  }

}
