import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray } from '@angular/forms';

//MODEL
import { UsuarioModel } from '../../../models/usuario.model';

//SERVICES
import { UsuarioService } from '../../../services/usuario.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { AuthService } from '../../auth/auth.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      body: {
        code: number;
        message: string;
        messageCode: string;
      }
      code?: number;
      message?: string;
      messageCode?: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-adicionar-usuario',
  templateUrl: './adicionar-usuario.component.html',
  styleUrls: ['./adicionar-usuario.component.scss']
})

export class AdicionarUsuarioComponent implements OnInit {

  selectedValue: string;
  selectedCar: string;

  user = {} as UsuarioModel;

  hide = true;
  submitted = false;

  usuarioForm: FormGroup;
  roles: FormArray;
  objetcUser: any;

  constructor(
    @Optional() private dialogRef: MatDialogRef<AdicionarUsuarioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id },
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService
  ) { };

  usuario: any = {};
  usuarios: any = {};
  objetcUser: any = {};
  profiles = [];

  ngOnInit(): void {

    this.usuarioForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      roles: this.formBuilder.array([])
    });

    // Get all profiles
    this.getProfiles();

    // Get object id
    this.usuarioService.getUsers().subscribe((usuarios: any) => {
      this.usuarios = usuarios;
      this.objetcUser = usuarios.returnObject.id;
    });

    // Cada addItem é um select do template/modal Adicionar Usuarios
    this.addItem("Cadastro de Seller");
    this.addItem("Cadastro do Produto");
    this.addItem("Gestão de Pedidos");
    this.addItem("Gestão de Financeira");
  }

  getProfiles() {
    this.usuarioService.getProfilesUsers().subscribe((data: any) => {
      let dataFilter = [];
      data.forEach(e => {
        dataFilter.push(
          {
            function: e.function,
            profiles: e.profiles.filter(p=> p.profile.includes('seller.'))
          }
        );
      });
      this.f.roles.setValue(dataFilter);
    },
    error => {
      if (error.status === 400) {
        if(error.error.returnObject.body) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.body.messageCode
          });
        } else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.messageCode
          });
        }
      }
      if (error.status === 403) {
        if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          })
          .afterClosed().subscribe(() => {
            this.auth.logoutAndRemoveStorage();
          });
        }
        else {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
        }
      }
    }
    );
  };

  // getters de conveniência para acesso fácil aos campos do formulário
  get f() { return this.usuarioForm.controls; };
  get t() { return this.f.roles as FormArray; };


  isObjectEmpty(object): boolean {
    return Object.keys(object).length === 0
  }

  createItem(name: string): FormGroup {
    return this.formBuilder.group({
      function: [name],
      profiles: [],
    });
  };

  addItem(name: string): void {
    this.roles = this.usuarioForm.get('roles') as FormArray;
    this.roles.push(this.createItem(name));
  };

  get rolesGetter() {
    return this.usuarioForm.get('roles') as FormArray;
  };

  onSubmit() {
    this.submitted = true;
    // Para a execução caso o formulário seja invalido
    if (this.usuarioForm.invalid) {
      return;
    };
  };

  // Se o usuario sera criado ou atualizado
  saveContact() {
    if (this.usuarioForm.invalid) {
      return;
    };
    if (this.usuarioForm.valid) {
      const usuario = this.usuarioForm.value;
      usuario.roles.forEach(role => {
        role.profiles = role.profiles.map((profile) => {
          return {
            profile: profile.profile,
            description: profile.description,
            selected: profile.selected
          };
        });
      });
      if (usuario.id) {
        console.log('Update ?');
      } else {
        if (this.isObjectEmpty(this.usuarios)) {
          this.savePost(usuario, this.objetcUser);
        } else {
          this.savePatch(usuario, this.objetcUser);
        }
      };
    };
  };

  savePatch(usuario: UsuarioModel, idObjetcUser) {
    this.usuarioService.saveUserPatch(usuario, idObjetcUser).subscribe(
      (resp: newReponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Usuário adicionado com sucesso.'
        });
        this.close();
      },
      (error: newReponseI) => {
        if (error.status === 400) {
          if(error.error.returnObject.body) {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: error.error.returnObject.body.messageCode
            });
          } else {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: error.error.returnObject.messageCode
            });
          }
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
          this.close();
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.close();
        }
      },
    );
  };

  savePost(usuario: UsuarioModel, idObjetcUser) {
    this.usuarioService.saveUserPost(usuario, idObjetcUser).subscribe(
      (resp: newReponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Usuário adicionado com sucesso.'
        });
        this.dialogRef.close(true);
      },
      (error: newReponseI) => {
        if (error.status === 400) {
          if(error.error.returnObject.body) {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: error.error.returnObject.body.messageCode
            });
          } else {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: error.error.returnObject.messageCode
            });
          }
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
          this.close();
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.close();
        }
      },
    );
  };

  disabled() {
    this.hide = false;
  };

  // Fecha formulario
  close() {
    this.dialogRef.close();
  };

  // Limpa formulario
  cleanForm(form: NgForm) {
    form.resetForm();
    this.user = {} as UsuarioModel;
  };

  selectRole(e, i) {
    const value = e.value;
    const rolesFormArray = this.usuarioForm.get('roles') as FormArray

    // Qual dos roles estamos mexendo
    rolesFormArray.controls[i].value.profiles.forEach(profile => {
      if(profile.description === value){
        profile.selected = true;  
      }else{
        profile.selected = false;
      }
    });
  };
};
