import { Component, Inject, OnInit, Optional } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { MessageDataI } from './error-message.service';



@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent  {
  code: number;
  messageCode: string;
  mensagens;
  warning: boolean;
  message: string;
  title: {
    icon: string;
  };

  constructor(
    @Optional() public dialogRef: MatDialogRef<ErrorMessageComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public modalData: MessageDataI,
    @Optional() @Inject(MAT_SNACK_BAR_DATA) public snackData: MessageDataI,
  ) {
    const data: MessageDataI = modalData || snackData;
    this.title = data.title;
    this.messageCode = data.messageCode;
    this.message = data.message.trim();
    var mensagem = this.messageCode;
    this.mensagens = mensagem.split(';');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
