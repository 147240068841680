import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';


import { StatusSellersI } from './../../models/statusSellers.model';
import { StorageService } from 'src/app/services/storage.service';
import { StatusSellersService } from 'src/app/services/status-sellers.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { GroupsService } from 'src/app/services/groups.service';
import { ParentEventEmitterService } from 'src/app/services/parent-event-emitter.service';
import { EvaluationSummaryService } from 'src/app/services/evaluation-summary.service';
import * as fileSaver from 'file-saver';

export interface Product {
  nome: string,
  status: string,
  falhas: string,
  registrationDate: string,
}

interface newReponseI {
  status: number,
  error: {
    error: string,
    returnObject: {
      body?: {
        code: number;
        message: string;
        messageCode: string;
      },
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}


@Component({
  selector: 'app-painel',
  templateUrl: './painel.component.html',
  styleUrls: ['./painel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PainelComponent implements OnInit {
  displayedColumns: string[] = [
    'nameSeller',
    'status',
    'falhas',
    'dataCadastro',
    'buttons',
  ];
  controlSearch = new FormControl();
  filteredOptions;

  sellersData: StatusSellersI;
  sellersDataTable: any;

  active: number = 0;
  inRegistrationProcess: number = 0;
  inactive: number = 0;
  integrationFailure: number = 0;
  preRegistration: number = 0;
  totalRegisteredSellers: number = 0;

  sellersList;
  sellerListFiltered;
  sellerId: string = '';
  logoName;
  imageUrl = "./../../../assets/svg/outline-backup-24px.svg";

  preview: boolean = false;
  isAuthenticating: boolean = false;
  file: File;
  timeLoadBar: boolean = false;
  isSuccessUpload: boolean = false;
  isPublished: boolean = false;
  isErrorUpload: boolean = false;

  @ViewChild('selectGroupName') selectGroupName;

  filterStatus: string = '';

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataAtual: string = '';


  constructor(
    private storageService: StorageService,
    private auth: AuthService,
    private errorMessageService: ErrorMessageService,
    private statusSellersService: StatusSellersService,
    private groupsService: GroupsService,
    private router: Router,
    private parentEventEmitterService: ParentEventEmitterService,
    private evaluationSummaryService: EvaluationSummaryService
  ) {
    this.groupsService.get().subscribe(
      data => {
        this.sellersList = data;
        this.sellersList.sort(function (a, b) {
          return (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0);
        })
        this.sellerListFiltered = this.sellersList.slice();
      }
    );
  }

  onSelectFile(e) {
    this.isAuthenticating = true;
    this.timeLoadBar = true;
    this.isErrorUpload = false;
    this.isSuccessUpload = false;
    if (e.target.files) {
      this.file = e.target.files[0];
      this.logoName = this.file.name.substring(0,66);
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
        this.preview = true;
        this.imageUrl = event.target.result;
        this.isAuthenticating = false;
      }
      setTimeout(() => { this.patchUploadContingencyButton(e); }, 800);
    }
  }
  patchUploadContingencyButton(event) {
    if (event.target.files) {
      this.file = event.target.files[0];
    };
    this.timeLoadBar = true;
    setTimeout(() => {
      this.timeLoadBar = false;
      this.isSuccessUpload = true;
    }, 2000);
  };

  length = 100;
  pageIndex = 0;
  pageSize = 10;
  pagelength = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  dataAtualFormatada(){
    var data = new Date(),
        dia  = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0'+dia : dia,
        mes  = (data.getMonth()+1).toString(),
        mesF = (mes.length == 1) ? '0'+mes : mes,
        anoF = data.getFullYear();
    return diaF+'_'+mesF+'_'+anoF;
}

  downloadListSellers(){
    console.log('baixando lista de sellers')
    this.dataAtual = this.dataAtualFormatada();
    console.log('painel_seller_'+this.dataAtual +'.csv');
    this.statusSellersService.downloadListSellers().subscribe((response: any) => {

      let file = new Blob([response], {
        type: 'application/csv'
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
        return;
      }

      var blob = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = blob;
      fileSaver.saveAs(blob, 'painel_seller_'+this.dataAtual +'.csv');

      (error: newReponseI) => {
        if (error.error.error === 'Internal Server Error') {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        } 
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        };
      }
    },
    error => {
      console.log(error)
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Ocorreu uma falha, contate o administrador.'
      });
    }
    );
  }

  filtraLista() {
    if (this.sellerListFiltered.length === 0) {
      this.sellerListFiltered = this.sellersList;
    }
  }

  atualizar(event: PageEvent) {
    console.log(event);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.getDataInitial();
    this.paginator._intl.firstPageLabel = 'Primeira página';
    this.paginator._intl.lastPageLabel = 'Última página';
    this.paginator._intl.nextPageLabel = 'Proxima página';
    this.paginator._intl.previousPageLabel = 'Página anterior';
    this.paginator._intl.itemsPerPageLabel = 'Itens por página';
  }

  showAllProducts() {
    const filter = () => true;
    this.createFilterPreticate(filter);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  selectSeller(sellerId: string) {
    this.storageService.storage.setItem('sellerId', sellerId);
    this.storageService.storage.removeItem('webstoreActive');
    this.router.navigate(['home/webstore']);
    this.parentEventEmitterService.emitChangeSellerIdMessage(sellerId);
  }

  getStatusSellerByName() {
    this.sellerId = this.selectGroupName.value;

    var pageIndex = this.pageIndex;
    var pageSize = this.pageSize;
    var filterStatus = '';
    this.filterStatus = filterStatus;
    this.statusSellersService.get(filterStatus, this.sellerId, pageIndex, pageSize).subscribe(
      (data: StatusSellersI) => {
        this.sellersData = data;

        this.active = this.sellersData.active;
        this.inRegistrationProcess = this.sellersData.inRegistrationProcess;
        this.inactive = this.sellersData.inactive;
        this.integrationFailure = this.sellersData.integrationFailure;
        this.preRegistration = this.sellersData.preRegistration;
        this.totalRegisteredSellers = this.sellersData.totalRegisteredSellers;
        if (this.sellersData.listSeller !== null) {
          this.sellersDataTable = this.sellersData.listSeller;
          this.dataSource.data = this.sellersDataTable;
        } else {
          this.sellersDataTable = [];
          this.dataSource.data = this.sellersDataTable;
        }
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    );
  }

  checkStatusSellers() {
    this.pagelength = this.totalRegisteredSellers;
  }



  getStatusSellers(filterStatus: string, name: string, pageSize: number) {
    var pageIndex = this.pageIndex;
    //var pageSize = this.pageSize;
    this.filterStatus = filterStatus;
    if (pageSize === 0) {
      pageSize = 10;
    }
    this.statusSellersService.get(filterStatus, name, pageIndex, pageSize).subscribe(
      (data: StatusSellersI) => {
        this.sellersData = data;

        this.active = this.sellersData.active;
        this.inRegistrationProcess = this.sellersData.inRegistrationProcess;
        this.inactive = this.sellersData.inactive;
        this.integrationFailure = this.sellersData.integrationFailure;
        this.preRegistration = this.sellersData.preRegistration;
        this.totalRegisteredSellers = this.sellersData.totalRegisteredSellers;

        if (this.sellersData.listSeller !== null) {
          this.sellersDataTable = this.sellersData.listSeller;
          this.dataSource.data = this.sellersDataTable;
        } else {
          this.sellersDataTable = [];
          this.dataSource.data = this.sellersDataTable;
        }

      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    );
  }



  getStatusSellersInitial(filterStatus: string, name: string, pageSize: number) {
    var pageIndex = this.pageIndex;
    //var pageSize = this.pageSize;
    this.filterStatus = filterStatus;
    if (pageSize === 0) {
      pageSize = 100;
    }
    this.statusSellersService.get(filterStatus, name, pageIndex, pageSize).subscribe(
      (data: StatusSellersI) => {
        this.sellersData = data;

        this.active = this.sellersData.active;
        this.inRegistrationProcess = this.sellersData.inRegistrationProcess;
        this.inactive = this.sellersData.inactive;
        this.integrationFailure = this.sellersData.integrationFailure;
        this.preRegistration = this.sellersData.preRegistration;
        this.totalRegisteredSellers = this.sellersData.totalRegisteredSellers;

        if (this.sellersData.listSeller !== null) {
          this.sellersDataTable = this.sellersData.listSeller;
          this.dataSource.data = this.sellersDataTable;
        } else {
          this.sellersDataTable = [];
          this.dataSource.data = this.sellersDataTable;
        }

      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    );
  }

  getDataInitial() {
    this.getStatusSellers(this.filterStatus, this.sellerId, this.pageSize);
    this.getStatusSellersInitial('totalRegisteredSellers', '', this.totalRegisteredSellers);
  }

  filterByData(value: string) {
    const filterPredicate = (data: any, filter: string) => !filter || data.nome === filter || data.status === filter || data.falhas === filter || data.registrationDate === filter;
    this.createFilterPreticate(filterPredicate);
    this.dataSource.filter = value;
  }

  createFilterPreticate(validationFunctions: (data: any, filter: string) => boolean) {
    this.dataSource.filterPredicate = validationFunctions;
  }

  uploadAvaliacaoREC(){
    this.timeLoadBar = true;
    this.isErrorUpload = false;
    this.isSuccessUpload = false;
    this.evaluationSummaryService.putUploadEvaluation(this.file).subscribe(
      resp => {
           this.errorMessageService.openDialog({
             message: 'SUCESSO',
             messageCode: "Upload realizado com sucesso !"
           }); 
          this.timeLoadBar = false;
          this.isSuccessUpload = true;
      },
      (error: newReponseI) => {
        this.timeLoadBar = true;
        setTimeout(() => {
          this.timeLoadBar = false;
          this.isErrorUpload = true;
          this.isSuccessUpload = false;
        }, 2000);
        if (error.error.error === 'Internal Server Error') {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
        else if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: error.error.returnObject.message,
            messageCode: error.error.returnObject.messageCode
          });
        }

        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        };
      },
    );
  }
}
