import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { ParametrizacaoService } from './../../services/parametrizacao.service';

interface newReponseI {
  status: number,
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-parametrizacao',
  templateUrl: './parametrizacao.component.html',
  styleUrls: ['./parametrizacao.component.scss']
})
export class ParametrizacaoComponent implements OnInit {
  // integradores = [
  //   {
  //     "name":"Nenhum integrador encontrado",
  //     "timeOut":'undefined'
  //   }
  // ];
  // integradoresInitial;

  // parametrizationId: string = '';
  // nameIntegrator: string = '';

  constructor(
    // private parametrizacaoService: ParametrizacaoService,
    // private errorMessageService: ErrorMessageService,
    // private auth: AuthService
  ) { }

  ngOnInit(): void {
    // this.buscaIntegradores();
  }

  // buscaIntegradoresInitial() {
  //   this.parametrizacaoService.getIntegradores().subscribe(
  //     data => {
  //       if(data.length !== 0) {
  //         this.integradoresInitial = data[0].integrations;
  //       }
  //     },
  //     (error: newReponseI) => {
  //       if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
  //         this.errorMessageService.openDialog({
  //           message: error.error.statusCustom.accessInfo.message,
  //           messageCode: error.error.statusCustom.profile
  //         });
  //         this.auth.logoutAndRemoveStorage();
  //       }
  //       if (error.status === 403) {
  //         this.errorMessageService.openDialog({
  //           message: error.error.statusCustom.accessInfo.message,
  //           messageCode: error.error.statusCustom.profile
  //         });
  //       }
  //       else {
  //         this.errorMessageService.openDialog({
  //           message: 'ERRO',
  //           messageCode: 'Ocorreu uma falha, contate o administrador.'
  //         });
  //       }
  //     },
  //   )
  // }


  // buscaIntegradores() {
  //   this.parametrizacaoService.getIntegradores().subscribe(
  //     data => {
  //       if(data.length !== 0) {
  //         this.integradores = data[0].integrations;
  //         this.integradoresInitial = data[0].integrations;
  //         this.parametrizationId =  data[0].id;
  //       }
  //     },
  //     (error: newReponseI) => {
  //       if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
  //         this.errorMessageService.openDialog({
  //           message: error.error.statusCustom.accessInfo.message,
  //           messageCode: error.error.statusCustom.profile
  //         });
  //         this.auth.logoutAndRemoveStorage();
  //       }
  //       if (error.status === 403) {
  //         this.errorMessageService.openDialog({
  //           message: error.error.statusCustom.accessInfo.message,
  //           messageCode: error.error.statusCustom.profile
  //         });
  //       }
  //       else {
  //         this.errorMessageService.openDialog({
  //           message: 'ERRO',
  //           messageCode: 'Ocorreu uma falha, contate o administrador.'
  //         });
  //       }
  //     },
  //   )
  // }

  // changeValue(integrador: any, timeOutChange: string) {
  //   this.buscaIntegradoresInitial();
  //   if(integrador !== undefined) {
  //     this.nameIntegrator = integrador.name;
  //     var integradorSelecionado; 
  //     for (let index = 0; index < this.integradoresInitial.length; index++) {
  //       if (this.integradores[index].name === integrador.name) {
  //         integradorSelecionado = this.integradoresInitial[index];
  //       }
  //     }

  //     if (integradorSelecionado.timeOut === timeOutChange) {
  //       console.log('valores iguais')
  //     } else {
  //       this.parametrizacaoService.updateTimeOut(this.parametrizationId, this.nameIntegrator, timeOutChange).subscribe(
  //         success => {
  //           this.errorMessageService.openDialog({
  //             message: 'SUCESSO',
  //             messageCode: 'Parâmetro atualizado com sucesso.'
  //           });
  //           this.buscaIntegradores();
  //         },
  //         (error: newReponseI) => {
  //           if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
  //             this.errorMessageService.openDialog({
  //               message: error.error.statusCustom.accessInfo.message,
  //               messageCode: error.error.statusCustom.profile
  //             });
  //             this.auth.logoutAndRemoveStorage();
  //           }
  //           if (error.status === 403) {
  //             this.errorMessageService.openDialog({
  //               message: error.error.statusCustom.accessInfo.message,
  //               messageCode: error.error.statusCustom.profile
  //             });
  //             this.buscaIntegradores();
  //           }
  //           else {
  //             this.errorMessageService.openDialog({
  //               message: 'ERRO',
  //               messageCode: 'Ocorreu uma falha, contate o administrador.'
  //             });
  //             this.buscaIntegradores();
  //           }
  //         },   
  //       );
  //     }

  //   } else {
  //     console.log('INTEGRADOR UNDEFINED');
  //   }
  // }

}
