import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DepartmentsService } from 'src/app/services/departments.service';
import { ConfirmarAcaoService } from 'src/app/shared/modal/confirmar-acao/confirmar-acao.service';

@Component({
  selector: 'app-requisitions-limit',
  templateUrl: './requisitions-limit.component.html',
  styleUrls: ['./requisitions-limit.component.scss']
})
export class RequisitionsLimitComponent implements OnInit {
  departamentos;
  dataSourceRequisitionsLimit: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginatorRequisitionsLimit: MatPaginator;

  displayedColumnsRequisitionsLimit: string[] = [
    'endpoint',
    'metodo',
    'periodo',
    'limite',
    'buttons'
  ];

  
  dataTable = [
    {        
      id: '1',
      endpoint: '/api/notification',
      metodo: 'POST',
      periodo: '1',
      limite: '15',
    },
    {        
      id: '2',
      endpoint: '/api/catalog_system/pvt/skuseller/changenotification',
      metodo: 'GET',
      periodo: '1',
      limite: '10',
    },
    {        
      id: '3',
      endpoint: '/api/catalog_system/pvt/sku/SuggestioninsertUpdate2',
      metodo: 'POST',
      periodo: '2',
      limite: '20',
    }
  ]

  length = 100;
  pageIndex = 0;
  pageSize = 5;
  pagelength = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  constructor(
    private departmentsService: DepartmentsService,
    private confirmarAcao: ConfirmarAcaoService,
  ) {
    this.dataSourceRequisitionsLimit.data = this.dataTable;
   }

  ngOnInit(): void {
    this.traducaoItensPaginacao()
    this.departmentsService.getAllDepartaments().subscribe(data => {
      this.departamentos = data;
    })
  }

  ngAfterViewInit() {
    this.dataSourceRequisitionsLimit.paginator = this.paginatorRequisitionsLimit;
  }

  changeValue(event: Event) {
    console.log('Mundança de valor', event);
  }

  createOrEditComission(comissao: any) {
    console.log(comissao);
    
    // if (comissao.id === "" || comissao.id === null || comissao.id === undefined) {
    //   this.criarComissao(comissao);
    // } else {
    //   this.editarComissao(comissao);
    // }
  }

  addItem() {
    this.dataSourceRequisitionsLimit.data.unshift(
      {        
        id: null,
        endpoint: null,
        metodo: null,
        periodo: null,
        limite: null,
      },
    )
    this.dataSourceRequisitionsLimit.data = this.dataSourceRequisitionsLimit.data;
  }

  confirmarExclusaoModal(comissao: any) {
    const dialog = this.confirmarAcao.openDialog({
      title: 'Exclusão de comissão',
      message: 'Deseja realmente excluir está comissão?'
    });

    dialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        console.log('excluido com sucesso')
        // this.excluirComissao(comissao.id);
      }
    });
  }

  traducaoItensPaginacao() {
    this.paginatorRequisitionsLimit._intl.firstPageLabel = 'Primeira página';
    this.paginatorRequisitionsLimit._intl.lastPageLabel = 'Última página';
    this.paginatorRequisitionsLimit._intl.nextPageLabel = 'Proxima página';
    this.paginatorRequisitionsLimit._intl.previousPageLabel = 'Página anterior';
    this.paginatorRequisitionsLimit._intl.itemsPerPageLabel = 'Itens por página';
  }

}
