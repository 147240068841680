import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar'

//envoriment
import { environment } from './../environments/environment';

import { AppRoutingProdModule } from './app.routing.prod.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { LayoutModule } from './layout/layout.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PagesModule } from './pages/pages.module';
import { ErrorMessageModule } from './shared/modal/error-message/error-message.module';

//Services
import { PreCadastroService } from './services/pre-cadastro.service';
import { ErrorMessageService } from './shared/modal/error-message/error-message.service';
import { BancosService } from './services/bancos.service'
import { AccountService } from './services/account.service';
import { WebstoreService } from './services/webstore.service';
import { UrlIntegradorService } from './services/urlIntegrador.service'
import { LogoService } from './services/logo.service';
import { SettingsService } from './services/settings.service'
import { DepartmentsService } from './services/departments.service'
import { CreateTokenFastService } from './services/create-token-fast.service'
import { ConfirmarAcaoService } from './shared/modal/confirmar-acao/confirmar-acao.service';
import { LoginService } from './services/login.service';
import { StorageService } from './services/storage.service';
import { UsuarioService } from './services/usuario.service';
import { ContatoService } from './services/contato.service';
import { NfeService } from './services/nfe.service';
import { ResponsaveisService } from './services/responsaveis.service';
import { GroupsService } from './services/groups.service';
import { EquipeFastshopService } from './services/equipe-fastshop.service';
import { StatusSellersService } from './services/status-sellers.service';
import {ProfilesService} from './services/profiles.service'
import {EvaluationSummaryService} from './services/evaluation-summary.service'

import { LoaderComponent } from './shared/components/loader/loader.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { RequestInterceptor } from './interceptors/requestInterceptor';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    environment.production ? AppRoutingProdModule : AppRoutingModule,    
    //AppRoutingProdModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    LayoutModule,
    PagesModule,
    ErrorMessageModule,
    MatCheckboxModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatProgressBarModule,
  ],
  providers: [
    Title,                   
    PreCadastroService,
    ErrorMessageService,
    BancosService, 
    AccountService ,
    WebstoreService,
    UrlIntegradorService,
    LogoService,
    SettingsService,
    DepartmentsService,
    CreateTokenFastService,
    ConfirmarAcaoService,
    LoginService,
    StorageService,
    UsuarioService,
    NfeService,
    ResponsaveisService,
    ContatoService,
    GroupsService,
    EquipeFastshopService,
    StatusSellersService,
    ProfilesService,
    EvaluationSummaryService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
