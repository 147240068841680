/** Example file/folder data. */
export const files = [
  { 
    name: 'Admin', title: 'admin',
      children: [
        { name: 'Equipe FastShop', title: 'equipe-fastshop' },
        { name: 'Logs', title: 'logs' },
        { name: 'Painel Sellers', title: 'painel' },
        { name: 'Pré Cadastro', title: 'pre-cadastro' },
        { name: 'Parametrização', title: 'parametrizacao' },
      ]
  },
  { 
    name: 'Resumo', title: 'resumo' 
  },
  { 
    name: 'Gestão de Pedidos', title: 'gestao-pedidos',
  },
  {
    name: 'Cadastro', title: 'cadastro',
      children: [
        { name: 'Contatos' , title: 'contatos'},
        { name: 'Contrato e Documentos', title: 'contrato' },
        { name: 'Empresa', title: 'empresa' },
        { name: 'Integração', title: 'integracao'},
        { name: 'Webstore', title: 'webstore'}
      ]
  },
  { 
    name: 'Comissionamento', title: 'comissionamento' 
  },
  { 
    name: 'Usuários', title: 'usuarios',
  },
  { 
    name: 'Gestão de Frete', title: 'gestao-frete',
  },
  { 
    name: 'HealthCheck', title: 'healthcheck',
  },
  { 
    name: 'Pesquisa de Atividades', title: 'atividades',
  },
];
