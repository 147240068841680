import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray } from '@angular/forms';

//MODEL
import { UsuarioModel } from '../../../models/usuario.model';

//SERVICES
import { UsuarioService } from '../../../services/usuario.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { AuthService } from '../../auth/auth.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
};

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      body: {
        code: number;
        message: string;
        messageCode: string;
      }
      code?: number;
      message?: string;
      messageCode?: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.scss']
})
export class EditarUsuarioComponent implements OnInit {
  element: HTMLElement;
  selectedValue: string;
  selectedCar: string;

  user = {} as UsuarioModel;
  users: UsuarioModel;

  hide = true;
  submitted = false;
  userRoles:any = {};
  dataRoles:any = {};
  function:any = {};
  functionProfiles:any = {};
  functionName:any = {};

  functionID = ['cadastro_seller', 'cadastro_produto', 'gestao_pedido', 'gestao_financeira'];
  profilesSelecteds = ['', '', '', ''];
  phoneMask = '';

  usuarioForm: FormGroup;
  roles: FormArray;
  usuarioModel: UsuarioModel;

  constructor(
    @Optional() private dialogRef: MatDialogRef<EditarUsuarioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      userId,
      objectId
    },
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService
  ) { };

  usuario = [];
  profiles = [];
  objetcUser: any = {};

  ngOnInit(): void {
    this.usuarioForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: [''],
      phone: ['', Validators.required],
      roles: this.formBuilder.array([
      ])
    });
    
    this.getProfiles();
  }

  setPhoneMask() {
    if (this.phone) {
      this.phoneMask = this.phone.length > 10
        ? '(00) 00000-0000'
        : '(00) 00000-0000||(00) 0000-0000';
    }
  }

  get phone(): string {
    return this.usuarioForm.controls['phone'].value;
  }

  // getters de conveniencia para acesso facil aos campos do formulario
  get f() { return this.usuarioForm.controls; }
  get t() { return this.f.roles as FormArray }

  // Chama o serviço de todos os Usuarios
  getUsuario() {
    this.usuarioService.getUsers().subscribe((usuario: any) => {
      this.usuario = usuario;
    });
  };

  getProfiles() {
    this.usuarioService.getProfilesUsers().subscribe((data: any) => {
      data.forEach((e,i) => {
        this.addItem(e.function , e.profiles.filter(p=> p.profile.includes('seller.')));
      });
      this.getUserById();
    },
    error => {
      if (error.status === 403) {
        if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          })
          .afterClosed().subscribe(() => {
            this.auth.logoutAndRemoveStorage();
          });
        }
        else {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
        }
      }
    }
    );
  };

  getUserById() {
    this.usuarioService.getUserById(this.data.userId, this.data.objectId)
    .subscribe((usuario: any) => {
      this.usuario = usuario;
      this.objetcUser = usuario.returnObject;
      this.f.name.setValue(this.objetcUser.name);
      this.f.email.setValue(this.objetcUser.email);
      if(this.objetcUser.phone.length > 10) {
        this.phoneMask = '(00) 00000-0000';
      } else {
        this.phoneMask = '(00) 0000-0000';
      }
      this.f.phone.setValue(this.objetcUser.phone);
      this.f.roles.setValue(this.objetcUser.roles);
      this.userRoles = this.objetcUser.roles;
      this.userRoles.forEach((e,i) => {
        let selected = e.profiles.filter(p=> p.selected);
        this.profilesSelecteds[i] = selected[0]?.profile;
      });
    }
    );
  };

  setSelected(roles: []) {
    return roles.find((r: any) => r.selected)
  }

  setSelectedProfile(){
    this.functionID.forEach(e => {
      this.element = document.getElementById(e) as HTMLElement;
    });
  }

  createItem(name: string, profiles?:any[]): FormGroup {
    return this.formBuilder.group({
      function: [name],
      profiles: [profiles],
    });
  };

  addItem(name: string,profiles?:any[]): void {
    this.roles = this.usuarioForm.get('roles') as FormArray;
    this.roles.push(this.createItem(name,profiles));
  };

  get rolesGetter() { return this.usuarioForm.get('roles') as FormArray }

  onSubmit() {
    this.submitted = true;
    // Para a execução caso o formulário seja invalido
    if (this.usuarioForm.invalid) {
      return;
    };
  };

  selectRole(e, i) {
    const value = e.value;
    const rolesFormArray = this.usuarioForm.get('roles') as FormArray
    // Qual dos roles estamos mexendo
    rolesFormArray.controls[i].value.profiles.forEach(profile => {
      if(profile.profile === value){
        profile.selected = true;  
      }else{
        profile.selected = false;
      }
    });
  }

  // Se o usuario sera criado ou atualizado
  saveUser(userId, objectId) {
    if (this.usuarioForm.invalid) {
      return;
    };
    if (this.usuarioForm.valid) {
      const usuario = this.usuarioForm.value;
      usuario.roles.forEach(role => {
        role.profiles = role.profiles.map((profile) => {
          return {
            profile: profile.profile,
            description: profile.description,
            selected: profile.selected
          }
        });
      });
      if (this.data.userId || this.data.objectId) {
        this.update(userId, objectId, usuario);
        this.getUsuario();
        this.close();
      } else {
        this.close();
      };
    };
  };

  update(userId, objectId, usuario: UsuarioModel) {
    this.usuarioService.updateUser(this.data.userId, this.data.objectId, usuario).subscribe(
      (resp: ResponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Usuário atualizado com sucesso.'
        });
        this.usuarioForm.reset({}, { onlySelf: false, emitEvent: false });
      },
      (error: newReponseI) => {
        if (error.status === 400) {
          if(error.error.returnObject.body) {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: error.error.returnObject.body.messageCode
            });
          } else {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: error.error.returnObject.messageCode
            });
          }
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
          this.close();
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.close();
        }
      },
    );
  };

  disabled() {
    this.hide = false;
  };

  // Fecha formulario
  close() {
    this.dialogRef.close();
  };

  // Limpa formulario
  cleanForm(form: NgForm) {
    form.resetForm();
    this.user = {} as UsuarioModel;
  };
};
