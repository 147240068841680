import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { ContatoModel } from '../../../models/contact.model';
import { DEPARTAMENT } from '../../../models/departaments.model';

//services
import { ContatoService } from '../../../services/contato.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { DepartmentsService } from '../../../services/departments.service';
import { AuthService } from '../../auth/auth.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
};
interface newReponseI {
  status: number;
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}
@Component({
  selector: 'app-adicionar-contato',
  templateUrl: './adicionar-contato.component.html',
  styleUrls: ['./adicionar-contato.component.scss']
})
export class AdicionarContatoComponent implements OnInit {

  contact = {} as ContatoModel;
  contacts: ContatoModel;

  hide = true;

  contatoForm: FormGroup;
  contatoModel: ContatoModel;
  departaments: DEPARTAMENT[];

  constructor(
    @Optional() private dialogRef: MatDialogRef<AdicionarContatoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id },
    private formBuilder: FormBuilder,
    private contatoService: ContatoService,
    private errorMessageService: ErrorMessageService,
    private departmentsService: DepartmentsService,
    private auth: AuthService,

  ) { }

  get f() {
    return this.contatoForm.controls;
  }

  contatos: any = {};
  contato: any = {};
  objetcUser: any = {};
  itensUpdate = [];

  ngOnInit(): void {

    this.getDepartaments();

    this.contatoForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      observation: [''],
      phone: ['', Validators.required],
      responsibility: ['', Validators.required]
    });

    this.contatoForm.get('responsibility').valueChanges.subscribe(item => {
      this.itensUpdate = item
    });
  };

  isObjectEmpty(object): boolean {
    try {
      return Object.keys(object).length === 0
    } catch (error) {
      return
    };
  };

  // Se o contato sera criado ou atualizado
  saveContact() {
    if (this.contatoForm.invalid) {
      return;
    };
    if (this.contatoForm.valid) {
      const contato = this.contatoForm.getRawValue() as ContatoModel;
      if (contato.id) {
        console.log('Update ?');
      } else {
        if (this.isObjectEmpty(this.contatos)) {
          this.saveContactPatch(contato);
          this.close();
        } else {
          this.saveContactPatch(contato);
          this.close();
        };
      };
    };
  };

  saveContactPost(contato: ContatoModel) {
    this.contatoService.saveContactPost(contato).subscribe(
      (resp: ResponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Contato adicionado com sucesso.'
        });
        this.dialogRef.close(true);
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
            this.close();
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.close();
        }
      },
    );
  };

  saveContactPatch(contato: ContatoModel) {
    this.contatoService.saveContactPatch(contato).subscribe(
      (resp: ResponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Contato adicionado com sucesso.'
        });
        this.dialogRef.close(true);
      },
      (error: newReponseI) => {
        if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.accessInfo.message
          });
          this.auth.logoutAndRemoveStorage();
        }
        if (error.status === 403) {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.accessInfo.message
          });
          this.close();
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.close();
        }
      },
    );
  };

  getDepartaments() {
    this.departmentsService.getAllDepartaments().subscribe(dados => {
      this.departaments = dados;
    },
      error => {
        console.log(error);
      }
    );
  };

  disabled() {
    this.hide = false;
  };

  // Fecha formulario
  close() {
    this.dialogRef.close();
  };

  // Limpa formulario
  cleanForm(form: NgForm) {
    form.resetForm();
    this.contact = {} as ContatoModel;
  };
};
