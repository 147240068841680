import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Parceiro Fast';
  subscriptions = new Subscription();
  constructor(
    private titleService: Title,
    private router: Router,
    private activedRoute: ActivatedRoute
    ) {}

  ngOnInit(){
    this.router.events.filter(e => e instanceof NavigationEnd)
    .map(() => this.activedRoute)
    .map(route => {
      while (route.firstChild) route = route.firstChild;
      return route
    })
    .switchMap(({data}) => data)
    .subscribe((data) => this.titleService.setTitle(data.title));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
