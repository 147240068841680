import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ParametrizacaoService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  getIntegradores(): Observable<any> {
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/parameterization`,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  updateTimeOut(id: String, name: string, timeOut: string) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.put(
      `${environment.urlApigee}${environment.pathSc}/parameterization/${id}/name/${name}/timeOut/${timeOut}`,
      [],
      { headers: this.getNewHeaders() }
    );
  }



  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }
}
