import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ActivitiesService } from '../../../services/activities.service';

@Component({
  selector: 'app-pesquisa-atividades',
  templateUrl: './pesquisa-atividades.component.html',
  styleUrls: ['./pesquisa-atividades.component.scss']
})
export class PesquisaAtividadesComponent implements OnInit, OnDestroy {

  @Input() webStore: any;

  public show = false;
  public activities = [];
  public form: FormGroup;
  public statusActivityOptions = [{
    "statusId": "ENCA",
    "label": "Encaminhado",
  }, {
    "statusId": "REAV",
    "label": "Reavaliar",
  }, {
    "statusId": "ATDM",
    "label": "Em atendimento",
  }, {
    "statusId": "OPEN",
    "label": "Pendente",
  }, {
    "statusId": "RESL",
    "label": "Resolvido",
  }];

  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private activitiesService: ActivitiesService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  search() {

    this.form.get('groupCrmCode').setValue(this.webStore.groupCrmCode);

    if (this.form.valid) {

      const formData = Object.assign(this.form.value);

      this.activitiesService.search(formData).subscribe(res => {

        this.activities = res.activities;

      }, err => {

        console.log(`err`, err);
        this.activities = [];

      });

    } else {

      console.log(`FORM ERROR`, this.form);
    }
  }

  private createForm() {

    this.form = this.formBuilder.group({
      groupCrmCode: ['', Validators.compose([
        Validators.required
      ])],
      statusList: [],
      activityId: ['', Validators.compose([])],
      customerName: ['', Validators.compose([])],
      activityDesc: ['', Validators.compose([])],
      maxResult: ['', Validators.compose([])],
    });

    this.show = true;
  }

  resetForm() {
    this.form = this.formBuilder.group({
      statusList: [null],
      activityId: [''],
      customerName: [''],
      activityDesc: [''],
      maxResult: [''],
    });
  }

}
