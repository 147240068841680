import {Component, OnInit, Optional, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, NgForm, FormControl } from '@angular/forms';

//MODELS
import { EquipeModel } from '../../../models/team.model';

//services
import { EquipeFastshopService } from '../../../services/equipe-fastshop.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { ProfilesService } from '../../../services/profiles.service';
import { AuthService } from '../../auth/auth.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number,
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-editar-equipe-fast',
  templateUrl: './editar-equipe-fast.component.html',
  styleUrls: ['./editar-equipe-fast.component.scss']
})
export class EditarEquipeFastComponent implements OnInit {

  team = {} as EquipeModel;
  teams: EquipeModel;

  hide = true;

  equipeFastForm: FormGroup;
  contatoModel: EquipeModel;
  profiles = [];

  constructor(
    @Optional() private dialogRef: MatDialogRef<EditarEquipeFastComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      idContact,
      team,
     },
    private formBuilder: FormBuilder,
    private equipeFastshopService: EquipeFastshopService,
    private errorMessageService: ErrorMessageService,
    private profilesService: ProfilesService,
    private auth: AuthService
  ) { }

  itensUpdate = []

  ngOnInit(): void {
    this.equipeFastForm = this.formBuilder.group({
      name: [''],
      email: [''],
      profile: ['', Validators.required]
    });
    this.getProfiles();
  }

  loadTeam(){
    this.team = this.data.team as EquipeModel;
    if(this.data.team.groups.length > 0){
      this.f.profile.setValue(this.data.team.groups[0]?.profile);
    }
    this.f.name.setValue(this.data.team.name);
    this.f.email.setValue(this.data.team.email);
    this.f.name.disable();
    this.f.email.disable();
  }


  updateTeam(){
    this.equipeFastshopService.updateEquipeFast(this.team, this.f.profile.value).subscribe(
      (resp: ResponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Usuário AD atualizado com sucesso.'
        });
        this.equipeFastForm.reset({},{ onlySelf: false, emitEvent: false} );
        this.dialogRef.close(true);
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    );
  }

  get f() {
    return this.equipeFastForm.controls;
  }


  getProfiles() {
    this.profilesService.getProfiles().subscribe(dados => {
      let prof = [];
      dados.forEach(e => {
        prof = prof.concat(e.profiles.filter(p=> p.profile.includes('fastshop.')));
      });
      this.profiles = prof;
      this.loadTeam();
    },
      error => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        console.log(error);
      }
    );
  }

  disabled() {
    this.hide = false;
  }

  // Fecha formulario
  close() {
    this.dialogRef.close();
  }

  // Limpa formulario
  cleanForm(form: NgForm) {
    form.resetForm();
    this.team = {} as EquipeModel;
  }

}
