import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})

export class EvaluationSummaryService {
    constructor(
        private http: HttpClient,
        private storage: StorageService
    ) { };


    putUploadEvaluation(file: File): Observable<any> {
        var sellerId = this.storage.storage.getItem(`sellerId`);
        const formdata = new FormData();
        formdata.append('uploadEvaluationSeller', file);
        return this.http.put(
            `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/uploadEvaluation`,
            formdata,
            { headers: this.getNewHeadersUpload() })
            .map(response => {
                return response;
            })
            .catch((error: any) => {
                return Observable.throw(error);
            });
    };

    getNewHeadersUpload() {
        return new HttpHeaders({
            'seller-api-version': 'v1',
        });
    }

    getNewHeaders() {
        return new HttpHeaders({
            'content-type': 'application/json',
            'accept': 'application/json',
            'seller-api-version': 'v1',
        });
    }

}
