import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, Input, Optional } from '@angular/core';

@Component({
  selector: 'app-confirmar-acao',
  templateUrl: './confirmar-acao.component.html',
  styleUrls: ['./confirmar-acao.component.scss']
})

export class ConfirmarAcaoComponent implements OnInit {

  //@Input() title: string;
  @Input() msg: string;
  @Input() cancelTxt = 'NÃO';
  @Input() okTxt = 'SIM';

  title: string;
  message: string;
  constructor(
    @Optional() public dialogRef: MatDialogRef<ConfirmarAcaoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,  
  ) { 
    this.title = data.title;
    this.message = data.message;
  }
  
  ngOnInit(): void {
  }

  onConfirm() {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
