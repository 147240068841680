export const environment = {
  production: true,
  urlApigee: 'https://apigw.fastshop.com.br',
  uri: 'v1/sellercenter/iam/fastid/fastshop',
  pathSc: '/sellercenter/v0',
  pathAddress: '/customer/v0',
  pathLocate: '/locate/v0',
  pathSimpleFreight: '/shipping/v0/shippings/sellersimplefreight',
  pathShopMpc: '/mpc/v0/shop-mpc/fastshop/catalog/1',
  pathShopMpcCategories: '/categories',
  pathShopMpcItensSkus: '/items/skus',
  pathShopMpcItensBrands: '/items/brands',
  marketplaceId: 'sellercenter-master',
  sapCrmApi: 'https://apigw.fastshop.com.br/sellercenter/v0',
  bucketName: 'seller-simple-freight-calculator-master',
  bucketRegion: 'us-east-1',
  accessKeyId: 'AKIAZEOANNNYNV27PYX2',
  secretAccessKey: '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'
};