import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, tap, delay, take, retry, catchError, isEmpty } from 'rxjs/operators';

import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  getLog(firstperiod: string, finalperiod: string, idfiltersbank: string): Observable<any> {
    const headerSettings: { [name: string]: string | any } = {};
    // headerSettings['firstperiod'] = firstperiod;
    // headerSettings['finalperiod'] = finalperiod;
    headerSettings['idfiltersbank'] = idfiltersbank;
    // headerSettings['token'] = this.storage.storage.getItem('key');
    headerSettings['seller-api-version'] = 'v1';

    const newHeader = new HttpHeaders(headerSettings);
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/export?finalPeriod=${finalperiod}&firstPeriod=${firstperiod}`,
      {
        headers: newHeader,
        responseType: 'blob'
      },
    )
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  getFilterList() {
    return this.http.get<any>(`${environment.urlApigee}${environment.pathSc}/filters/log`,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
    ;
  }

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }
}
