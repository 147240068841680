import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, tap, delay, take, retry, catchError, isEmpty } from 'rxjs/operators';
import { UsuarioModel } from './../models/usuario.model';
import { ReenviarSenhaModel } from './../models/reenviar-senha.model';

import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  reset(arg0: {}, arg1: { onlySelf: boolean; emitEvent: boolean; }) {
    throw new Error("Method not implemented.");
  };

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { };

  getProfilesUsers(): Observable<any> {
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/profiles`,
      { headers: this.getNewHeaders() }
    )
      .pipe(retry(2),
        catchError(this.handleError)
      );
  };

  getUsers(): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/users`,
      { headers: this.getNewHeaders() }
    )
      .pipe(retry(2),
        catchError(this.handleError)
      );
  };

  getUserById(iduser: string, id: string): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/users/${id}/user/${iduser}`,
      { headers: this.getNewHeaders() },
    )
      .pipe(retry(2),
        // tap(_ => console.log(`ID do OBJETO=${id}, ID do USUARIO=${iduser}`)),
        catchError(this.handleError)
      );
  };

  saveUserPatch(usuario: UsuarioModel, idObjetcUser: string) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    if (sellerId == "undefined" || sellerId == undefined || sellerId == null) {
      alert("Por favor selecione um Seller");
      return;
    }

    return this.http.patch(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/users/${idObjetcUser}/user`,
      { users: [usuario] },
      { headers: this.getNewHeaders() }
    )
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  saveUserPost(usuario: UsuarioModel, idObjetcUser: string) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.post(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/users`,
      usuario,
      { headers: this.getNewHeaders() }
    ).map(response => {
      return response;
    })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  updateUser(id: number, objectId: string, usuario: UsuarioModel) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.patch(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/users/${objectId}/user/${id}`,
      usuario,
      { headers: this.getNewHeaders() }
    ).map(response => {
      return response;
    })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  deleteUser(idObjectUser: string, idUser: number) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.delete<UsuarioModel>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/users/${idUser}/user/${idObjectUser}`,
      { headers: this.getNewHeaders() }
    ).map(response => {
      return response;
    })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  sendEmail(name: ReenviarSenhaModel) {
    const headersSettings: { [name: string]: string | any } = {};
    headersSettings['userName'] = name;
    const newHeader = new HttpHeaders(headersSettings);
    return this.http.post(
      `${environment.urlApigee}${environment.pathSc}/iam/fastid/fastshop/login/forgotPassword`,
      {},
      { headers: newHeader }
    ).map(response => {
      return response;
    })
      .catch((error: any) => {
        return Observable.throw(error);
      })
      .pipe(retry(1),
        catchError(this.handleError))
  };

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }

  // Manipulação de erros
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Error lado do cliente
      errorMessage = error.error.message;
    } else {
      // Error lado do servidor
      errorMessage = `Código de erro: ${error.status},` + `menssagem: ${error.message}`;
    };
    console.log(errorMessage);
    return throwError(errorMessage);
  };

}
