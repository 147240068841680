import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './../../../shared/auth/auth.service';
import { LoginService } from 'src/app/services/login.service';
import { PasswordValidation } from './../shared/password.validation';
import { LoginModel } from './../../../models/login.model';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    key?: string;
    status?: string;
    messageCode?: string;
    warning?: boolean;
  }
}

interface ResponseSuccessI {
  message: string;
  key?: string;
  status?: string;
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  hide = true;
  hideTwo = true;
  changePasswordForm: FormGroup;
  passwordType: string;
  isAuthenticating: boolean = false;
  isInvalid: boolean = false;
  pwdPattern = "^(?=.*[A-Z])(?=.*[!@#%?_<>)({}´`|\/,.^~$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$";

  email: string = '';
  password: string = '';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private loginService: LoginService,
    private errorMessageService: ErrorMessageService,

  ) {
    this.email = this.router.getCurrentNavigation().extras.state.username;
    this.password = this.router.getCurrentNavigation().extras.state.password;
  }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      userName: [this.email],
      oldpassword: [this.password],
      Password: ['', [Validators.required, Validators.minLength(7), Validators.pattern(this.pwdPattern)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(7), Validators.pattern(this.pwdPattern)]]
    },
      {
        validators: PasswordValidation.MatchPassword,
      },
    );
  }

  get f() {
    return this.changePasswordForm.controls;
  }

  newPassword() {
    this.isAuthenticating = true;

    setTimeout(() => {
      if (this.changePasswordForm.valid) {
        this.isAuthenticating = false;
        this.loginService.newPasswordRequired(this.changePasswordForm.value).subscribe(
          (resp: ResponseSuccessI) => {
            this.router.navigate(['bem-vindo']);
            this.authService.login(resp);
            console.log('redirecionado para bem vindo');
            this.errorMessageService.openDialog({
              message: 'Senha criada com sucesso',
              messageCode: 'Sua senha definitiva foi criada com sucesso e está valendo a partir do seu próximo acesso.'
            });
          },
          (error: ResponseI) => {
            console.log(error.error);
            this.errorMessageService.openDialog({
              message: error.error.message,
              messageCode: error.error.messageCode
            });
          }
        );
      }

    }, 1500)
  }

}
