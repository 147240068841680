import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { ContatoModel } from './../models/contact.model';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ContatoService {
  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  getContacts(): Observable<ContatoModel[]> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<ContatoModel[]>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/contacts`,
      { headers: this.getNewHeaders() }
    ).map(response => {
      return response;
    })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getContactById(idContact: string, idObjectContact: string): Observable<ContatoModel> {
    var sellerId = this.storage.storage.getItem(`sellerId`);

    return this.http.get<ContatoModel>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/${idObjectContact}/contacts/${idContact}`,
      { headers: this.getNewHeaders() }
    ).map(response => {
      return response;
    })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  saveContactPatch(contato: ContatoModel) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.patch(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/contacts`,
      contato,
      { headers: this.getNewHeaders() }
    ).map(response => {
      return response;
    })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  saveContactPost(contato: ContatoModel) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.post(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/contacts`,
      contato,
      { headers: this.getNewHeaders() }
    ).map(response => {
      return response;
    })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  updateContact(contato: ContatoModel, id: number, idObjectUser: string) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.patch(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/contacts/${id}`,
      contato,
      { headers: this.getNewHeaders() }
    ).map(response => {
      return response;
    })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  deleteContact(id: string) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.delete<ContatoModel>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/contact/${id}`,
      { headers: this.getNewHeaders() }
    ).map(response => {
      return response;
    })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }

}
