import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class CreateTokenFastService {

    constructor(
        private http: HttpClient,
        private storage: StorageService
    ) { }

    get(sellerId: String, appKey: string): Observable<any> {
        return this.http.get<any>(`${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/token/${appKey}`,
            { headers: this.getNewHeaders() })
            .map(response => {
                return response;
            })
            .catch((error: any) => {
                return Observable.throw(error);
            });
    }

    getToken(sellerIdExt: string): Observable<any> {
        var sellerId = this.storage.storage.getItem(`sellerId`);
        return this.http.get<any>(`${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerIdExt}/accounts/${sellerId}/hub/internalcode`,
            { headers: this.getNewHeaders() })
            .map(response => {
                return response;
            })
            .catch((error: any) => {
                return Observable.throw(error);
            });
    }

    postTokenJWT(appKey: string, sellerIdExt: string) {
        var sellerId = this.storage.storage.getItem(`sellerId`);
        return this.http.post(`${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerIdExt}/accounts/${sellerId}/token/${appKey}`,
            {},
            { headers: this.getNewHeaders() })
            .map(response => {
                return response;
            })
            .catch((error: any) => {
                return Observable.throw(error);
            });
    }

    getNewHeaders() {
        return new HttpHeaders({
            'content-type': 'application/json',
            'accept': 'application/json',
            'seller-api-version': 'v1',
        });
    }

}