import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AddressI } from './../models/address.model';

@Injectable({
  providedIn: 'root'
})

export class AddressService {

  private headers = new HttpHeaders({
    'content-type': 'application/json',
    'accept': 'application/json',
    'exception': 'No-Token'
  });

  constructor(
    private http: HttpClient,
  ) { }

  public getCepAddress(cep: String): Observable<any> {
    return this.http.get<any>(`${environment.urlApigee}${environment.pathAddress}/address?zipcode=${cep}&base=GAN`,
      { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  public getCepByAddress(address: AddressI): Observable<any> {
    return this.http.post(`${environment.urlApigee}${environment.pathAddress}/byStreet`,
    address,
    { headers: this.headers } )
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }


  public getUFs(): Observable<any> {
    return this.http.get<any>(`${environment.urlApigee}${environment.pathLocate}/buscalocalidades`,
      { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }
}
