import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsLoadingService {
  private isLoading = new BehaviorSubject(false);
  constructor() { }

  getLoadState(){
    return this.isLoading;
  }

  setLoadState(state:boolean){
    this.isLoading.next(state);
  }
}
