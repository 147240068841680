import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../material/material.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar'

import { HeaderComponent } from './header/header.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { LayoutComponent } from './layout.component';
import { IframeListenerComponent } from './iframe-listener/iframe-listener.component';

import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    HeaderComponent,
    LeftMenuComponent,
    IframeListenerComponent,
    LayoutComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatProgressBarModule,
  ],
  exports: [
    
  ]
})
export class LayoutModule { }
