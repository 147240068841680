import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
@Injectable({
  providedIn: 'root',
})
export class RequestInterceptor implements HttpInterceptor {
  constructor(private storage: StorageService) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    const token = this.storage.storage.getItem('key')
    const exception = request.headers.get('exception');

    if (exception) {
      request.headers.delete('token');
    }

    if (token) {
      const headers = request.headers.append('token', token);
      const authReq = request.clone({
        headers,
      });
      return next.handle(authReq);
    } else {
      return next.handle(request.clone())
    }
  }
}
