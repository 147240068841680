
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { DEPARTAMENT } from '../models/departaments.model';

@Injectable({
    providedIn: 'root'
})
export class DepartmentsService {

    private headersDepartament = new HttpHeaders({
        'content-type': 'application/json',
        'accept': 'application/json',
        'seller-api-version': 'v1',
        'exception': 'No-Token'
    });

    constructor(private http: HttpClient) { }

    getAllDepartaments() {
        return this.http.get<DEPARTAMENT[]>(`${environment.urlApigee}${environment.pathSc}/departments`,
            { headers: this.headersDepartament })
            .map(response => {
                return response;
            })
            .catch((error: any) => {
                return Observable.throw(error);
            });
        ;
    }
}