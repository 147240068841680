import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, tap, delay, take, retry, catchError, isEmpty } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  reset(arg0: {}, arg1: { onlySelf: boolean; emitEvent: boolean; }) {
    throw new Error("Method not implemented.");
  };

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { };

  getProfiles(): Observable<any> {
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/profiles`,
      { headers: this.getNewHeaders() }
    )
      .pipe(retry(2),
        catchError(this.handleError)
      );
  };

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }

  // Manipulação de erros
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Error lado do cliente
      errorMessage = error.error.message;
    } else {
      // Error lado do servidor
      errorMessage = `Código de erro: ${error.status},` + `menssagem: ${error.message}`;
    };
    console.log(errorMessage);
    return throwError(errorMessage);
  };

}
