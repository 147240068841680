import { GestaoDeFreteLog } from './../../models/gestao-de-frete-log';
import { ContigenceResponse } from './../../models/contigence-response.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as fileSaver from 'file-saver';

//Service
import { GestaoFreteService } from '../../services/gestao-frete.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { AuthService } from 'src/app/shared/auth/auth.service';

//Modal
import { SobrescreverDadosComponent } from './sobrescrever-dados-modal/sobrescrever-dados.component';
import { ConsultaCepModalComponent } from './consulta-cep-modal/consulta-cep-modal.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { catchError, first, switchMap } from 'rxjs/operators';
import { timeInterval } from 'rxjs-compat/operator/timeInterval';
import { of, timer } from 'rxjs';

interface newReponseI {
  status: number;
  error: {
    error: string;
    returnObject: {
      body?: {
        code: number;
        message: string;
        messageCode: string;
      };
      code: number;
      message: string;
      messageCode: string;
    };
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      };
      profile: string;
    };
  };
}

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  };
}

export class NgbdModalContent {
  @Input() name;
  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: 'app-gestao-frete',
  templateUrl: './gestao-frete.component.html',
  styleUrls: ['./gestao-frete.component.scss'],
})
export class GestaoFreteComponent implements OnInit {
  logoName;
  ifBaseData;
  checkData;
  dateLastUpload;

  dataButtonDownload: any;
  dataGetInfoPage: any;

  isAuthenticating: boolean = false;
  preview: boolean = false;
  isInvalidUpload: boolean = false;
  isErrorUpload: boolean = false;
  isSuccessUpload: boolean = false;
  timeLoadBar: boolean = false;
  ifDataBase: boolean = false;
  processingStatus: GestaoDeFreteLog[] = [];

  imageUrl = './../../../assets/svg/outline-backup-24px.svg';

  file: File;
  gestaoFreteForm: FormGroup;
  todayDate: Date = new Date();
  cep: string = '';

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  freteDataTable: any;

  displayedColumns: string[] = ['Modalidade', 'Prazo', 'Valor'];

  constructor(
    private dialog: MatDialog,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private gestaoFreteService: GestaoFreteService,
    private errorMessageService: ErrorMessageService
  ) {}

  length = 100;
  pageIndex = 0;
  pageSize = 10;
  pagelength = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  pageEvent: PageEvent;
  isProcessing: boolean;
  hasNotGeneratedExtraction = true;

  atualizar(event: PageEvent) {
    console.log(event);
  }

  ngOnInit(): void {
    this.dataSource.data = [];
    this.getCheckInfosPage();
    this.checkSellerExtrationStatus();

    this.gestaoFreteForm = this.formBuilder.group({
      peso: ['', [Validators.required]],
      cubagem: ['1', Validators.required],
      cep: ['', Validators.required],
    });
    this.f.cubagem.disable();

    if (this.ifBaseData !== null && this.ifBaseData !== undefined) {
      this.isInvalidUpload = this.ifBaseData.length > 1 ? true : false;
      this.logoName =
        this.ifBaseData.split('/')[this.ifBaseData.split('/').length - 1];
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ConsultaCepModalComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.cep = result;
      this.f.cep.setValue(this.cep);
    });
  }

  get f() {
    return this.gestaoFreteForm.controls;
  }

  // --- ação que é iniciada após a seleção do arquivo para upload
  onSelectFile(e) {
    console.log(e);
    this.isAuthenticating = true;
    if (e.target.files) {
      this.file = e.target.files[0];
      this.logoName = this.file.name;
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
        this.preview = true;
        this.imageUrl = event.target.result;
        this.isAuthenticating = false;
      };
      setTimeout(() => {
        this.patchUploadContingencyS3Button(e);
      }, 800);
    }
  }

  public openModalDialogSobrescreverDados(fileData) {
    const dialogRef = this.dialog.open(SobrescreverDadosComponent, {
      width: '30%',
      data: {
        fileData,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('DATA', result);

      switch (result) {
        case 'canceledButton':
          console.log('Sobrescrever dados fechado');
          this.getCheckInfosPage();
          break;

        case 'failedUpload':
          console.log('Sobrescrever dados falha');
          this.timeLoadBar = true;
          setTimeout(() => {
            this.timeLoadBar = false;
            this.isErrorUpload = true;
            this.isSuccessUpload = false;
          }, 2000);
          this.getCheckInfosPage();
          break;

        case 'successUpload':
          console.log('Sobrescrever dados sucesso');
          this.timeLoadBar = true;
          setTimeout(() => {
            this.timeLoadBar = false;
            this.isErrorUpload = false;
            this.isSuccessUpload = true;
            // this.postUploadContingencyButton();
          }, 2000);
          this.getCheckInfosPage();
          break;

        default:
          break;
      }
    });
  }

  getCheckInfosPage() {
    this.gestaoFreteService.getCheckInfos().subscribe(
      (dataGetInfoPage: any) => {
        this.dataGetInfoPage = dataGetInfoPage;
        this.checkData = dataGetInfoPage.check;
        this.dateLastUpload = dataGetInfoPage.data;

        if (this.checkData === true) {
          this.ifDataBase = true;
        } else {
          this.ifDataBase = false;
        }
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (
            error.error.statusCustom.accessInfo.message === 'Token Expirado'
          ) {
            this.errorMessageService
              .openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile,
              })
              .afterClosed()
              .subscribe(() => {
                this.auth.logoutAndRemoveStorage();
              });
          } else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile,
            });
          }
        }
      }
    );
  }

  postUploadContingencyButton() {
    const sso = true;

    this.gestaoFreteService.postUploadContingency(sso).subscribe(
      (resp: ResponseI) => {
        console.log(resp);
        console.log('Primeiro post enviado com sucesso');
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (
            error.error.statusCustom.accessInfo.message === 'Token Expirado'
          ) {
            this.errorMessageService
              .openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile,
              })
              .afterClosed()
              .subscribe(() => {
                this.auth.logoutAndRemoveStorage();
              });
          } else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile,
            });
          }
        }
      }
    );
  }

  patchUploadContingencyButton(event) {
    if (event.target.files) {
      this.file = event.target.files[0];
    }
    this.gestaoFreteService
      .putUploadContingency(this.file)
      .pipe(first())
      .subscribe(
        (response: ContigenceResponse) => {
          const { code, message } = response.returnObject;
          const title =
            code === 202
              ? {
                  text: 'Planilha enviada com sucesso, aguarde alguns minutos e atualize o status de processamento.',
                  icon: 'sucess',
                }
              : { text: 'Erro', icon: 'error' };
          this.errorMessageService.openDialog({
            code,
            message,
            title,
            messageCode:
              'Planilha enviada com sucesso, aguarde alguns minutos e atualize o status de processamento.',
          });
        },
        (error: newReponseI) => {
          this.getCheckInfosPage();
          if (error.error.error === 'Internal Server Error') {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: 'Ocorreu uma falha, contate o administrador.',
            });
          } else if (error.status === 403) {
            if (
              error.error.statusCustom.accessInfo.message === 'Token Expirado'
            ) {
              this.errorMessageService
                .openDialog({
                  message: error.error.statusCustom.accessInfo.message,
                  messageCode: error.error.statusCustom.profile,
                })
                .afterClosed()
                .subscribe(() => {
                  this.auth.logoutAndRemoveStorage();
                });
            } else {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile,
              });
            }
          } else {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: 'Ocorreu uma falha, contate o administrador.',
            });
          }
        }
      );
  }

  getDownloadContingencyButton() {
    this.gestaoFreteService.getDownloadContingency().subscribe(
      (response: any) => {
        let file = new Blob([response], {
          type: 'application/xlsx',
        });

        const nav = (window.navigator as any);
        if (nav.msSaveOrOpenBlob) {
          nav.msSaveOrOpenBlob(file);
          return;
        }

//        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//          window.navigator.msSaveOrOpenBlob(file);
//          return;
//        }

        var blob = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = blob;
        fileSaver.saveAs(blob, 'parceirofast_frete_contingencia.xlsx');
        this.getCheckInfosPage();
      },
      (error: newReponseI) => {
        this.getCheckInfosPage();
        if (error.error.error === 'Internal Server Error') {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.',
          });
        } else if (error.status === 403) {
          if (
            error.error.statusCustom.accessInfo.message === 'Token Expirado'
          ) {
            this.errorMessageService
              .openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile,
              })
              .afterClosed()
              .subscribe(() => {
                this.auth.logoutAndRemoveStorage();
              });
          } else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile,
            });
          }
        } else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.',
          });
        }
      }
    );
  }

  getDownloadContingencyButtonTemplate() {
    this.gestaoFreteService.getDownloadContingencyTemplate().subscribe(
      (response: any) => {
        let file = new Blob([response], {
          type: 'application/xlsx',
        });

        const nav = (window.navigator as any);
        if (nav.msSaveOrOpenBlob) {
          nav.msSaveOrOpenBlob(file);
          return;
        }

//        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//          window.navigator.msSaveOrOpenBlob(file);
//          return;
//        }

        var blob = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = blob;
        fileSaver.saveAs(blob, 'parceirofast_frete_contingencia_template.xlsx');
        this.getCheckInfosPage();
      },
      (error: newReponseI) => {
        this.getCheckInfosPage();
        if (error.error.error === 'Internal Server Error') {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.',
          });
        } else if (error.status === 403) {
          if (
            error.error.statusCustom.accessInfo.message === 'Token Expirado'
          ) {
            this.errorMessageService
              .openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile,
              })
              .afterClosed()
              .subscribe(() => {
                this.auth.logoutAndRemoveStorage();
              });
          } else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile,
            });
          }
        } else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.',
          });
        }
      }
    );
  }

  simularFrete() {
    if (this.gestaoFreteForm.invalid) {
      return;
    }
    if (this.gestaoFreteForm.valid) {
      const formSimularFrete = this.gestaoFreteForm.getRawValue() as any;
      var peso = formSimularFrete.peso;
      var cubagem = formSimularFrete.cubagem;
      var cep = formSimularFrete.cep;
      this.gestaoFreteService
        .getFreteByWeightCubageZipcode(cep, peso, cubagem)
        .subscribe(
          (success) => {
            console.log(success);
            this.freteDataTable = success.returnObject;
            this.dataSource.data = this.freteDataTable;
            this.length = this.freteDataTable.length;
          },
          (error: newReponseI) => {
            if (error.error.error === 'Internal Server Error') {
              this.errorMessageService.openDialog({
                message: 'ERRO',
                messageCode: 'Ocorreu uma falha, contate o administrador.',
              });
            } else if (error.status === 403) {
              if (
                error.error.statusCustom.accessInfo.message === 'Token Expirado'
              ) {
                this.errorMessageService
                  .openDialog({
                    message: error.error.statusCustom.accessInfo.message,
                    messageCode: error.error.statusCustom.profile,
                  })
                  .afterClosed()
                  .subscribe(() => {
                    this.auth.logoutAndRemoveStorage();
                  });
              } else {
                this.errorMessageService.openDialog({
                  message: error.error.statusCustom.accessInfo.message,
                  messageCode: error.error.statusCustom.profile,
                });
              }
            } else {
              this.errorMessageService.openDialog({
                message: 'ERRO',
                messageCode: error.error.returnObject.message,
              });
            }
            this.dataSource.data = [];
          }
        );
    }
  }

  deletePreviewFile() {
    this.preview = false;
  }

  checkProcessingStatus() {
    this.gestaoFreteService
      .checkStatusControl()
      .pipe(first())
      .subscribe(
        ({ logs, status: processingStatus }) => {
          const status =
            !!logs && logs.length > 0
              ? 'Finalizado com inconsistências'
              : processingStatus === 'FINISHED'
              ? 'Finalizado'
              : processingStatus === 'PROCESSING'
              ? 'Processando'
              : 'Draft';

          this.processingStatus = [{ status, logs }];
        },
        (error: newReponseI) => {
          this.getCheckInfosPage();
          if (error.error.error === 'Internal Server Error') {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: 'Ocorreu uma falha, contate o administrador.',
            });
          } else if (error.status === 403) {
            if (
              error.error.statusCustom.accessInfo.message === 'Token Expirado'
            ) {
              this.errorMessageService
                .openDialog({
                  message: error.error.statusCustom.accessInfo.message,
                  messageCode: error.error.statusCustom.profile,
                })
                .afterClosed()
                .subscribe(() => {
                  this.auth.logoutAndRemoveStorage();
                });
            } else {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile,
              });
            }
          } else {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: 'Ocorreu uma falha, contate o administrador.',
            });
          }
        }
      );
  }

  generateSellerExtraction() {
    this.gestaoFreteService
      .generatesellerextraction()
      .pipe(first())
      .subscribe(
        ({ code }) => {
          this.isProcessing = true;
          this.hasNotGeneratedExtraction = false;
          const title =
            code === '202'
              ? {
                  text: 'Sua planilha está sendo processada, aguarde alguns minutos e clique no botão download.',
                  icon: 'sucess',
                }
              : { text: 'Erro', icon: 'error' };
          const message =
            code === '200'
              ? 'Processamento de planilha iniciado'
              : 'Erro no processamento';
          const codeNumber = Number.parseInt(code);
          this.errorMessageService.openDialog({
            code: codeNumber,
            message,
            title,
            messageCode:
              'Sua planilha está sendo processada, aguarde alguns minutos e clique no botão download.',
          });
        },
        (error: any) => {
          this.getCheckInfosPage();
          if (error.error.error === 'Internal Server Error') {
            this.errorMessageService.openDialog({
              message: 'Error',
              messageCode: error?.error?.message || 'Ocorreu um erro contate o administrador',
            });
          } else if (error.status === 403) {
            if (
              error.error.statusCustom.accessInfo.message === 'Token Expirado'
            ) {
              this.errorMessageService
                .openDialog({
                  message: error.error.statusCustom.accessInfo.message,
                  messageCode: error.error.statusCustom.profile,
                })
                .afterClosed()
                .subscribe(() => {
                  this.auth.logoutAndRemoveStorage();
                });
            } else {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile,
              });
            }
          } else {
            this.errorMessageService.openDialog({
              message: 'Erro',
              messageCode: error?.error?.message || 'Ocorreu um erro contate o administrador',
            });
          }
        }
      );
  }
// ---- solicita a geração da planilha para salvar no bucket s3 e fazer o download
  generateSellerExtractionS3() {
    this.gestaoFreteService
    .generatesellerextractions3()
    .subscribe(
      (ret) => {
        console.log("retorno upload API - ret = " + ret);
        this.isProcessing = true;
        this.hasNotGeneratedExtraction = false;
        const title = {
          text: 'Sua planilha está sendo processada, aguarde alguns minutos e clique no botão download.',
          icon: 'sucess',
        }
        const message = 'Processamento de planilha iniciado';
        const codeNumber = 200;
        this.errorMessageService.openDialog({
          code: codeNumber,
          message,
          title,
          messageCode: 'Sua planilha está sendo processada, aguarde alguns minutos e clique no botão download.',
        });
        this.isProcessing = false;
      },
      (error) => {
        console.log("erro na chamada da API de download - " + error);
        this.isProcessing = false;
        this.hasNotGeneratedExtraction = true;
      }
    );      
  }

  downloadSellerExtraction() {
    this.gestaoFreteService
      .downloadsellerextraction()
      .pipe(first())
      .subscribe(
        (response) => {
          fileSaver.saveAs(response, `dados_gestao_de_frete${Date.now()}.xlsx`);
        },
        (error: newReponseI) => {
          this.getCheckInfosPage();
          if (error.error.error === 'Internal Server Error') {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: 'Ocorreu uma falha, contate o administrador.',
            });
          } else if (error.status === 403) {
            if (
              error.error.statusCustom.accessInfo.message === 'Token Expirado'
            ) {
              this.errorMessageService
                .openDialog({
                  message: error.error.statusCustom.accessInfo.message,
                  messageCode: error.error.statusCustom.profile,
                })
                .afterClosed()
                .subscribe(() => {
                  this.auth.logoutAndRemoveStorage();
                });
            } else {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile,
              });
            }
          } else {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: 'Ocorreu uma falha, contate o administrador.',
            });
          }
        }
      );
  }


  // --- obtém url assinada e faz o download da planilha do s3
  downloadSellerExtractionS3() {

    const param = 'parceirofast_frete_contingencia';
    this.isProcessing = true;
    console.log("Inicio download - {}", param);
    const s3Url = this.gestaoFreteService.getSignedUrlS3().subscribe(
      data => {
        console.log("Url pre-assinada - {}", data);
        const sheet = this.gestaoFreteService.downloadExcelS3(data).subscribe(
          sheetData => {
            console.log("Download arquivo - {}", data);
            fileSaver.saveAs(sheetData, param + '.xlsx');
            this.hasNotGeneratedExtraction = true;
            this.isProcessing = false;
            const delS3 = this.gestaoFreteService.deleteFileBucketAws(param).subscribe(
              del => {
                console.log("Exclusão do arquivo no bucket - {}", param);
              }
            )
          },
          error => {
            this.hasNotGeneratedExtraction = true;
            this.isProcessing = false;
            console.log("Erro no download do bucket S3 " + error);
          }
        )
      },
      error => {
        if (error.error.error === 'Internal Server Error') {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.',
          });
        } else if (error.status === 403) {
          if (
            error.error.statusCustom.accessInfo.message === 'Token Expirado'
          ) {
            this.errorMessageService
              .openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile,
              })
              .afterClosed()
              .subscribe(() => {
                this.auth.logoutAndRemoveStorage();
              });
          } else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile,
            });
          }
        } else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.',
          });
        }
      }
    );
  }



  checkSellerExtrationStatus() {
    const timerInterval = timer(0, 15000);
    timerInterval
      .pipe(
        switchMap(() =>
          this.gestaoFreteService
            .checkStatusControl()
            .pipe(catchError((error) => of(error)))
        )
      )
      .subscribe(
        ({ extractionStatus }) => {
          this.isProcessing = extractionStatus !== 'FINISHED';
        },
        (error: newReponseI) => {
          if (error.status === 403) {
            if (
              error.error.statusCustom.accessInfo.message === 'Token Expirado'
            ) {
              this.errorMessageService
                .openDialog({
                  message: error.error.statusCustom.accessInfo.message,
                  messageCode: error.error.statusCustom.profile,
                })
                .afterClosed()
                .subscribe(() => {
                  this.auth.logoutAndRemoveStorage();
                });
            } else {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile,
              });
            }
          } else if (error.status !== 404) {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: 'Ocorreu uma falha, contate o administrador.',
            });
          }
        }
      );
  }

  downloadErrors(errors: string) {
    const blob = new Blob([errors], { type: 'text/plain;charset=utf-8' });
    fileSaver.saveAs(blob, `erros_gestao_de_frete${Date.now()}.txt`);
  }


  // --- upload do arquivo selecionado, primeiro aciona API para obter a URL assinada
  // --- e fazer o upload para o s3, segundo acionar a API que deverá fazer a leitura
  // --- da planilha do s3 e persistir no banco MongoDb
  patchUploadContingencyS3Button(event) {

    if (event.target.files) {
      this.file = event.target.files[0];
    }

    console.log("Inicio upload s3 .....")

    this.gestaoFreteService.uploadFileToS3(this.file)
          .then(retorno1 => {
            console.log("Termino upload s3 (retorno) - " + retorno1)
            this.gestaoFreteService.putUploadContingencyS3(this.file.name)
                  .subscribe(retorno2 => {
                    console.log("Termino chamada API (retorno) - " + retorno2);
                    const message = "Sucesso";
                    const code = 202;
                    const title =
                            code === 202
                              ? {
                                  text: 'Planilha enviada com sucesso, aguarde alguns minutos e atualize o status de processamento.',
                                  icon: 'sucess',
                                }
                              : { text: 'Erro', icon: 'error' };
                
                    this.errorMessageService.openDialog({
                      code,
                      message,
                      title,
                      messageCode:
                        'Planilha enviada com sucesso, aguarde alguns minutos e atualize o status de processamento.',
                    });
                  })
          })
  }

}
