import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class StatusSellersService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  public get(filterStatus: string, name: string, page: number, pageSize: number): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(`${environment.urlApigee}${environment.pathSc}/statusSeller/${page}?filterStatus=${filterStatus}&nomeDoSeller=${name}&pageSize=${pageSize}`,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  public downloadListSellers(): Observable<any> {
    return this.http.get(`${environment.urlApigee}${environment.pathSc}/publishstatus/report`,
    { headers: this.getNewHeaders(), responseType: 'blob' })
        .map(response => {
            return response;
        })
        .catch((error: any) => {
            return Observable.throw(error);
        });
  }

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }
}
