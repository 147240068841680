import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { UsuarioService } from '../../../services/usuario.service';
import { AuthService } from '../../auth/auth.service';
import { ErrorMessageService } from '../error-message/error-message.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-delete-usuario',
  templateUrl: './delete-usuario.component.html',
  styleUrls: ['./delete-usuario.component.scss']
})
export class DeleteUsuarioComponent implements OnInit {

  constructor(
    @Optional() private dialogRef: MatDialogRef<DeleteUsuarioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      idObjectUser,
      idUser
    },
    private usuarioService: UsuarioService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService
  ) { }

  ngOnInit(): void { }

  // Se a ação sera de excluir ou fechar o modal
  deleteContact(idObjectUser, idUser) {
    if (this.data.idObjectUser || this.data.idUser) {
      this.deleteUsuarioById(idObjectUser, idUser);
      this.close()
    } else {
      this.buttonSubmitCancel();
    }
  }

  // Fecha modal
  buttonSubmitCancel() {
    this.dialogRef.close();
  }

  deleteUsuarioById(idObjectUser, idUser) {
    this.usuarioService.deleteUser(this.data.idObjectUser, this.data.idUser).subscribe((error) => {
      this.errorMessageService.openDialog({
        message: 'SUCESSO',
        messageCode: 'Usuário deletado com sucesso.'
      });
      this.dialogRef.close(true);
    },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
            this.close();
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.close();
        }
      },
    );
  };

  close() {
    this.dialogRef.close();
  };

}
