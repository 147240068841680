import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EquipeFastshopService } from '../../../services/equipe-fastshop.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { AuthService } from '../../auth/auth.service';

interface newReponseI {
  status: number,
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

interface erroResponse {
  status: number,
  error: {
    returnObject:{
      body:{
        code:string,
        message:string,
        messageCode:string
      },
      headers:{},
      statusCode:string,
      statusCodeValue:number,
    }
  }
}

@Component({
  selector: 'app-delete-equipe-fast',
  templateUrl: './delete-equipe-fast.component.html',
  styleUrls: ['./delete-equipe-fast.component.scss']
})
export class DeleteEquipeFastComponent implements OnInit {

  constructor(
    @Optional() private dialogRef: MatDialogRef<DeleteEquipeFastComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { email },
    private equipeFastshopService: EquipeFastshopService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
  }

  // Se o contato sera criado ou atualizado
  deleteTeam() {
    if (this.data.email) {
      this.deleteTeamByEmail(this.data.email);
    } else {
      this.close();
    }
  }

  // Fecha formulario
  close() {
    this.dialogRef.close();
  }

  // Chama o serviço de todos os contatos
  deleteTeamByEmail(email) {
    this.equipeFastshopService.deleteEquipeFast(email).subscribe((ResponseI) => {
      this.errorMessageService.openDialog({
        message: 'SUCESSO',
        messageCode: 'Usuário AD deletado com sucesso.'
      });
      this.dialogRef.close(true)
    },
      (error: any) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: error.error.returnObject.body.message,
            messageCode: error.error.returnObject.body.messageCode
          });
        }
      }
    );
  };

}
