import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      body: {
        code: number;
        message: string;
        messageCode: string;
      }
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-healthcheck',
  templateUrl: './healthcheck.component.html',
  styleUrls: ['./healthcheck.component.scss']
})


export class HealthcheckComponent implements OnInit {

healthCheckArray = [];

  constructor(
    private settingsService: SettingsService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.settingsService.getHealthcheck().subscribe(
      data => {
        this.healthCheckArray = data.returnObject; 
      },
      (error: any) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Não foram encontradas informações para este seller'
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    )
  }

}
