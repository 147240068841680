import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgBrazil } from 'ng-brazil';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule } from 'ngx-mask';

//Modules
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { LoginModule } from './login/login.module';
import { MessageErrorModule } from './../shared/components/message-error/message-error.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';

//Components
import { LoginComponent } from './login/login.component';
import { PreCadastroComponent } from './pre-cadastro/pre-cadastro.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { ContatosComponent } from './contatos/contatos.component';
import { GestaoFreteComponent } from './gestao-frete/gestao-frete.component';
import { PainelComponent } from './painel/painel.component';
import { IntegracaoComponent } from './integracao/integracao.component';
import { WebstoreComponent } from './webstore/webstore.component';
import { NewWebstoreComponent } from './../pages/webstore/new-webstore/new-webstore.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ContratosDocumentosComponent } from './contrato-documentos/contratos-documentos.component';
import { EquipeFastshopComponent } from './equipe-fastshop/equipe-fastshop.component';
import { BemVindoComponent } from './bem-vindo/bem-vindo.component';
import { ParametrizacaoComponent } from './parametrizacao/parametrizacao.component';
import { ComissionamentoComponent } from './comissionamento/comissionamento.component';
import { ComissaoWebstoresPageComponent } from './comissionamento/comissao-webstores-page/comissao-webstores-page.component';
import { ComissaoWebstoreComponent } from './comissionamento/components/comissao-webstore/comissao-webstore.component';
import { CanaisDeVendaComponent } from './comissionamento/components/canais-de-venda/canais-de-venda.component';
import { ComissaoWebstoreDetailComponent } from './comissionamento/components/comissao-webstore-detail/comissao-webstore-detail.component';
import { NewIntegracaoComponent } from './integracao/new-integracao/new-integracao.component';
import { LogComponent } from './log-page/log.component';

import { PesquisaAtividadesComponent } from './atividades/webstores-atividades/components/pesquisa-atividades/pesquisa-atividades.component';
import { AtividadeDetalheComponent } from './atividades/atividade-detalhe/atividade-detalhe.component';
import { ListaAtividadesComponent } from './atividades/webstores-atividades/components/lista-atividades/lista-atividades.component';
import { WebstoresAtividadesComponent } from './atividades/webstores-atividades/webstores-atividades.component';

//Modal
import { AdicionarResponsavelComponent } from '../shared/modal/adicionar-responsavel/adicionar-responsavel.component';
import { AdicionarContatoComponent } from '../shared/modal/adicionar-contato/adicionar-contato.component';
import { ConfirmarAcaoComponent } from '../shared/modal/confirmar-acao/confirmar-acao.component';
import { EditarContatoComponent } from '../shared/modal/editar-contato/editar-contato.component';
import { EditarResponsavelComponent } from '../shared/modal/editar-responsavel/editar-responsavel.component';
import { DeleteContatoComponent } from '../shared/modal/apagar-contato/delete-contact.component';
import { AdicionarNfeComponent } from '../shared/modal/adicionar-nfe/adicionar-nfe.component';
import { DeleteResponsavelComponent } from '../shared/modal/deletar-responsavel/delete-responsavel.component';
import { AdicionarUsuarioComponent } from '../shared/modal/adicionar-usuario/adicionar-usuario.component';
import { ReenviarSenhaUsuarioComponent } from '../shared/modal/reenviar-senha/reenviar-senha.component';
import { DeleteNfeComponent } from '../shared/modal/deletar-nfe/delete-nfe.component';
import { EditarNfeComponent } from '../shared/modal/editar-nfe/editar-nfe.component';
import { EditarUsuarioComponent } from '../shared/modal/editar-usuario/editar-usuario.component';
import { DeleteUsuarioComponent } from '../shared/modal/deletar-usuario/delete-usuario.component';
import { EditarEquipeFastComponent } from '../shared/modal/editar-equipe-fast/editar-equipe-fast.component';
import { DeleteEquipeFastComponent } from '../shared/modal/deletar-equipe-fast/delete-equipe-fast.component';
import { SobrescreverDadosComponent } from '../pages/gestao-frete/sobrescrever-dados-modal/sobrescrever-dados.component';
import { ConsultaCepModalComponent } from './gestao-frete/consulta-cep-modal/consulta-cep-modal.component';
import { HistoricoModalComponent } from './comissionamento/components/historico-modal/historico-modal.component';
import { NotasRecComponent } from './notas-rec/notas-rec.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HistoricoRecModalComponent } from './notas-rec/historico-rec-modal/historico-rec-modal.component';
import { GestaoPedidosComponent } from './gestao-pedidos/gestao-pedidos.component';
import { OutboundTableComponent } from './gestao-pedidos/outbound-table/outbound-table.component';
import { TimeOutFreteComponent } from './parametrizacao/time-out-frete/time-out-frete.component';
import { RequisitionsLimitComponent } from './parametrizacao/requisitions-limit/requisitions-limit.component';
import { HealthcheckComponent } from './healthcheck/healthcheck.component';
import { IncluirArquivoAtividadeComponent } from './atividades/incluir-arquivo-atividade/incluir-arquivo-atividade.component';

//Pipes
import { IEPipe } from '../shared/pipes/ie.pipe';

@NgModule({
  declarations: [
    LoginComponent,
    PreCadastroComponent,
    EmpresaComponent,
    GestaoFreteComponent,
    AdicionarResponsavelComponent,
    ContatosComponent,
    PainelComponent,
    AdicionarContatoComponent,
    ConfirmarAcaoComponent,
    EditarContatoComponent,
    IntegracaoComponent,
    WebstoreComponent,
    NewWebstoreComponent,
    DeleteContatoComponent,
    AdicionarNfeComponent,
    DeleteNfeComponent,
    EditarNfeComponent,
    UsuariosComponent,
    AdicionarUsuarioComponent,
    ReenviarSenhaUsuarioComponent,
    ContratosDocumentosComponent,
    EditarUsuarioComponent,
    DeleteUsuarioComponent,
    EquipeFastshopComponent,
    EditarEquipeFastComponent,
    DeleteEquipeFastComponent,
    EditarResponsavelComponent,
    DeleteResponsavelComponent,
    BemVindoComponent,
    SobrescreverDadosComponent,
    ParametrizacaoComponent,
    ConsultaCepModalComponent,
    ComissionamentoComponent,
    ComissaoWebstoresPageComponent,
    ComissaoWebstoreComponent,
    HistoricoModalComponent,
    CanaisDeVendaComponent,
    ComissaoWebstoreDetailComponent,
    NewIntegracaoComponent,
    LogComponent,
    NotasRecComponent,
    HistoricoRecModalComponent,
    GestaoPedidosComponent,
    OutboundTableComponent,
    TimeOutFreteComponent,
    RequisitionsLimitComponent,
    HealthcheckComponent,
    IncluirArquivoAtividadeComponent,
    PesquisaAtividadesComponent,
    AtividadeDetalheComponent,
    ListaAtividadesComponent,
    WebstoresAtividadesComponent,
    IEPipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    LoginModule,
    HttpClientModule,
    MessageErrorModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    NgBrazil,
    TextMaskModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    NgbModule,
  ],
})
export class PagesModule {}
