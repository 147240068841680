import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParentEventEmitterService {
  constructor() { }

  public emitSignOutMessage() {
    window.parent.postMessage({
      action: "signOut", data: {
        message: "Token expirou",
      }
    }, "*");
  };

  public emitChangeSellerIdMessage(sellerId: string) {
    window.parent.postMessage({
      action: "changeSellerId", data: {
        message: sellerId,
      }
    }, "*");
  };
  
}
