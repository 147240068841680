import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, tap, delay, take, retry, catchError } from 'rxjs/operators';
import { NfeModel } from './../models/nfe.model';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { StorageService } from './storage.service'

@Injectable({
  providedIn: 'root'
})
export class NfeService {

  reset(arg0: {}, arg1: { onlySelf: boolean; emitEvent: boolean; }) {
    throw new Error("Method not implemented.");
  }

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  getNfes(): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/nfe/${environment.marketplaceId}/${sellerId}`,
      { headers: this.getNewHeaders() }
    )
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  getNfeById(numberInclusion: number): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/nfe/${environment.marketplaceId}/${sellerId}/${numberInclusion}`,
      { headers: this.getNewHeaders() }
    )
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  saveNfe(contato: NfeModel) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.post(
      `${environment.urlApigee}${environment.pathSc}/nfe/${environment.marketplaceId}/${sellerId}/registration/email`,
      contato,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  updateNfe(contato: NfeModel) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.put(
      `${environment.urlApigee}${environment.pathSc}/nfe/${environment.marketplaceId}/${sellerId}/email/`,
      contato,
      { headers: this.getNewHeaders() }
    )
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  deleteNfe(numberInclusion: string) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.delete<NfeModel>(
      `${environment.urlApigee}${environment.pathSc}/nfe/${environment.marketplaceId}/${sellerId}/remove/email/${numberInclusion}`,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }

  // Manipulação de erros
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Error lado do cliente
      errorMessage = error.error.message;
    } else {
      // Error lado do servidor
      errorMessage = `Código de erro: ${error.status},` + `menssagem: ${error.message}`;
    }
    return throwError(errorMessage);
  }

}
