import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ContatoModel } from '../../models/contact.model';
import { AdicionarContatoComponent } from '../../shared/modal/adicionar-contato/adicionar-contato.component';
import { EditarContatoComponent } from '../../shared/modal/editar-contato/editar-contato.component';
import { DeleteContatoComponent } from '../../shared/modal/apagar-contato/delete-contact.component';
import { ContatoService } from '../../services/contato.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { AuthService } from 'src/app/shared/auth/auth.service';

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

export class NgbdModalContent {
  @Input() name;

  constructor(public activeModal: NgbActiveModal) {};
};

@Component({
  selector: 'app-contatos',
  templateUrl: './contatos.component.html',
  styleUrls: ['./contatos.component.scss']
})

export class ContatosComponent implements OnInit {

  contatct = {} as ContatoModel;
  contacts: ContatoModel[];
  controlTableContact = false; 
  objectAll: any = {};
  objetcContact: any = {};

  constructor(
    private dialog: MatDialog,
    private contatoService: ContatoService,
    private modalService: NgbModal,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService,
  ) { };

  ngOnInit(): void {
    this.getContacts();
  };

  open() {
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.name = 'World';
  };

  // Chama o serviço de todos os contatos
  getContacts() {
    this.contatoService.getContacts().subscribe((contacts: any) => {

      this.contacts = contacts;
      this.objetcContact = contacts.contacts;

      if(this.objetcContact.length > 0){
        this.controlTableContact = true;
      }else{
        this.controlTableContact = false;
      }
    },
    (error: newReponseI) => {
      if (error.status === 403) {
        if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          })
          .afterClosed().subscribe(() => {
            this.auth.logoutAndRemoveStorage();
          });
        }
        else {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
        }
      }
    });
  };

  public openModalDialogEditContato(idContact, idObjectContact){
    const dialogRef = this.dialog.open(EditarContatoComponent, {
      width: '35%',
      id:'modalEditContact',
      data: {
        idContact,
        idObjectContact,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {this.getContacts();}, 2000);
    });
  };

  public openModalDialogDeleteContato(id){
    const dialogRef = this.dialog.open(DeleteContatoComponent, {
      width: '30%',
      data: {
        id
      },
    });
    
    dialogRef.afterOpened().subscribe(() => {
      document.querySelector('mat-dialog-container').id = 'teste';
    });

    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {this.getContacts();}, 1500);
    });
  };

  public openModalDialogAddContato(id) {
    const dialogRef = this.dialog.open(AdicionarContatoComponent, {
      width: '35%',
      data: {
        id
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {this.getContacts();}, 2000);
    });
  };
}
