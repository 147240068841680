import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
// import { IActivity, IActivitySearch, IAttachment, INote } from '../interfaces/IActivity';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  search(data: any): Observable<any> {

    data = {
      "groupCrmCode": data.groupCrmCode,
      "statusList": data.statusList,
      "activityId": data.activityId,
      "activityDesc": data.activityDesc,
      "customerName": data.customerName,
      "maxResult": data.maxResult,
    };

    return this.http.post<any>(`${environment.sapCrmApi}/v1/sap-crm/activities`, data, {
      observe: 'response'

    }).pipe(take(1), map(res => {

      return res.body;

    }), catchError((err) => {
      throw err;

    }));
  }

  findOne(groupCrmCode: string, activityId: any): Observable<any> {

    return this.http.get<any>(`${environment.sapCrmApi}/v1/sap-crm/activities/${groupCrmCode}/${activityId}`, {
      observe: 'response'

    }).pipe(take(1), map(res => {

      return res.body;

    }), catchError((err) => {
      throw err;

    }));
  }

  uploadAttachment(groupCrmCode: string, activityId: any, file: File, description: string) {
    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('description', description);
    return this.http.post<any>(`https://seller-management-api-master.prod.fastshopdigital.com/sellercenter/v1/sap-crm/activities/${groupCrmCode}/${activityId}/attachment`,
      formdata)
      .pipe(map(res => {
        return res;

      }), catchError((err) => {
        throw err.error;

      }));
  }

  saveActivity(data: any) {

    return this.http.put(`${environment.sapCrmApi}/v1/sap-crm/activities/${data.groupCrmCode}/${data.activityId}`, data.formData, {
      observe: 'response',

    }).pipe(map(res => {
      return res;

    }), catchError((err) => {
      throw err.error;

    }));
  }

}
