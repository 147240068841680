import { IsLoadingService } from './../services/is-loading.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { ErrorMessageService } from '../shared/modal/error-message/error-message.service';
import { AuthService } from '../shared/auth/auth.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(
    private loaderService: IsLoadingService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService,
    // private message: MensagemService
  ) {}

  removeRequest(req: HttpRequest<any>) {
    const index = this.requests.indexOf(req);
    this.requests.splice(index, 1);
    const haveAnyRequest = this.requests.length > 0;
    this.loaderService.setLoadState(haveAnyRequest);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requests.push(request);
    this.loaderService.setLoadState(true);

    return new Observable((observer) => {
      const subscription = next
        .handle(request)
        .pipe(timeout(30000))
        .subscribe(
          (event) => {
            if (event instanceof HttpResponse) {
              observer.next(event);
            }
          },
          (error: any) => {
            // console.log(JSON.stringify(err))
            // const strategies = [
            //   { test: ({error}) => !!error?.authStatus, title:'Erro',message: err.error?.authStatus?.pop()?.accessInfo?.message},
            //   { test: ({status}) => status === 403, title:'Erro de permissão',message: 'Sem permissão para realizar esta ação, faça login novamente ou acesse com outro usuário.'},
            //   { test: (err) => err?.name === 'TimeoutError', title:'Erro',message: 'Tempo de requisição esgotado'},
            //   { test: ({error}) => !!error?.message, title:'Erro', message: err.error.message},
            //   { test: ({message}) => !!message, title:'Erro', message: err.message},
            //   { test: (err) => true, title: 'Erro',message: 'Erro desconhecido' }
            // ];

            // const {title,message} = strategies.find((strategie) => strategie.test(err));
            
            // if (err.status !== 404 && !!message) this.message.showMessage(title, message);
            observer.error(error);
            observer.complete();
          },
          () => {
            observer.complete();
          }
        )
        .add(() => this.removeRequest(request));
      return () => {
        subscription.unsubscribe();
      };
    });
  }
}