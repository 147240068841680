import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NfeService } from '../../../services/nfe.service';
import { AuthService } from '../../auth/auth.service';
import { ErrorMessageService } from '../../modal/error-message/error-message.service';


interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-delete-contato',
  templateUrl: './delete-nfe.component.html',
  styleUrls: ['./delete-nfe.component.scss']
})
export class DeleteNfeComponent implements OnInit {

  constructor(
    @Optional() private dialogRef: MatDialogRef<DeleteNfeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { numberInclusion },
    private nfeService: NfeService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {

  }

  // Se o contato sera criado ou atualizado
  deleteContact(numberInclusion) {
    if (this.data.numberInclusion) {
      this.deleteContactsById(numberInclusion);
    } else {
      this.close();
    }
  }

  // Fecha formulario
  close() {
    this.dialogRef.close();
  }

  // Chama o serviço de todos os contatos
  deleteContactsById(numberInclusion) {
    this.nfeService.deleteNfe(this.data.numberInclusion).subscribe((ResponseI) => {
      this.errorMessageService.openDialog({
        message: 'SUCESSO',
        messageCode: 'Contato deletado com sucesso.'
      });
      this.dialogRef.close(true)
    },
      (error: ResponseI) => {
        this.dialogRef.close(true);
        this.errorMessageService.openDialog({
          message: error.error.message,
          messageCode: error.error.message
        });
      }
    );
  };

}
