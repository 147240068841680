import { EquipeFast } from './../models/equipe-fast.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { StorageService } from './storage.service';

//MODEL
import { EquipeModel } from './../models/team.model';

@Injectable({
  providedIn: 'root'
})
export class EquipeFastshopService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  getEquipeFast(limit:number, nextToken:string) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<EquipeFast>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/usersad`,
      { headers: this.getNewHeaders(), params:{
        limit:limit.toString(),
        nextToken: nextToken.replace(/\+/g, '%2B')
      } }
    )
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  findEquipeFast(userName:string){
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<EquipeFast>(`${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/userad`, {
      headers: this.getNewHeaders(),
      params:{
        userName
      }
    })
  }

  updateEquipeFast(team: EquipeModel, profile : string) { 
    var sellerId = this.storage.storage.getItem(`sellerId`);
    const body = {
      groupName: [ profile ] ,
      userName: team.email
    }
    return this.http.post(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/usersad`,
      body,
      { headers: this.getNewHeaders() }
    )
      .pipe(retry(2),
        catchError(this.handleError)
      );
  };


  deleteEquipeFast(userName: string) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.delete<EquipeModel>( 
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/user?userName=${userName}`,
      { headers: this.getNewHeaders() }
    ).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }

  // Manipulação de erros
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Error lado do cliente
      errorMessage = error.error.message;
    } else {
      // Error lado do servidor
      errorMessage = `Código de erro: ${error.status},` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
