import { IsLoadingService } from './../../../services/is-loading.service';
import { Component,ChangeDetectorRef, AfterContentInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements AfterContentInit {
  loading: boolean;
  constructor(
    public loaderService: IsLoadingService,
    private changeRef: ChangeDetectorRef
  ) {}

  ngAfterContentInit(): void {
    this.loaderService.getLoadState().subscribe((isLoading) => {
      this.loading = isLoading;
      this.changeRef.detectChanges();
    });
  }
}