import { Component, Input, OnInit } from '@angular/core';
import { IActivitySearch } from '../../../interfaces/IActivity';

@Component({
  selector: 'app-lista-atividades',
  templateUrl: './lista-atividades.component.html',
  styleUrls: ['./lista-atividades.component.scss']
})
export class ListaAtividadesComponent implements OnInit {

  @Input() groupCrmCode: any;
  @Input() activities: IActivitySearch[] = [];

  constructor() { }

  ngOnInit(): void {

  }

}
