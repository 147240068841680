import { StatusProcessamentoFrete } from './../models/status-processamento-frete';
import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import * as fileSaver from 'file-saver';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpBackend,
} from '@angular/common/http';
import {
  map,
  tap,
  delay,
  first,
  retry,
  catchError,
  isEmpty,
} from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class GestaoFreteService {
  reset(arg0: {}, arg1: { onlySelf: boolean; emitEvent: boolean }) {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient, private httpBackend: HttpBackend, private storage: StorageService) {}

  getFreteByWeightCubageZipcode(
    zipcode: string,
    weight: string,
    cubage: string
  ): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http
      .get(
        `${environment.urlApigee}${environment.pathSimpleFreight}/sellersimplefreightcalculator/${environment.marketplaceId}/sellers/${sellerId}/${sellerId}/freight/${zipcode}/weight/${weight}/cubage/${cubage}`,
        { headers: this.getNewHeaders() }
      )
      .map((response) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getDownloadContingency(): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http
      .get(
        `${environment.urlApigee}${environment.pathSimpleFreight}/sellersimplefreightcalculator/${environment.marketplaceId}/sellers/${sellerId}/${sellerId}/downloadContingency/`,
        { headers: this.getNewHeaders(), responseType: 'blob' }
      )
      .map((response) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getDownloadContingencyTemplate(): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http
      .get(
        `${environment.urlApigee}${environment.pathSimpleFreight}/sellersimplefreightcalculator/${environment.marketplaceId}/sellers/${sellerId}/${sellerId}/downloadContingencyTemplate/`,
        { headers: this.getNewHeaders(), responseType: 'blob' }
      )
      .map((response) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getCheckInfos(): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http
      .get<any>(
        `${environment.urlApigee}${environment.pathSimpleFreight}/sellersimplefreightcalculator/${environment.marketplaceId}/sellers/${sellerId}/${sellerId}/checkInfos`,
        { headers: this.getNewHeaders() }
      )
      .pipe(retry(2), catchError(this.handleError));
  }

  postUploadContingency(sso): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    const formdata = new FormData();
    return this.http
      .post(
        `${environment.urlApigee}${environment.pathSimpleFreight}/sellersimplefreightcalculator/${environment.marketplaceId}/sellers/${sellerId}/${sellerId}/uploadContingency?overwrite=${sso}`,
        formdata,
        { headers: this.getNewHeaders() }
      )
      .map((response) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  putUploadContingency(file: File): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    const formdata = new FormData();
    formdata.append('uploadContingencySeller', file);
    const headers = this.getNewHeadersUpload().append('Host', '');
    return this.http
      .put(
        `${environment.urlApigee}${environment.pathSimpleFreight}/sellersimplefreightcalculator/${environment.marketplaceId}/sellers/${sellerId}/${sellerId}/uploadContingency/`,
        formdata,
        { headers: this.getNewHeadersUpload() }
      )
      .map((response) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  checkStatusControl() {
    const sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<StatusProcessamentoFrete>(
      `${environment.urlApigee}${environment.pathSimpleFreight}/sellersimplefreightcalculator/s3/${environment.marketplaceId}/sellers/${sellerId}/${sellerId}/checkstatuscontrol`,
      { headers: this.getNewHeaders() }
    );
  }

  generatesellerextraction() {
    const sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<{code:string, message:string}>(
      `${environment.urlApigee}${environment.pathSimpleFreight}/sellersimplefreightcalculator/${environment.marketplaceId}/sellers/${sellerId}/${sellerId}/generatesellerextraction`,
      { headers: this.getNewHeaders() }
    );
  }

  downloadsellerextraction(){
    const sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get(
      `${environment.urlApigee}${environment.pathSimpleFreight}/sellersimplefreightcalculator/${environment.marketplaceId}/sellers/${sellerId}/${sellerId}/downloadsellerextraction`,
      { headers: this.getNewHeadersUpload(), responseType: 'blob'}
    );
  }

  getNewHeadersUpload() {
    return new HttpHeaders({
      'seller-api-version': 'v1',
    });
  }

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      accept: 'application/json',
      'seller-api-version': 'v1',
    });
  }

  getNewHeadersAvoidCache() {
    return new HttpHeaders({
      'content-type': 'application/json',
      accept: 'application/json',
      'seller-api-version': 'v1',
    });
  }

  // Manipulação de erros
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Error lado do cliente
      errorMessage = error.error.message;
    } else {
      // Error lado do servidor
      errorMessage =
        `Código de erro: ${error.status},` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  uploadFileToS3_excluded(file: File, preSignedUrl: string) {
    const excelFile = new FormData();
    excelFile.append('file', file);
    console.log("URL ASSINADA - " + file.name + " / " + file.type);
    const newHttpClient = new HttpClient(this.httpBackend);
    return newHttpClient.put(preSignedUrl, excelFile);
  }

  callApiUploadContingency(file: File) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    const urlApi = `${environment.urlApigee}${environment.pathSimpleFreight}/sellersimplefreightcalculator/s3/${environment.marketplaceId}/sellers/${sellerId}/${sellerId}/uploadContingency?filename=${file.name}`;
    console.log("Api upload S3 para Banco sendo chamado.... " + urlApi);
    return this.http.get(urlApi, { headers: this.getNewHeaders() })
  }


  generatesellerextractions3() {
    const sellerId = this.storage.storage.getItem(`sellerId`);
    const urlApi = `${environment.urlApigee}${environment.pathSimpleFreight}/sellersimplefreightcalculator/s3/${environment.marketplaceId}/sellers/${sellerId}/${sellerId}/downloadContingency?timestamp=${Date.now()}`;
    console.log("Chamando API Download S3 - " + urlApi);
    return this.http.get<{code:string, message:string}>(urlApi, { headers: this.getNewHeaders()}).pipe(first());
  }

  downloadExcelS3(signedUrl:string) {
    const newHttpClient = new HttpClient(this.httpBackend);
    return newHttpClient.get(signedUrl, {responseType: 'blob'});
  }

  getSignedUrlS3() {
    const sellerId = this.storage.storage.getItem(`sellerId`);
    const signedUrl = `${environment.urlApigee}${environment.pathSimpleFreight}/sellersimplefreightcalculator/s3/${environment.marketplaceId}/sellers/${sellerId}/${sellerId}/preSignedUrlS3`;
    console.log("Chamando API AWS obter url-assinada - " + signedUrl);
    return this.http.get(signedUrl, {responseType: 'text', headers: this.getNewHeaders() }).pipe(first());
  }

  deleteFileBucketAws(filename:string) {
    const sellerId = this.storage.storage.getItem(`sellerId`);
    const signedUrl = `${environment.urlApigee}${environment.pathSimpleFreight}/sellersimplefreightcalculator/s3/${environment.marketplaceId}/sellers/${sellerId}/${sellerId}/deleteFileBucketAws`;
    console.log("Chamando API AWS delete arquivo - " + signedUrl);
    return this.http.delete(signedUrl, { headers: this.getNewHeaders() });
  }

  getPutSignedUrlS3(filename:string) {
    const sellerId = this.storage.storage.getItem(`sellerId`);
    const signedUrl = `${environment.urlApigee}${environment.pathSimpleFreight}/sellersimplefreightcalculator/s3/${environment.marketplaceId}/sellers/${sellerId}/${sellerId}/putSignedUrlS3?filename=${filename}`;
    console.log("Chamando API para obter url-assinada - " + signedUrl);
    return this.http.get(signedUrl, {responseType: 'text', headers: this.getNewHeaders() });
  }

  async uploadFileToS3Direct(file: File) {
    const contentType = file.type;
    const bucket = new S3(
        {
            accessKeyId: environment.accessKeyId,
            secretAccessKey: environment.secretAccessKey,
            region: environment.bucketRegion
        }
    );
    const params = {
        Bucket: environment.bucketName,
        Key: file.name,
        Body: file
    };
    bucket.upload(params, function (err, data) {
        if (err) {
            console.log('There was an error uploading your file: ', err);
            return false;
        }
        console.log('Successfully uploaded file.', data);
    });
    await this.delay(10000);
    return true;
  }

  private delay(ms: number): Promise<boolean> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, ms);
    });
  }

  async uploadFileToS3(file: File) {

    const bucket = new S3({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.bucketRegion
    });

    const params = {
        Bucket: environment.bucketName,
        Key: file.name,
        Body: file
    };

    try {
      const retorno = await bucket.upload(params).promise()
      console.log("finalizou uploado s3 (sucesso) - " + retorno)  
    } catch (err) {
      console.log("finalizou upload s3 (erro) - " + err)
    }

  }

  putUploadContingencyS3(file: String) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    const urlApi = `${environment.urlApigee}${environment.pathSimpleFreight}/sellersimplefreightcalculator/s3/${environment.marketplaceId}/sellers/${sellerId}/${sellerId}/uploadContingency?filename=${file}`;
    console.log("Api upload S3 para Banco sendo chamado.... " + urlApi);
    return this.http.get(urlApi, { headers: this.getNewHeaders() })
  }

}
