import { StorageService } from './../../services/storage.service';
import { switchMap } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

//model
import { Integration } from '../../models/integration.model'

//services
import { UrlIntegradorService } from '../../services/urlIntegrador.service'
import { SettingsService } from '../../services/settings.service'
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { CreateTokenFastService } from '../../services/create-token-fast.service'
import { AuthService } from 'src/app/shared/auth/auth.service';
import { MatTabGroup } from '@angular/material/tabs';

interface erroResponse {
  error: {
    returnObject:{
      body:{
        code:string,
        message:string,
        messageCode:string
      },
      headers:{},
      statusCode:string,
      statusCodeValue:number,
    }
  }
}

interface newReponseI {
  status: number,
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-integracao',
  templateUrl: './integracao.component.html',
  styleUrls: ['./integracao.component.scss']
})
export class IntegracaoComponent implements OnInit, OnDestroy {
  integracaoForm: FormGroup;

  dataSource = [
    { 'endpoint': 'OrderCreated', 'url': 'https://' },
    { 'endpoint': 'OrderUpdatedStatus', 'url': 'https://' },
    { 'endpoint': 'OrderCanceled', 'url': 'https://' },
    { 'endpoint': 'FreightSimulation', 'url': 'https://' },
    { 'endpoint': 'GetOrderVtex', 'url': 'https://' },
    { 'endpoint': 'GetProduct', 'url': 'https://' },
    { 'endpoint': 'GetSalesConditional', 'url': 'https://' }
  ];
  sourceInicial = this.dataSource;
  displayedColumns: string[] = ['endpoint', 'url'];
  integradoresBack = [];
  lastSellerId = '';
  lastIdLoja = '';
  lastPoliticaComercial = '';
  sellerId = this.serviceStorage.storage.getItem('sellerId');

  subscriptions = new Subscription();

  allIntegrations;
  allIntegrationsData;
  validArrayIntegrations;

  newIntegration: Integration = new Object() as Integration;

  @ViewChild("tabs", { static: false }) tabs: MatTabGroup;
  newIntegracaoClick = false;
  finalPositionArray;


  constructor(
    private formBuilder: FormBuilder,
    private urlIntegradorService: UrlIntegradorService,
    private settingsService: SettingsService,
    private errorMessageService: ErrorMessageService,
    private createTokenFastService: CreateTokenFastService,
    private auth: AuthService,
    private serviceStorage: StorageService,
  ) { }

  ngOnInit(): void {
    this.getIntegracao();
  }

  ngOnDestroy(): void{
    this.subscriptions.unsubscribe();
  }

  get integracaoFormControls() {
    return this.integracaoForm.controls;
  }

  get isUsedIntegrator(){
    return this.integracaoFormControls.flagUtilizarIntegrador.value;
  }

  createIntegracaoForm(){
    this.integracaoForm = this.formBuilder.group({
      id: [''],
      flagUtilizarIntegrador: [null, Validators.required],
      integrador: [null],
      appKey: [null],
      appToken: [null],
      sellerId: [this.sellerId, Validators.required],
      idLoja: [null],
      politicaComercial: [null],
      vinculoPagseguro: [null, Validators.required],
      appKeyFastShop: [null],
      appTokenFastShop: [null]
    });
  }

  isRequireAndEnableInput(){
    const subscription = this.integracaoFormControls.flagUtilizarIntegrador.valueChanges.subscribe(isUsed => {
      if(!isUsed) this.clearForm();
      this.isRequireValidations(isUsed);
      this.isEnabledInputs(isUsed);
    })
    this.subscriptions.add(subscription);
  }

  isEnabledInputs(option:boolean) {
    const disableOrEnable = option ? 'enable' : 'disable';
    this.integracaoFormControls.integrador[disableOrEnable]();
    this.integracaoFormControls.appKey[disableOrEnable]();
    this.integracaoFormControls.appToken[disableOrEnable]();
    this.integracaoFormControls.sellerId[disableOrEnable]();
    this.integracaoFormControls.idLoja[disableOrEnable]();
    this.integracaoFormControls.politicaComercial[disableOrEnable]();
    this.integracaoFormControls.appKeyFastShop[disableOrEnable]();
    this.integracaoFormControls.appTokenFastShop[disableOrEnable]();
  }

  isRequireValidations(option:boolean){
    const isRequired = option ? Validators.required : Validators.nullValidator;
    this.integracaoFormControls.integrador.setValidators(isRequired);
    this.integracaoFormControls.appKey.setValidators(isRequired);
    this.integracaoFormControls.appToken.setValidators(isRequired);
    this.integracaoFormControls.politicaComercial.setValidators(isRequired);
  }

  clearForm(){
    this.integracaoFormControls.integrador.reset();
    this.integracaoFormControls.appKey.reset();
    this.integracaoFormControls.appToken.reset();
    this.integracaoFormControls.politicaComercial.reset();
    this.integracaoFormControls.appKeyFastShop.reset();
    this.integracaoFormControls.appTokenFastShop.reset();
    this.integracaoFormControls.vinculoPagseguro.reset();
  }

  verifyFormIsComplete(){
    this.integracaoForm.statusChanges.subscribe(status => {

    });
  }

   getIntegradores() {
    this.urlIntegradorService.get().subscribe(
      ret => {
        this.integradoresBack = ret[0].integrations;
      },
      error => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        console.log(error);
      }
    )
  }

   saveIntegration() {
    var setting: Integration;

    if (this.integracaoForm.invalid) {
      return;
    }
    if (this.integracaoForm.valid) {
      if (this.integracaoFormControls.id.value !== '') {
        this.update(setting);
      } else {
        this.save(setting);
      }
    }
  }

  newIntegracao() {
    const tabGroup = this.tabs;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
    this.newIntegracaoClick = true;
    this.allIntegrations.push({
      sellerIdExt: 'SellerId',
      } as Integration)
    const tabCount = tabGroup._tabs.length;
    tabGroup.selectedIndex = (tabCount + 1);
  }

  save(setting: Integration) {
    setting = this.buildSetting();
    this.settingsService.save(setting).subscribe(
      success => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Integração criada com sucesso. '
        });
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
            this.getIntegracao();
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      },
    );
  };

   update(setting: Integration) {
    setting = this.buildSetting();
    var id ='2';
    this.settingsService.update(setting, id).subscribe(
      () => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Integração atualizada com sucesso. '
        });
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
            this.getIntegracao();
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      },
    );
  };

   setUrls() {
    this.dataSource = this.urlIntegradorService.getDataSource(this.integracaoFormControls.integrador.value, this.integradoresBack);

    this.validaPoliticaComercial();
    this.validaIdLoja();

    if (this.integracaoFormControls.politicaComercial.value !== '') {
      this.setPoliticaComercialUrl();
    }

    if (this.integracaoFormControls.sellerId.value !== '') {
      this.replaceSellerID();
    }
  }

  validaPoliticaComercial(){
    if (this.integracaoFormControls.integrador.value == 'Vtex') {
      this.integracaoFormControls.politicaComercial.setValue('');
      this.integracaoFormControls.politicaComercial.enable();
    } else {
      if (this.integracaoFormControls.integrador.value === null ||
        this.integracaoFormControls.integrador.value === '') {
        this.integracaoFormControls.politicaComercial.setValue('');
        this.integracaoFormControls.politicaComercial.enable();
      }
      else {
        this.integracaoFormControls.politicaComercial.setValue(1);
        this.integracaoFormControls.politicaComercial.disable();
      }
    }
  }

   validaIdLoja() {
    if (this.integracaoFormControls.integrador.value == 'HUB2B' ||
      this.integracaoFormControls.integrador.value == 'Tray Corp' ||
      this.integracaoFormControls.integrador.value == 'Plugg.TO' ||
      this.integracaoFormControls.integrador.value == 'Shopping de Preços' ||
      this.integracaoFormControls.integrador.value == 'CiaShop') {
      this.integracaoFormControls.idLoja.setValue('');
      this.integracaoFormControls.idLoja.enable();
      this.integracaoFormControls.idLoja.setValidators(Validators.required);
      this.integracaoFormControls.idLoja.updateValueAndValidity();
    } else {
      this.integracaoFormControls.idLoja.setValue('');
      this.integracaoFormControls.idLoja.disable();
      this.integracaoFormControls.idLoja.clearValidators();
      this.integracaoFormControls.idLoja.updateValueAndValidity();
    }
  }

  verifyIfIs

   buildSetting() {
    const urls = this.isUsedIntegrator ? this.urlIntegradorService.getDataSourceBack(this.integracaoFormControls.integrador.value, this.integradoresBack,
      this.integracaoFormControls.sellerIdExt?.value, this.integracaoFormControls.idLoja?.value, this.integracaoFormControls.politicaComercial?.value,
     this.integracaoForm) : null;
    let settings = {
      integration: {
        isIntegration : this.isUsedIntegrator,
        urls : (urls == null ) ? [] : [urls],
        appKey: this.integracaoFormControls.appKey.value,
        appToken: this.integracaoFormControls.appToken.value,
        comercialPolitics : this.integracaoFormControls.politicaComercial.value,
        urlPagSeguro: this.integracaoFormControls.vinculoPagseguro.value,
        idSeller: this.integracaoFormControls.idLoja.value // idSeller == idLoja
      }};
    return settings as Integration;
  }

  getIntegracao() {
    return this.settingsService.get().subscribe(
      (data: Integration) => {
        this.allIntegrationsData = data;
        this.allIntegrations = this.allIntegrationsData;
        if (this.allIntegrations === undefined || this.allIntegrations.length === 0) {
          this.validArrayIntegrations = false;

        } else {
          this.validArrayIntegrations = true;
        }
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      },
    );
  };

   gerarTokens() {
    this.createTokenFastService.get(this.integracaoFormControls.sellerId.value, 'appkey')
      .subscribe(
        ret => {
          ret = ret.key.split('.');
          this.integracaoFormControls.appKeyFastShop.setValue(ret[0]);
          this.integracaoFormControls.appTokenFastShop.setValue(ret[1]);
        },
        (error: newReponseI) => {
          if (error.status === 403) {
            if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile
              })
              .afterClosed().subscribe(() => {
                this.auth.logoutAndRemoveStorage();
              });
            }
            else {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile
              });
            }
          }
          else {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: 'Ocorreu uma falha, contate o administrador.'
            });
          }
        });
  }

   replaceSellerID() {
    if (this.integracaoFormControls.sellerId.value !== '') {
      this.dataSource.forEach(e => {
        if(e.url !== null){
          e.url = e.url.replace('{sellerid}', this.integracaoFormControls.sellerId.value);
          if (this.lastSellerId !== '') {
            e.url = e.url.replace(this.lastSellerId, this.integracaoFormControls.sellerId.value);
          }
        }
      });
    } else {
      if (this.lastSellerId !== '') {
        this.dataSource.forEach(e => {
          if(e.url !== null){
            e.url = e.url.replace(this.lastSellerId, '{sellerid}');
          }
        });
      }
    }
    this.lastSellerId = this.integracaoFormControls.sellerId.value;
  }

   replaceIdLoja() {
    if (this.integracaoFormControls.idLoja.value !== '') {
      this.dataSource.forEach(e => {
        if(e.url !== null){
          e.url = e.url.replace('{idloja}', this.integracaoFormControls.idLoja.value);
          if (this.lastIdLoja !== '') {
            e.url = e.url.replace(this.lastIdLoja, this.integracaoFormControls.idLoja.value);
          }
        }
      });
    } else {
      if (this.lastIdLoja !== '') {
        this.dataSource.forEach(e => {
          if(e.url !== null){
            e.url = e.url.replace(this.lastIdLoja, '{idloja}');
          }
        });
      }
    }
    this.lastIdLoja = this.integracaoFormControls.idLoja.value;
  }

   setPoliticaComercialUrl() {
    if (this.integracaoFormControls.politicaComercial.value !== '') {
      this.dataSource.forEach(e => {
        if(e.url !== null){
          e.url = e.url.replace('{sc}', this.integracaoFormControls.politicaComercial.value);
          if (this.lastPoliticaComercial !== '') {
            e.url = e.url.replace(this.lastPoliticaComercial, this.integracaoFormControls.politicaComercial.value);
          }
        }
      });
    } else {
      if (this.lastPoliticaComercial !== '') {
        this.dataSource.forEach(e => {
          if(e.url !== null){
            e.url = e.url.replace(this.lastPoliticaComercial, '{sc}');
          }
        });
      }
    }
    this.lastPoliticaComercial = this.integracaoFormControls.politicaComercial.value;
  }

}
