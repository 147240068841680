import { ProfileModel } from './../../models/profiles.model';
import { catchError, switchMap, retry } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EquipeFast } from './../../models/equipe-fast.model';
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { MatPaginatorIntl } from '@angular/material/paginator';

//MODELS
import { EquipeModel } from '../../models/team.model';

//SERVICES
import { EquipeFastshopService } from '../../services/equipe-fastshop.service';
import { ProfilesService } from '../../services/profiles.service';

//MODALS
import { EditarEquipeFastComponent } from '../../shared/modal/editar-equipe-fast/editar-equipe-fast.component';
import { DeleteEquipeFastComponent } from '../../shared/modal/deletar-equipe-fast/delete-equipe-fast.component';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { iif, of, throwError, Subscription } from 'rxjs';

export class NgbdModalContent {
  @Input() name;

  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: 'app-equipe-fastshop',
  templateUrl: './equipe-fastshop.component.html',
  styleUrls: ['./equipe-fastshop.component.scss'],
})
export class EquipeFastshopComponent implements OnInit, OnDestroy {
  constructor(
    private dialog: MatDialog,
    private equipeFastshopService: EquipeFastshopService,
    private profilesService: ProfilesService,
    private modalService: NgbModal,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute
  ) {}

  length = 100;
  pageIndex = 0;
  pageSize = 10;
  pagelength = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageEvent: PageEvent;
  displayedColumns: string[] = ['nome', 'email', 'perfil', 'actions'];

  team = {} as EquipeModel;
  teams: EquipeModel[];
  controlTableTeam = false;
  objectAll: any = {};
  objetcTeam: any = [];
  fakeData = [
    {
      email: 'não encontrado',
      groups: {
        description: 'não encontrado',
        profile: 'não encontrado',
        selected: false,
      },
      name: 'não encontrado',
      profiles: [],
    },
  ];
  equipeFast: EquipeFast = this.activatedRoute.snapshot.data.equipeFast;
  profiles: ProfileModel[] = this.activatedRoute.snapshot.data.profiles;
  roles: { [profile: string]: string }[] = [];

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  // @ViewChild(MatPaginator, { static: true }) paginatorEquipeFast: MatPaginator;
  filterControl = new FormControl(null, [Validators.email]);
  hasNext: boolean = !!this.equipeFast?.returnObject?.paginate?.nextToken;
  subscription = new Subscription();

  ngAfterViewInit() {}

  ngOnInit(): void {
    this.dataSource.data = [...this.equipeFast.returnObject.usersFast];
    this.listenFilter();
    this.listProfiles();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  listProfiles() {
    this.roles = this.profiles
      .map(({ profiles }) => profiles)
      .reduce((acc, crr) => acc.concat(crr), [])
      .reduce((acc,crr) => ({...acc, [crr.profile]:crr.description}), {});

    console.log(this.roles)
  }

  next() {
    this.equipeFastshopService
      .getEquipeFast(60, this.equipeFast.returnObject.paginate.nextToken)
      .subscribe((response) => {
        this.hasNext = !!response.returnObject?.paginate?.nextToken;
        this.equipeFast = response;
        this.dataSource.data = [...response.returnObject.usersFast];
      });
  }

  first() {
    this.equipeFastshopService.getEquipeFast(60, '').subscribe((response) => {
      this.hasNext = !!response.returnObject?.paginate?.nextToken;
      this.equipeFast = response;
      this.dataSource.data = [...response.returnObject.usersFast];
    });
  }

  listenFilter() {
    const subscription = this.filterControl.valueChanges
      .pipe(
        switchMap((value: string) =>
          iif(
            () => !!value,
            iif(
              () => this.filterControl.valid,
              this.equipeFastshopService.findEquipeFast(value.trim()),
              of()
            ),
            this.equipeFastshopService.getEquipeFast(60, '')
          )
        ),
        catchError((error) => {
          this.errorMessageService.openDialog({
            message: 'Não encontrado',
            messageCode: 'Usuário não encontrado.',
          });
          return throwError(error);
        }),
        retry()
      )
      .subscribe((response) => {
        if (response?.returnObject?.paginate) {
          this.dataSource.data = [...response.returnObject.usersFast];
        } else {
          this.dataSource.data = [response.returnObject];
        }
        this.hasNext = !!response.returnObject?.paginate?.nextToken;
        this.equipeFast = response;
      });
    this.subscription.add(subscription);
  }

  getProfiles() {
    this.profilesService.getProfiles().subscribe(
      (dados) => {
        let prof = [];
        dados.forEach((e) => {
          prof = prof.concat(e.profiles);
        });
        this.profiles = prof;
        this.getListEquipeFast();
      },
      (error) => {
        if (error.status === 403) {
          if (
            error.error.statusCustom.accessInfo.message === 'Token Expirado'
          ) {
            this.errorMessageService
              .openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile,
              })
              .afterClosed()
              .subscribe(() => {
                this.auth.logoutAndRemoveStorage();
              });
          } else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile,
            });
          }
        }
        console.log(error);
      }
    );
  }

  public getListEquipeFast() {
    // this.equipeFastshopService.getEquipeFast().subscribe((ret: any) => {
    //   const teams = ret.returnObject.usersFast;
    //   let fastUsers = [];
    //   teams.forEach(e => {
    //     if(e.groups.length > 0){
    //       const desc = this.profiles.find(pr=> pr.profile == e.groups[0]);
    //       if(desc !== undefined && e.profiles !== undefined){
    //         e.groups = [desc];
    //         e.profiles[0] = e.groups[0].description;
    //         if(e.groups[0].profile.includes('fastshop.')){
    //           fastUsers.push(e);
    //         }
    //       } else {
    //         fastUsers.push(e);
    //       }
    //     }
    //   });
    //   this.teams = fastUsers;
    //   this.objetcTeam = fastUsers;
    //   this.dataSource.data = this.objetcTeam;
    //   this.length = this.objetcTeam.length;
    //   if(this.objetcTeam.length > 0){
    //     this.controlTableTeam = true;
    //   }else{
    //     this.controlTableTeam = false;
    //   }
    // },
    // error =>{
    //   if (error.status === 403) {
    //     if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
    //       this.errorMessageService.openDialog({
    //         message: error.error.statusCustom.accessInfo.message,
    //         messageCode: error.error.statusCustom.profile
    //       })
    //       .afterClosed().subscribe(() => {
    //         this.auth.logoutAndRemoveStorage();
    //       });
    //     }
    //     else {
    //       this.errorMessageService.openDialog({
    //         message: error.error.statusCustom.accessInfo.message,
    //         messageCode: error.error.statusCustom.profile
    //       });
    //     }
    //   }
    //   console.log(error);
    // });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource);
    this.length = this.dataSource.filteredData.length;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public openModalDialogEditEquipeFast(email, team) {
    const dialogRef = this.dialog.open(EditarEquipeFastComponent, {
      width: '50%',
      id: 'modalEditContact',
      data: {
        email,
        team,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getListEquipeFast();
      }
    });
  }

  public openModalDialogDeleteEquipeFast(email) {
    const dialogRef = this.dialog.open(DeleteEquipeFastComponent, {
      width: '30%',
      data: {
        email,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getListEquipeFast();
      }
    });
  }
}
