import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CommissionI } from 'src/app/models/commission.model';
import { ComissionamentoService } from 'src/app/services/comissionamento.service';
import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ConfirmarAcaoService } from 'src/app/shared/modal/confirmar-acao/confirmar-acao.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { HistoricoModalComponent } from '../components/historico-modal/historico-modal.component';
interface newReponseI {
  status: number;
  error: {
    returnObject: {
      body: {
        code: number;
        message: string;
        messageCode: string;
      },
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}
@Component({
  selector: 'app-comissao-webstores-page',
  templateUrl: './comissao-webstores-page.component.html',
  styleUrls: ['./comissao-webstores-page.component.scss']
})
export class ComissaoWebstoresPageComponent implements OnInit {
  displayedColumnsComissionamentoWebstore: string[] = [
    'commissionMartPlace',
    'commissionSalesMan',
    'termStart',
    'termEnd',
    'buttons',
  ];


  length = 100;
  pageIndex = 0;
  pageSize = 10;
  pagelength = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  dataTable = [
    {        
      id: '',
      commissionMartPlace: '',
      commissionSalesMan: '',
      termStart: '',
      termEnd: '',
      brand: '',
      category: '',
      channel: '',
      dateOfExclusion: '',
      sku: '',
      skuName: '',
      type: '',
      user: '',
      webStore: '',
      buttonUpdate: true,
      buttonCancel: true
    }
  ]

  dataSourceComissionamento: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginatorComissionamento: MatPaginator;

  leadZeroDateTime: boolean = false;
  listSellerCommissionsData = [];
  userName: string = '';
  webstoreActive: string = 'Webstore não identificada';

  categoria: string = 'categoria';
  marca: string  = 'marca';
  produto: string = 'produto';
  comissaoBase: string = '';
  
  validaRegraComissao: boolean = false;
  disabledTooltip: boolean = false; 

  constructor(
    private auth: AuthService,
    private errorMessageService: ErrorMessageService,
    private comissionamentoService: ComissionamentoService,
    private confirmarAcao: ConfirmarAcaoService,
    private router: Router,
    private dialog: MatDialog,
    private storageService: StorageService

  ) { 
    this.userName = this.auth.getUserName();
    this.webstoreActive = this.storageService.storage.getItem('webstoreActive');
    this.dataSourceComissionamento.data = this.dataTable;
  }

  ngOnInit(): void {
    this.buscarComissaoPorWebstore();
    this.traducaoItensPaginacao();
  }

  ngAfterViewInit() {
    this.dataSourceComissionamento.paginator = this.paginatorComissionamento;
  }

  backToComissionamento() {
    this.router.navigate(['home/comissionamento']);
  }

  alterarComissaoBase() {
    
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceComissionamento.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceComissionamento.paginator) {
      this.dataSourceComissionamento.paginator.firstPage();
    }
  }

  openDialog(type: string): void {
    var description = this.webstoreActive;
    const dialogRef = this.dialog.open(HistoricoModalComponent, {
      width: '1100px',
      data: {
        type,
        description
      },
    });
  }

  formatDate(date: any) {
    var dataSplit = date.split('');
    var dia = dataSplit[0] + dataSplit[1];
    var mes = dataSplit[2] + dataSplit[3];
    var ano = dataSplit[4] + dataSplit[5] + dataSplit[6] + dataSplit[7];
    var dataFormatada = ano+'-'+mes+'-'+dia;
    date = dataFormatada;
    return date;
  }

  revertDate(comissao: CommissionI) {
    var dataStartSplit = comissao.termStart.split('-');
    var diaStart = dataStartSplit[2];
    var mesStart = dataStartSplit[1];
    var anoStart = dataStartSplit[0];
    comissao.termStart = diaStart+mesStart+anoStart;
    var dataEndSplit = comissao.termEnd.split('-');
    var diaEnd = dataEndSplit[2];
    var mesEnd = dataEndSplit[1];
    var anoEnd = dataEndSplit[0];
    comissao.termEnd = diaEnd+mesEnd+anoEnd;
    return comissao;
  }

  changeValue(event: Event, comissao: CommissionI) {
    // if(comissao.termStart !== null && comissao.termEnd !== null) {
    //   this.errorMessageService.openDialog({
    //     message: 'Atenção',
    //     messageCode: 'A criação e/ou atualização do comissionamento só será efetivada após a ação no campo Vigência (Fim).'
    //   });
    // }
    comissao.buttonUpdate = false;
    comissao.buttonCancel = false;
  }

  addItem() {
    this.listSellerCommissionsData.splice(
      1,0,
      {
        id: null,
        commissionMartPlace: 0,
        commissionSalesMan: 100,
        termStart: null,
        termEnd: null,
        brand: null,
        category: null,
        channel: null,
        dateOfExclusion: null,
        sku: null,
        skuName: null,
        type: 'WEBSTORE',
        user: this.userName,
        webStore: this.webstoreActive,
        buttonUpdate: false,
        buttonCancel: false
      }
    )
    this.dataSourceComissionamento.data = this.listSellerCommissionsData;
  }

  copyItem(event: CommissionI, index: number) {
    this.listSellerCommissionsData.splice(
      index + 1,0,
      {
        id: '',
        commissionMartPlace: event.commissionMartPlace,
        commissionSalesMan: event.commissionSalesMan,
        termStart: event.termStart,
        termEnd: event.termEnd,
        brand: event.brand,
        category: '',
        channel: '',
        dateOfExclusion: '',
        sku: '',
        skuName: '',
        type: 'WEBSTORE',
        user: this.userName,
        webStore: this.webstoreActive,
        buttonUpdate: false,
        buttonCancel: false
        }
    )
    this.dataSourceComissionamento.data = this.listSellerCommissionsData;
  }

  createOrEditComission(comissao: CommissionI) {
    if (comissao.id === "" || comissao.id === null || comissao.id === undefined) {
      this.criarComissao(comissao);
    } else {
      this.editarComissao(comissao);
    }
  }

  applyCommissionRule(comissao: CommissionI) {
    if(comissao.commissionMartPlace == 0 || comissao.commissionSalesMan == 0) {
      this.validaRegraComissao = false;
      return;
    }
    if(comissao.commissionSalesMan == 0 && comissao.commissionMartPlace != 0 ) {
      if(comissao.commissionMartPlace >= 3 && comissao.commissionMartPlace <= 50) {
        this.validaRegraComissao = true;
        
      } else {
        this.validaRegraComissao = false;
      }
    }
    if(comissao.commissionSalesMan != 0 && comissao.commissionMartPlace == 0 ) {
      if(comissao.commissionSalesMan >= 3 && comissao.commissionSalesMan <= 100) {
        this.validaRegraComissao = true; 
      } else {
        this.validaRegraComissao = false;
      }
    }
    if (comissao.commissionSalesMan > 0 && comissao.commissionMartPlace > 0) {
      if(comissao.commissionSalesMan >= 3 && comissao.commissionSalesMan <= 100 && comissao.commissionMartPlace >= 3 && comissao.commissionMartPlace <= 50) {
        this.validaRegraComissao = true;
        
      } else {
        this.validaRegraComissao = false;
      }
    }
  }

  criarComissao(comissao: CommissionI) {
    this.applyCommissionRule(comissao);
    this.revertDate(comissao);
    if(!this.validaRegraComissao) {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Percentual praticado deve estar entre 3% a 50%.'
      });
      return;
    }
    this.comissionamentoService.saveCommission(comissao).subscribe(
      success => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Comissão adicionada com sucesso.'
        });
        this.buscarComissaoPorWebstore();
      },
      (error) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        // else if (error.status === 403) {
        //   this.errorMessageService.openDialog({
        //     message: error.error.statusCustom.accessInfo.message,
        //     messageCode: error.error.statusCustom.profile
        //   });
        // }
        // else if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
        //   this.errorMessageService.openDialog({
        //     message: error.error.statusCustom.accessInfo.message,
        //     messageCode: error.error.statusCustom.profile
        //   });
        //   this.auth.logoutAndRemoveStorage();
        // }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.buscarComissaoPorWebstore();
        }
      }
    )
  }

  editarComissao(comissao: CommissionI) {
    this.applyCommissionRule(comissao);
    this.revertDate(comissao);
    if(!this.validaRegraComissao) {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Percentual praticado deve estar entre 3% a 50%.'
      });
      this.buscarComissaoPorWebstore();
      return;
    }
    this.comissionamentoService.editCommission(comissao).subscribe(
      success => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Comissão atualizada com sucesso.'
        });
        this.buscarComissaoPorWebstore();
      },
      (error) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject
          });
          this.buscarComissaoPorWebstore();
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.buscarComissaoPorWebstore();
        }
      }
    )
  }



  excluirComissao(id: string) {
    this.comissionamentoService.deleteCommission(id).subscribe(
      success => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Comissão excluída com sucesso.'
        });
        this.buscarComissaoPorWebstore();
      },
      error => {
        this.buscarComissaoPorWebstore();
      }
    )
  }

  confirmarExclusaoModal(comissao: CommissionI) {
    const dialog = this.confirmarAcao.openDialog({
      title: 'Exclusão de comissão',
      message: 'Deseja realmente excluir esta comissão?'
    });

    dialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.excluirComissao(comissao.id);
      }
    });
  }

  buscarComissaoPorWebstore() {
    this.comissionamentoService.getCommissionByWebstore(this.webstoreActive).subscribe(
      success => {
        if(success.returnObject.listWebstoreCommissions !== null) {
          this.comissaoBase = success.returnObject.standardCommission;
          this.listSellerCommissionsData = success.returnObject.listWebstoreCommissions;
          this.dataSourceComissionamento.data = this.listSellerCommissionsData;
          this.dataSourceComissionamento.data.forEach(e => {
            e.buttonCancel = true;
            e.buttonUpdate = true;
            if (e.termStart !== null) {
              e.termStart = this.formatDate(e.termStart);
              e.termEnd = this.formatDate(e.termEnd);
            }
          });
        }
      },
      (error: newReponseI) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.body.messageCode
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    )
  }


  confirmarUpdateModal(comissao: CommissionI) {
    const dialog = this.confirmarAcao.openDialog({
      title: 'Confirmar atualização',
      message: 'Deseja realmente atualizar esta comissão?'
    });

    dialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.createOrEditComission(comissao);
      }
    });
  }

  confirmarCancelModal(comissao: CommissionI) {
    const dialog = this.confirmarAcao.openDialog({
      title: 'Cancelar alteração',
      message: 'Deseja realmente cancelar a alteração?'
    });

    dialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.buscarComissaoPorWebstore();
      }
    });
  }
  

  traducaoItensPaginacao() {
    this.paginatorComissionamento._intl.firstPageLabel = 'Primeira página';
    this.paginatorComissionamento._intl.lastPageLabel = 'Última página';
    this.paginatorComissionamento._intl.nextPageLabel = 'Proxima página';
    this.paginatorComissionamento._intl.previousPageLabel = 'Página anterior';
    this.paginatorComissionamento._intl.itemsPerPageLabel = 'Itens por página';
  }

}
