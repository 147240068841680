import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service'

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  save(account: Object) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.post(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts`,
      account,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  update(account: Object) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.put(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts`,
      account,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  public get(): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(`${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}`,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }
}
