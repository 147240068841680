import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { WebstoreService } from 'src/app/services/webstore.service';

@Component({
  selector: 'app-webstores-atividades',
  templateUrl: './webstores-atividades.component.html',
  styleUrls: ['./webstores-atividades.component.scss']
})
export class WebstoresAtividadesComponent implements OnInit, OnDestroy {

  public show = false;
  public webStores = [];
  private subscriptions: Subscription[] = [];

  constructor(
    private webstoreService: WebstoreService,
  ) { }

  ngOnInit(): void {
    this.getWebstores();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private getWebstores() {

    const subscription = this.webstoreService.getWebstore().subscribe(res => {

      let webStores = [];
      console.log(`success`, res);

      if (!!res.length) {
        webStores = res[0].webStores;
      }

      this.webStores = webStores;
      this.show = true;

    }, err => {
      console.log(`err`, err);
      this.show = true;
    });

    this.subscriptions.push(subscription);
  }
}
