import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { ComissionamentoService } from './../../services/comissionamento.service';
import { CommissionI } from './../../models/commission.model'
import { ConfirmarAcaoService } from 'src/app/shared/modal/confirmar-acao/confirmar-acao.service';
import { MatDialog } from '@angular/material/dialog';
import { HistoricoModalComponent } from './components/historico-modal/historico-modal.component';

// import * as moment from 'moment';

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      body: {
        code: number;
        message: string;
        messageCode: string;
      },
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-comissionamento',
  templateUrl: './comissionamento.component.html',
  styleUrls: ['./comissionamento.component.scss']
})

export class ComissionamentoComponent implements OnInit {
  displayedColumnsComissionamento: string[] = [
    'commissionMartPlaceSeller',
    'commissionSalesManSeller',
    'termStart',
    'termEnd',
    'buttons',
  ];

  userName: string = '';
  sellerId: string = '';

  dataTable = [
    {        
      id: '',
      commissionMartPlace: '',
      commissionSalesMan: '',
      termStart: '',
      termEnd: '',
      brand: '',
      category: '',
      channel: '',
      dateOfExclusion: '',
      sku: '',
      skuName: '',
      type: 'SELLER',
      user: this.userName,
      webStore: '',
      buttonUpdate: true,
      buttonCancel: true
    }
  ]

  length = 100;
  pageIndex = 0;
  pageSize = 5;
  pagelength = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];


  dataSourceComissionamento: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginatorComissionamento: MatPaginator;

  leadZeroDateTime: boolean = false;
  listSellerCommissionsData;

  validaRegraComissao: boolean = false;
  disabledTooltip: boolean = false; 

  constructor(
    private storageService: StorageService,
    private auth: AuthService,
    private dialog: MatDialog,
    private errorMessageService: ErrorMessageService,
    private comissionamentoService: ComissionamentoService,
    private confirmarAcao: ConfirmarAcaoService,

  ) {
    this.userName = this.auth.getUserName();
    this.sellerId = this.storageService.storage.getItem('sellerId');
    this.dataSourceComissionamento.data = this.dataTable;
   }



  ngOnInit(): void {
    this.buscarComissao();
    this.traducaoItensPaginacao();
  }

  ngAfterViewInit() {
    this.dataSourceComissionamento.paginator = this.paginatorComissionamento;
  }

  openDialog(type: string): void {
    var description = this.sellerId;
    const dialogRef = this.dialog.open(HistoricoModalComponent, {
      width: '1100px',
      data: {
        type,
        description
      },
    });
  }


  addItem() {
    this.listSellerCommissionsData.unshift(
      {
        id: null,
        commissionMartPlace: 0,
        commissionSalesMan: 100,
        termStart: null,
        termEnd: null,
        brand: null,
        category: null,
        channel: null,
        dateOfExclusion: null,
        sku: null,
        skuName: null,
        type: 'SELLER',
        user: this.userName,
        webStore: null,
        buttonUpdate: false,
        buttonCancel: false
      }
    )
    this.dataSourceComissionamento.data = this.listSellerCommissionsData;
  }



  copyItem(event: CommissionI, index: number) {
    this.listSellerCommissionsData.splice(
      index + 1,0,
      {
        id: '',
        commissionMartPlace: event.commissionMartPlace,
        commissionSalesMan: event.commissionSalesMan,
        termStart: event.termStart,
        termEnd: event.termEnd,
        brand: event.brand,
        category: '',
        channel: '',
        dateOfExclusion: '',
        sku: '',
        skuName: '',
        type: 'SELLER',
        user: this.userName,
        webStore: '',
        buttonUpdate: false,
        buttonCancel: false
      }
    )
    this.dataSourceComissionamento.data = this.listSellerCommissionsData;
  }

  createOrEditComission(comissao: CommissionI) {
    if (comissao.id === "" || comissao.id === null || comissao.id === undefined) {
      comissao.webStore = "";
      this.criarComissao(comissao);
    } else {
      this.editarComissao(comissao);
    }
  }

  changeValue(event: Event, comissao?: CommissionI) {
    // if(comissao.termStart !== null && comissao.termEnd !== null) {
    //   this.errorMessageService.openDialog({
    //     message: 'Atenção',
    //     messageCode: 'A criação e/ou atualização do comissionamento só será efetivada após a ação no campo Vigência (Fim).'
    //   });
    // }
    // return event.toString().replace(".", ",");
    comissao.buttonUpdate = false;
    comissao.buttonCancel = false;
  }

  applyCommissionRule(comissao: CommissionI) {
    if(comissao.commissionMartPlace == 0 || comissao.commissionSalesMan == 0) {
      this.validaRegraComissao = false;
      return;
    }
    if(comissao.commissionSalesMan == 0 && comissao.commissionMartPlace != 0 ) {
      if(comissao.commissionMartPlace >= 3 && comissao.commissionMartPlace <= 50) {
        this.validaRegraComissao = true;
        
      } else {
        this.validaRegraComissao = false;
      }
    }
    if(comissao.commissionSalesMan != 0 && comissao.commissionMartPlace == 0 ) {
      if(comissao.commissionSalesMan >= 3 && comissao.commissionSalesMan <= 100) {
        this.validaRegraComissao = true; 
      } else {
        this.validaRegraComissao = false;
      }
    }
    if (comissao.commissionSalesMan > 0 && comissao.commissionMartPlace > 0) {
      if(comissao.commissionSalesMan >= 3 && comissao.commissionSalesMan <= 100 && comissao.commissionMartPlace >= 3 && comissao.commissionMartPlace <= 50) {
        this.validaRegraComissao = true;  
      } else {
        this.validaRegraComissao = false;
      }
    }
  }
  
  criarComissao(comissao: CommissionI) {
    this.applyCommissionRule(comissao);
    this.revertDate(comissao)
    if(!this.validaRegraComissao) {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Percentual praticado deve estar entre 3% a 50%.'
      });
      this.buscarComissao();
      return;
    }
    this.comissionamentoService.saveCommission(comissao).subscribe(
      success => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Comissão adicionada com sucesso.'
        });
        this.buscarComissao();
      },
      (error) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject
          });
          // this.buscarComissao();
        }
        else if (error.status === 403) {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
          this.buscarComissao();
        }
        else if (error.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.message,
            messageCode: error.status
          });
          this.auth.logoutAndRemoveStorage();
        }
        else {
          this.errorMessageService.openDialog({
            message: error.error,
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.buscarComissao();
        }
      }
    )
  }

  editarComissao(comissao: CommissionI) {
    this.applyCommissionRule(comissao);
    this.revertDate(comissao)
    if(!this.validaRegraComissao) {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Percentual praticado deve estar entre 3% a 50%.'
      });
      this.buscarComissao();
      return;
    }
    this.comissionamentoService.editCommission(comissao).subscribe(
      success => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Comissão atualizada com sucesso.'
        });
        this.buscarComissao();
      },
      (error) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject
          });
          this.buscarComissao();
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
            this.buscarComissao();
          }
        }
        // else if (error.status === 403) {
        //   this.errorMessageService.openDialog({
        //     message: error.error.statusCustom.accessInfo.message,
        //     messageCode: error.error.statusCustom.profile
        //   });
        //   this.buscarComissao();
        // }
        // else if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
        //   this.errorMessageService.openDialog({
        //     message: error.error.statusCustom.accessInfo.message,
        //     messageCode: error.error.statusCustom.profile
        //   });
        //   this.auth.logoutAndRemoveStorage();
        // }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.buscarComissao();
        }
      }
    )
  }

  excluirComissao(id: string) {
    this.comissionamentoService.deleteCommission(id).subscribe(
      success => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Comissão excluída com sucesso.'
        });
        this.buscarComissao();
      },
      error => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
            this.buscarComissao();
          }
        }
        this.buscarComissao();
      }
    )
  }


  confirmarUpdateModal(comissao: CommissionI) {
    const dialog = this.confirmarAcao.openDialog({
      title: 'Confirmar atualização',
      message: 'Deseja realmente atualizar esta comissão?'
    });

    dialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.createOrEditComission(comissao);
      }
    });
  }

  confirmarCancelModal(comissao: CommissionI) {
    const dialog = this.confirmarAcao.openDialog({
      title: 'Cancelar alteração',
      message: 'Deseja realmente cancelar a alteração?'
    });

    dialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.buscarComissao();
      }
    });
  }

  confirmarExclusaoModal(comissao: CommissionI) {
    const dialog = this.confirmarAcao.openDialog({
      title: 'Exclusão de comissão',
      message: 'Deseja realmente excluir está comissão?'
    });

    dialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.excluirComissao(comissao.id);
      }
    });
  }

  formatDate(date: any) {
    var dataSplit = date.split('');
    var dia = dataSplit[0] + dataSplit[1];
    var mes = dataSplit[2] + dataSplit[3];
    var ano = dataSplit[4] + dataSplit[5] + dataSplit[6] + dataSplit[7];
    var dataFormatada = ano+'-'+mes+'-'+dia;
    date = dataFormatada;
    return date;
  }

  revertDate(comissao: CommissionI) {
    console.log(">>> datas comissao: " + comissao.termStart + " - " + comissao.termEnd)
    var dataStartSplit = comissao.termStart.split('-');
    var diaStart = dataStartSplit[2];
    var mesStart = dataStartSplit[1];
    var anoStart = dataStartSplit[0];
    comissao.termStart = diaStart+mesStart+anoStart;
    var dataEndSplit = comissao.termEnd.split('-');
    var diaEnd = dataEndSplit[2];
    var mesEnd = dataEndSplit[1];
    var anoEnd = dataEndSplit[0];
    comissao.termEnd = diaEnd+mesEnd+anoEnd;
    return comissao;
  }

  buscarComissao() {
    this.comissionamentoService.getCommission().subscribe(
      success => {
        this.listSellerCommissionsData = success.returnObject.listSellerCommissions;
        this.dataSourceComissionamento.data = this.listSellerCommissionsData;
        this.dataSourceComissionamento.data.forEach(e => {
          if (e.termStart !== null) {
            e.termStart = this.formatDate(e.termStart);
            e.termEnd = this.formatDate(e.termEnd);
          }
          e.buttonCancel = true;
          e.buttonUpdate = true;
        });
      },
      (error: newReponseI) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.body.messageCode
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    )
  }

  

  traducaoItensPaginacao() {
    this.paginatorComissionamento._intl.firstPageLabel = 'Primeira página';
    this.paginatorComissionamento._intl.lastPageLabel = 'Última página';
    this.paginatorComissionamento._intl.nextPageLabel = 'Proxima página';
    this.paginatorComissionamento._intl.previousPageLabel = 'Página anterior';
    this.paginatorComissionamento._intl.itemsPerPageLabel = 'Itens por página';
  }

}
