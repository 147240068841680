import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../shared/auth/auth.service';
import { map } from 'rxjs/operators';
import { LoginService } from 'src/app/services/login.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    key?: string;
    status?: string;
    messageCode?: string;
    warning?: boolean;
  }
}

interface ResponseSuccessI {
  message: string;
  key?: string;
  status?: string;
}

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  hide = true;
  loginForm: FormGroup;
  passwordType: string;
  isAuthenticating: boolean = false;
  isInvalid: boolean = false;
  pwdPattern = "^(?=.*[A-Z])(?=.*[!@#%?_;<>)({}´`|\/,.^~$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$";

  @Output() enviarUsername = new EventEmitter<String>();
  static enviandoUsername = new EventEmitter<String>();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private loginService: LoginService,
    private errorMessageService: ErrorMessageService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.email]],
      Password: ['', [Validators.required, Validators.minLength(6), Validators.pattern(this.pwdPattern)]]
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  LogIn() {
    const username = this.loginForm.get('userName').value;
    const password = this.loginForm.get('Password').value;
    this.isAuthenticating = true;

    /* TODO(tulio) - tirar esse delay no futuro*/
    setTimeout(() => {
      if (this.loginForm.valid) {
        this.loginService.signIn(username, password).subscribe(
          (resp: ResponseSuccessI) => {
            this.loginForm.reset({}, { onlySelf: false, emitEvent: false });
            this.isAuthenticating = false;
            if (resp.key === 'NEW_PASSWORD_REQUIRED') {
              //this.router.navigate(['change-password']);
              this.router.navigateByUrl('change-password', { state: { username: username, password: password},  })
            } else {
              this.authService.login(resp);
              this.router.navigate(['home']);
              //http://dev.fastshopdev.com:4200/home
            }
          },
          (error: ResponseI) => {
            console.log(error);
            this.isInvalid = true;
            this.isAuthenticating = false;
          }
        );
      }
    })
  }

  LoginFastShop() {
    console.log('Funcionalidade ainda não implementada.');
  }

}
