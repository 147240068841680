import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';
import { CommissionI } from './../models/commission.model';
import { CommissionF } from './../models/commission.model';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})

export class ComissionamentoService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  saveCommission(comissao: CommissionI) {
    console.log("save - comissao: " + JSON.stringify(comissao));
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.post(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/commission`,
      comissao,
      { headers: this.getNewHeaders() }
    );
  }

  editCommission(comissao: CommissionF) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.put(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/commission/${comissao.id}`,
      comissao,
      { headers: this.getNewHeaders() }
    );
  }

  getCommission() {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/commission`,
      // `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/commission`,
      {headers: this.getNewHeaders()})
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getChannel() {
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/channels`,
      {headers: this.getNewHeaders()})
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getCommissionByWebstore(webstore: string) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/account/${sellerId}/${webstore}/commission`,
      {headers: this.getNewHeaders()})
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getCommissionHistoric(type: string) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/commission/history/types/${type}`,
      {headers: this.getNewHeaders()})
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getCommissionHistoricByName(type: string, name: string) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/commission/history/types/${type}/${name}`,
      {headers: this.getNewHeaders()})
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  deleteCommission(id: string) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.delete<CommissionI>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/commission/${id}`,
      { headers: this.getNewHeaders() }
    ).map(response => {
      return response;
    })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  downloadComission(dataInicio: string, dataFim: string, webStore: string): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/webStore/${webStore}/accounts/${sellerId}/commission/csvDownload?dataIni=${dataInicio}&dataFin=${dataFim}`,
      { headers: this.getNewHeaders(), responseType: 'blob' },
      )
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  downloadAllComission(webStore: string): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/webStore/${webStore}/accounts/${sellerId}/commission/csvDownload`,
      { headers: this.getNewHeaders(), responseType: 'blob' },
      )
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  uploadDocuments(file: File, webStore: string): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    const formdata = new FormData();
    formdata.append('file', file);
    return this.http.post(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/webStore/${webStore}/commission/csvUpload`,
      formdata,
      { headers: this.getNewHeadersUpload() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  getNewHeadersUpload() {
    return new HttpHeaders({
      'accept': '*/*',
      'seller-api-version': 'v1',
    });
  }

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }


}
