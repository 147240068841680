import { EquipeFastshopService } from './../../services/equipe-fastshop.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { EquipeFast } from '../../models/equipe-fast.model';
import { Observable } from 'rxjs';
@Injectable({providedIn: 'root'})
export class EquipeFastResolver implements Resolve<EquipeFast>{
    constructor(private equipeFastService:EquipeFastshopService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EquipeFast> {
        return this.equipeFastService.getEquipeFast(60, '')
    }

}