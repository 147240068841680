import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { ActivitiesService } from '../services/activities.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number,
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-incluir-arquivo-atividade',
  templateUrl: './incluir-arquivo-atividade.component.html',
  styleUrls: ['./incluir-arquivo-atividade.component.scss']
})
export class IncluirArquivoAtividadeComponent implements OnInit {

  incluirArquivoForm: FormGroup;
  file: File;
  nameFile: string;
  timeLoadBar: boolean = false;
  isErrorUpload: boolean = false;
  isSuccessUpload: boolean = false;
  fileName: string;
  description: string;

  constructor(
    @Optional() private dialogRef: MatDialogRef<IncluirArquivoAtividadeComponent>,
    private formBuilder: FormBuilder,
    private activitiesService: ActivitiesService,
    private errorMessageService: ErrorMessageService,
    @Inject(MAT_DIALOG_DATA) public data: {
      groupCrmCode,
      activityId
    }
    ) {}

  ngOnInit(): void {
    this.incluirArquivoForm = this.formBuilder.group({
      description: ['', Validators.compose([
        Validators.required
      ])],
    });
  }

  onSelectFile(e) {
    if (e.target.files) {
      this.file = e.target.files[0];
      this.nameFile = this.file.name;
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
        this.timeLoadBar = true;
          setTimeout(() => {
            this.timeLoadBar = false;
            this.isErrorUpload = false;
            this.isSuccessUpload = true;
          }, 2000);
      }
    }
  }

  async uploadAttachment() {
    const formIncluirArquivo = this.incluirArquivoForm.getRawValue() as any;
    this.description = formIncluirArquivo.description;

    this.activitiesService.uploadAttachment(this.data.groupCrmCode, this.data.activityId, 
      this.file, this.description).subscribe(
      (resp: ResponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: "Upload realizado com sucesso!"
        });
        this.incluirArquivoForm.reset({}, { onlySelf: false, emitEvent: false });
        setTimeout(() => {
          
          this.close();
        }, 4000);
      }, 
      (error: newReponseI) => {
        this.errorMessageService.openDialog({
          message: 'ERRO',
          messageCode: error.error.returnObject.messageCode
        });
      }
    );
  }

  close() {
    this.dialogRef.close();
  };
}
