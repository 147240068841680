import { Component, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

//Models
import { PreCadastroI } from '../../models/pre-cadastro.model';
import { Account } from '../../models/account.model';
import { DEPARTAMENT } from '../../models/departaments.model';
//msg erro
import { MessageErrorComponent } from 'src/app/shared/components/message-error/message-error.component';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';

//services
import { PreCadastroService } from './../../services/pre-cadastro.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { DepartmentsService } from './../../services/departments.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number,
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-pre-cadastro',
  templateUrl: './pre-cadastro.component.html',
  styleUrls: ['./pre-cadastro.component.scss']
})
export class PreCadastroComponent implements OnInit {
  hide = true; 
  registerForm: FormGroup;
  preCadastro: PreCadastroI;
  departamentos: DEPARTAMENT[];
  pwdPattern = "^(?=.*[A-Z])(?=.*[!@#%?_<>)({}´`|\/,.^~$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$";

  valueDepartamentInitial;
  sellerId: string = '';
  phoneMask = '';
  
  constructor(
    @Optional() private dialogRef: MatDialogRef<MessageErrorComponent>,
    private formBuilder: FormBuilder,
    private preCadastroService: PreCadastroService,
    private errorMessageService: ErrorMessageService,
    private departmentsService: DepartmentsService,
    private auth: AuthService
    ) { }

  ngOnInit(): void {
    this.departmentsService.getAllDepartaments().subscribe(data => {
      this.departamentos = data;
    })
  
    this.registerForm = this.formBuilder.group({
      id: [''],
      cnpj: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(18)]],
      sellerId: ['', Validators.required],
      companyName: ['', Validators.required],
      userName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      departmentName: ['', Validators.required],
    });
  }

  setPhoneMask() {
    if (this.phone) {
      this.phoneMask = this.phone.length > 10
        ? '(00) 00000-0000'
        : '(00) 00000-0000||(00) 0000-0000';
    }
  }

  get phone(): string {
    return this.registerForm.controls['phone'].value;
  }

  disabled() {
    this.hide = false;
  }

  departamentSplit() {
    let a = this.registerForm.get('departmentName').value;
    this.valueDepartamentInitial = a;
    a = a.split(" ");
    this.f.departmentName.setValue(a);
  }

  departamentValueDefault(){
    if (this.valueDepartamentInitial !== undefined)  {
      this.f.departmentName.setValue(this.valueDepartamentInitial);
    }

  }

  onSubmit() {
    if (this.registerForm.invalid) {
      return;
    }
    if (this.registerForm.valid) {
      this.departamentSplit();
      const preCadastro = this.registerForm.getRawValue() as PreCadastroI;
      if(preCadastro.id){
        this.update(preCadastro);
      }else{
        this.save(preCadastro);
      }
    }
  }

  save(preCadastro : PreCadastroI){
    this.preCadastroService.save(preCadastro).subscribe(
      (resp: ResponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Pré cadastro finalizado com sucesso. '
        });
        this.registerForm.reset({},{ onlySelf: false, emitEvent: false} );
      },
      (error: ResponseI) => {
        this.departamentValueDefault();
        if (error.error.messageCode === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.message,
            messageCode: error.error.messageCode
          });
          this.auth.logoutAndRemoveStorage();
        } 
        if (error.error.code !== null || error.error.code !== undefined) {
          this.errorMessageService.openDialog({
            message: error.error.message,
            messageCode: error.error.messageCode
          });
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    );
  }

  update(preCadastro : PreCadastroI){
    this.preCadastroService.update(preCadastro).subscribe(
      (resp: ResponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Pré cadastro atualizado com sucesso. '
        });
        this.registerForm.reset({},{ onlySelf: false, emitEvent: false} );
      },
      (error: ResponseI) => {
        this.departamentValueDefault();
        if (error.error.messageCode === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.message,
            messageCode: error.error.messageCode
          });
          this.auth.logoutAndRemoveStorage();
        } 
        if (error.error.code !== null || error.error.code !== undefined) {
          this.errorMessageService.openDialog({
            message: error.error.message,
            messageCode: error.error.messageCode
          });
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    );
  }

  getDepartaments() {
    this.departmentsService.getAllDepartaments()
      .subscribe(dados => {
        this.departamentos = dados;
      },
      (error: ResponseI) => {
        if (error.error.messageCode === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.message,
            messageCode: error.error.messageCode
          });
          this.auth.logoutAndRemoveStorage();
        }
        if (error.error.code === 403) {
          this.errorMessageService.openDialog({
            message: error.error.message,
            messageCode: error.error.messageCode
          });
        }
        // else {
        //   this.errorMessageService.openDialog({
        //     message: 'ERRO',
        //     messageCode: 'Ocorreu uma falha, contate o administrador.'
        //   });
        // }
      }
      );
  }

  get f() {
    return this.registerForm.controls;
  }

  public buscaPreAccount(){
    this.preCadastroService.getPreAccount().subscribe(
      (data: Account) => {
        var a = data;
        this.f.id.setValue(a.id);
        this.f.cnpj.setValue(a.businessInfo.cnpj);
        this.f.companyName.setValue(a.businessInfo.companyName);
        this.f.sellerId.setValue(a.sellerId);
      },
      (error: ResponseI) => {
        if (error.error.messageCode === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.message,
            messageCode: error.error.messageCode
          });
          this.auth.logoutAndRemoveStorage();
        }
        if (error.error.code === 403) {
          this.errorMessageService.openDialog({
            message: error.error.message,
            messageCode: error.error.messageCode
          });
        }
        if (error.error.code === 404) {
          this.errorMessageService.openDialog({
            message: error.error.message,
            messageCode: error.error.messageCode
          });
        }
        if (error.error.code === 410) {
          this.errorMessageService.openDialog({
            message: error.error.message,
            messageCode: error.error.messageCode
          });
        }
        // else {
        //   this.errorMessageService.openDialog({
        //     message: 'ERRO',
        //     messageCode: 'Ocorreu uma falha, contate o administrador.'
        //   });
        // }
      }
    );
  }

  public buscaCnpj() {
    const cnpj = this.f.cnpj.value;
      this.preCadastroService.getCnpj(cnpj)
        .subscribe( account =>  {
          if(account.statusPublishEnum === 'PREREGISTER') {
            var a = account;
            this.f.id.setValue(a.id);
            this.f.cnpj.setValue(a.businessInfo.cnpj);
            this.f.companyName.setValue(a.businessInfo.companyName);
            this.f.sellerId.setValue(a.sellerId);
            var c = a.contacts[0];
            this.f.userName.setValue(c.name);
            this.f.email.setValue(c.email);
            if(c.phone.length > 10) {
              this.phoneMask = '(00) 00000-0000';
            } else {
              this.phoneMask = '(00) 0000-0000';
            }
            this.f.phone.setValue(c.phone);
            var d = c.responsibility[0];
            var dept =  this.departamentos.find( dep => dep.id = d);
            this.f.departmentName.setValue(dept.id);
          } else {
            this.errorMessageService.openDialog({
              message: 'Pré cadastro finalizado',
              messageCode: 'Pré cadastro não pode ser mais alterado.'
            });
            this.f.cnpj.setValue('');
            this.f.id.setValue('');
            this.f.companyName.setValue('');
            this.f.userName.setValue('');
            this.f.sellerId.setValue('');
            this.f.email.setValue('');
            this.f.phone.setValue('');
            this.f.departmentName.setValue('');
          }
        },
        (error: ResponseI) => {
            if (error.error.messageCode === 'Token Expirado') {
              this.errorMessageService.openDialog({
                message: error.error.message,
                messageCode: error.error.messageCode
              });
              this.auth.logoutAndRemoveStorage();
            }
            if (error.error.code === 403) {
              this.errorMessageService.openDialog({
                message: error.error.message,
                messageCode: error.error.messageCode
              });
            }
              
            if (error.error.code === 404) {
              const companyName = this.f.companyName.value;
              const sellerId = this.f.sellerId.value;
              if (companyName != "" && sellerId != "") {
                this.f.id.setValue('');
                this.f.companyName.setValue('');
                this.f.userName.setValue('');
                this.f.sellerId.setValue('');
                this.f.email.setValue('');
                this.f.phone.setValue('');
                this.f.departmentName.setValue('');
              }
              console.log(cnpj + " CNPJ não encontrado");
            } else
              console.log("Ocorreu um erro, tente novamente!");
          }
        );
    }

}