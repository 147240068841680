import { error } from '@angular/compiler/src/util';
import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { WebstoreService } from './../../../services/webstore.service';
import { LogoService } from './../../../services/logo.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { ConfirmarAcaoService } from 'src/app/shared/modal/confirmar-acao/confirmar-acao.service';

import { WebstoreI, transferModelMock } from './../../../models/webstore.model';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { Subscription } from 'rxjs';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      body: {
        code: number;
        message: string;
        messageCode: string;
      }
      code?: number;
      message?: string;
      messageCode?: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

export enum webstoreActiveEnum {
  invalida = 1,
  desativada = 2,
  ativada = 3
}

@Component({
  selector: 'app-new-webstore',
  templateUrl: './new-webstore.component.html',
  styleUrls: ['./new-webstore.component.scss']
})
export class NewWebstoreComponent implements OnInit {

  transferModeOption: any;
  installmentOption: any;

  logoName;
  logoUrlExibe;
  imageUrl = "./../../../../assets/svg/outline-backup-24px.svg";

  webstoreId;
  id: string = '';
  isInvalidUpload: boolean = false;
  isAuthenticating: boolean = false;
  isActivateWebButton: boolean = false;


  controlWebstore;
  statusActiveDeactive;

  commissioningFastControl;
  installmentFastControl;
  transferModelFastControl;

  disabled = true;
  statusActiveDeactiveProducts;

  webstoreForm: FormGroup;

  subscriptions = new Subscription();

  file: File;
  preview: boolean = false;
  profile: string = 'null';
  valorDias: string = ' dia';

  @Input() webstoreInterno: WebstoreI;
  @Input() allWebstoresInterno;
  @Input() newWebStore: WebstoreI;
  @Output() createUpdate: EventEmitter<any> = new EventEmitter();
  @Output() createLogo: EventEmitter<any> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private logoService: LogoService,
    private errorMessageService: ErrorMessageService,
    private webstoreService: WebstoreService,
    private confirmarAcao: ConfirmarAcaoService,
    private auth: AuthService,
    private storage: StorageService

  ) {
    this.profile = this.storage.storage.getItem(`profile`);
    if (this.profile === 'fastshop.admin.cadastroseller') {
      this.disabled = false;
    }
  }

  ngOnInit(): void {
    this.controlWebstore = webstoreActiveEnum[1];

    this.transferModeOption = transferModelMock;

    this.webstoreForm = this.formBuilder.group({
      id: [''],
      flagUtilizarIntegrador: [false, Validators.required],
      token: ['', Validators.required],
      cep: ['', Validators.required],
      activateSeller: [false],
      activateAutoSaleProduct: [false],
      commissioning: ['', [Validators.min(4), Validators.max(25)]],
      deliveryPolicy: ['', Validators.required],
      description: ['', Validators.required],
      // deadlineDispatch: [''],
      installment: ['', [Validators.min(1), Validators.max(12), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      file: [''],
      logoUrl: [''],
      name: ['', Validators.required],
      groupCrmCode: [''],
      returnPolicy: ['', Validators.required],
      transferModel: [''],
    });
    this.setDataWebstore();
    this.validWebstoreActiveInative();

    if (this.logoUrlExibe !== null && this.logoUrlExibe !== undefined) {
      this.isInvalidUpload = this.logoUrlExibe.length > 1 ? true : false;
      this.logoName = this.logoUrlExibe.split("/")[this.logoUrlExibe.split("/").length - 1];
    }
    this.isRequireAndEnableInput();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get f() {
    return this.webstoreForm.controls;
  }

  isRequireAndEnableInput() {
    const subscription = this.f.flagUtilizarIntegrador.valueChanges.subscribe(isUsed => {
      this.isEnabledInputs(isUsed);
    })
    this.subscriptions.add(subscription);
  }

  isEnabledInputs(option: boolean) {
    const disableOrEnable = option ? 'enable' : 'disable';
    if (disableOrEnable === 'disable') {
      this.f.token.setValue('');
      this.f.cep.setValue('');
    }
    console.log(disableOrEnable);
    this.f.token[disableOrEnable]();
    this.f.cep[disableOrEnable]();
  }

  FastShopControls() {
    const w = this.webstoreInterno;
    if (!w) return;
    this.commissioningFastControl.setValue(w.commissioning);
    this.installmentFastControl.setValue(w.installment);
    this.transferModelFastControl.setValue(w.transferModel);
    // this.deadlineDispatchFastControl.setValue(w.deadlineDispatch);
  }


  activateAutoSaleProduct() {
    this.statusActiveDeactiveProducts = this.statusActiveDeactiveProducts !== true ? true : false;
    this.webstoreService.activeProducts(this.id, this.webstoreId, this.statusActiveDeactiveProducts)
      .subscribe(
        () => {
        },
        (error: newReponseI) => {
          if (error.status === 403) {
            if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile
              })
              .afterClosed().subscribe(() => {
                this.auth.logoutAndRemoveStorage();
              });
            }
            else {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile
              });
            }
          }
          else {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: 'Ocorreu uma falha, contate o administrador.'
            });
          }
        }
      )
  }

  validWebstoreActiveInative() {
    const w = this.webstoreInterno;
    if (w.activateSeller === false) {
      this.controlWebstore = webstoreActiveEnum[2]
    }
    else if (w.activateSeller === true) {
      this.controlWebstore = webstoreActiveEnum[3]
    } else {
      this.controlWebstore = webstoreActiveEnum[1]
    }
  }

  activeWebstore() {
    this.isActivateWebButton = true;
    this.statusActiveDeactive = true;
    setTimeout(() => { this.isActivateWebButton = false; }, 1500);
    this.webstoreService.activeAndDeactiveWebstore(this.statusActiveDeactive, this.id, this.webstoreId)
      .subscribe(
        () => {
          this.controlWebstore = webstoreActiveEnum[3]
          this.webstoreInterno.activateSeller = true;
        },
        (error: newReponseI) => {
          if (error.status === 400) {
            if(error.error.returnObject.body) {
              this.errorMessageService.openDialog({
                message: 'ERRO',
                messageCode: error.error.returnObject.body.messageCode
              });
            } else {
              this.errorMessageService.openDialog({
                message: 'ERRO',
                messageCode: error.error.returnObject.messageCode
              });
            }
          }
          else if (error.status === 403) {
            if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile
              })
              .afterClosed().subscribe(() => {
                this.auth.logoutAndRemoveStorage();
              });
            }
            else {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile
              });
            }
          }
          else {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: 'Ocorreu uma falha, contate o administrador.'
            });
          }
        }
      )
  }

  deactiveWebstore() {
    this.statusActiveDeactive = false;
    this.webstoreService.activeAndDeactiveWebstore(this.statusActiveDeactive, this.id, this.webstoreId)
      .subscribe(
        () => {
          this.controlWebstore = webstoreActiveEnum[2]
          this.webstoreInterno.activateSeller = false;
        },
        (error: newReponseI) => {
          if (error.status === 400) {
            if(error.error.returnObject.body) {
              this.errorMessageService.openDialog({
                message: 'ERRO',
                messageCode: error.error.returnObject.body.messageCode
              });
            } else {
              this.errorMessageService.openDialog({
                message: 'ERRO',
                messageCode: error.error.returnObject.messageCode
              });
            }
          }
          else if (error.status === 403) {
            if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile
              })
              .afterClosed().subscribe(() => {
                this.auth.logoutAndRemoveStorage();
              });
            }
            else {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile
              });
            }
          }
          else {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: 'Ocorreu uma falha, contate o administrador.'
            });
          }
        }
      )
  }

  deactiveWebstoreModal() {
    const dialog = this.confirmarAcao.openDialog({
      title: 'Desativar webstore',
      message: 'Deseja realmente desativar esta webstore?'
    });

    dialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.deactiveWebstore();
      }
    });
  }

  replaceComissao(comissao: string) {
    this.webstoreInterno.commissioning = comissao.replace(',', '.');
  }

  setDataWebstore() {
    const w = this.webstoreInterno;
    if (!w) return;
    this.f.id.setValue(w.id);
    this.webstoreId = (w.id);
    this.f.activateSeller.setValue(w.activateSeller);
    this.f.flagUtilizarIntegrador.setValue(w.flagUtilizarIntegrador);
    this.statusActiveDeactive = (w.activateSeller);
    this.f.activateAutoSaleProduct.setValue(w.activateAutoSaleProduct);
    this.statusActiveDeactiveProducts = (w.activateAutoSaleProduct);
    this.f.commissioning.setValue(w.commissioning);
    this.f.deliveryPolicy.setValue(w.deliveryPolicy);
    this.f.description.setValue(w.description);
    this.f.installment.setValue(w.installment);
    this.f.logoUrl.setValue(w.logoUrl);
    this.imageUrl = (w.logoUrl);
    this.logoUrlExibe = (w.logoUrl);
    this.f.name.setValue(w.name);
    this.f.returnPolicy.setValue(w.returnPolicy);
    if (this.allWebstoresInterno !== undefined) {
      this.id = this.allWebstoresInterno.id;
    }
    this.commissioningFastControl = (w.commissioning);
    this.installmentFastControl = (w.installment);
    if (w.transferModel !== undefined && w.transferModel !== null) {
      this.f.transferModel.setValue(w.transferModel.toLowerCase());
      this.transferModelFastControl = (w.transferModel.substring(0, 1).toUpperCase().concat(w.transferModel.substring(1)));
    }
    // this.deadlineDispatchFastControl = (w.deadlineDispatch);
    if (w.token !== undefined && w.token !== null && w.token !== '' && w.cep !== undefined && w.cep !== null && w.cep !== '') {
      this.f.flagUtilizarIntegrador.setValue(true);
      this.isEnabledInputs(true);
      this.f.token.setValue(w.token);
      this.f.cep.setValue(w.cep);
    } else {
      this.f.flagUtilizarIntegrador.setValue(false);
      this.isEnabledInputs(false);
    }

  }


  onSelectFile(e) {
    this.isAuthenticating = true;
    if (e.target.files) {
      this.file = e.target.files[0];
      this.logoName = this.file.name;
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
        this.preview = true;
        this.imageUrl = event.target.result;
        this.isAuthenticating = false;
      }
    }
  }

  deletePreviewLogo() {
    this.preview = false;
  }

  //Process files
  receivedFiles() {
    this.isAuthenticating = true;
    try {
      this.logoService.saveLogo(this.id, this.webstoreId, this.file)
        .subscribe(
          () => {
            this.isAuthenticating = false;
          },
          (error: newReponseI) => {
            if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile
              });
              this.auth.logoutAndRemoveStorage();
            }
            if (error.status === 403) {
              if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
                this.isAuthenticating = false;
                this.errorMessageService.openDialog({
                  message: error.error.statusCustom.accessInfo.message,
                  messageCode: error.error.statusCustom.profile
                })
                .afterClosed().subscribe(() => {
                  this.auth.logoutAndRemoveStorage();
                });
              }
              else {
                this.errorMessageService.openDialog({
                  message: error.error.statusCustom.accessInfo.message,
                  messageCode: error.error.statusCustom.profile
                });
                this.isAuthenticating = false;
              }
            }
            else {
              this.errorMessageService.openDialog({
                message: 'ERRO',
                messageCode: 'Ocorreu uma falha, contate o administrador.'
              });
            }
          }
        )

    } catch (err) {
      console.log(error);
    }
  }

  deleteLogo() {
    this.logoService.deleteLogo(this.id, this.webstoreId)
      .subscribe(
        () => {
          this.isInvalidUpload = false;
        },
        (error: newReponseI) => {
          if (error.status === 403) {
            if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile
              })
              .afterClosed().subscribe(() => {
                this.auth.logoutAndRemoveStorage();
              });
            }
            else {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile
              });
            }
          }
          else {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: 'Ocorreu uma falha, contate o administrador.'
            });
          }
        }
      )
  }

  onSubmit() {
    const webstore = this.webstoreForm.getRawValue() as WebstoreI;
    console.log(webstore);
    this.createUpdate.emit();
    if (this.file !== undefined) {
      this.receivedFiles();
    }
  }

}
