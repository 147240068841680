import { ProfilesService } from './../../services/profiles.service';
import { ProfileModel } from "../../models/profiles.model";
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class ProfilesResolver implements Resolve<ProfileModel[]>{
    constructor(private profilesService:ProfilesService){}
    resolve(): Observable<ProfileModel[]> {
        return this.profilesService.getProfiles();
    }

}