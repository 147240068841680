import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HistoricoRecModalComponent } from './historico-rec-modal/historico-rec-modal.component';
import { NotasRecService } from './../../services/notas-rec.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
@Component({
  selector: 'app-notas-rec',
  templateUrl: './notas-rec.component.html',
  styleUrls: ['./notas-rec.component.scss']
})
export class NotasRecComponent implements OnInit {
  rating = '0';
  currentRate;
  lastWeek = 'undefined';
  lastMonth = 'undefined';
  lastYear = 'undefined';
  lastWeekNumeric = 'undefined';

  periodosFechamento = [
    {
      name: 'ACER',
      value: 'ACER'
    },
    {
      name: 'ACERSTORE',
      value: 'ACERSTORE'
    }
  ];

  fakeArray = [
    {
      sellerIdExt: 'ACER',
      accountId: "ACER",
      companyName: "ACER SA",
      evaluation: '7',
      id: "602bb9bc8c23240c8b474113",
      month: "Outubro",
      monthNumeric: 10,
      sellerId: "ACER",
      tenantId: "sellercenter-develop",
      week: "05",
      weekNumeric: '5',
      year: '2020'
    },
    {
      sellerIdExt: 'ACERSTORE',
      accountId: "ACERSTORE",
      companyName: "ACERSTORE SA",
      evaluation: '8',
      id: "602bb9bc8c23240c8b474114",
      month: "Outubro",
      monthNumeric: 10,
      sellerId: "ACERSTORE",
      tenantId: "sellercenter-develop",
      week: "05",
      weekNumeric: '5',
      year: '2020'
    }
  ]

  selected;
  notasRecArray;

  constructor(
    private dialog: MatDialog,
    private notasRecService: NotasRecService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService,

  ) { }

  ngOnInit(): void {
    this.buscaUltimaNota();
  }


  trocaSellerId(sellerIdExt: string) {
    console.log('troquei o seller id', sellerIdExt);
    this.notasRecArray.forEach(e => {
      if (e.sellerIdExt === sellerIdExt) {
        this.lastMonth = e.month;
        this.lastWeek = e.week;
        this.lastWeekNumeric = e.weekNumeric;
        this.lastYear = e.year;
        //replace
        this.rating = e.evaluation;
        this.rating = this.rating.toString().replace('.', ',');
        this.currentRate = e.evaluation;
      }
    })
  }


  buscaUltimaNota() {
    this.notasRecService.getLastNotes().subscribe(
      data => {
        console.log(data)
        if (data.length > 0) {
          this.notasRecArray = data;
          this.selected = data[0].sellerIdExt;
        }
        this.lastMonth = data[0].month;
        this.lastWeek = data[0].week;
        this.lastWeekNumeric = data[0].weekNumeric;
        this.lastYear = data[0].year;
        //replace
        this.rating = data[0].evaluation;
        this.rating = this.rating.toString().replace('.', ',');
        this.currentRate = data[0].evaluation;
      },
      error => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
      }
    )
  }

  openDialog(selected?: string): void {
    console.log('foi selecionado', selected)
    const dialogRef = this.dialog.open(HistoricoRecModalComponent, {
      width: '800px',
      data: {
        sellerIdExt: selected
      },
    });
  }

}
