import {Component, OnInit, Optional, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { ResponsaveisService } from '../../../services/responsaveis.service';
import { ResponsavelModel } from '../../../models/responsavel.model';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { AuthService } from '../../auth/auth.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
};

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-editar-responsavel',
  templateUrl: './editar-responsavel.component.html',
  styleUrls: ['./editar-responsavel.component.scss']
})

export class EditarResponsavelComponent implements OnInit {
  public title: string = 'Editar um responsável pelo contrato';
  public subTitle: string = 'Edite aqui uma lista de responsáveis por este contrato. Os dados podem ser editados e novos responsáveis podem ser inseridos a qualquer momento.';

  responsavel = {} as ResponsavelModel;
  hide = true;
  responsavelLegaisForm: FormGroup;
  phoneMask = '';


  constructor(
    @Optional() private dialogRef: MatDialogRef<EditarResponsavelComponent>,
    @Inject(MAT_DIALOG_DATA)public data:{idResponsavel, idbase},
    private formBuilder: FormBuilder,
    private responsavelService: ResponsaveisService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService
  ) { };

  responsavel_content = [];

  ngOnInit(): void {

    this.responsavelService.getResponsavelById(this.data.idResponsavel).subscribe((responsavel: any) => {
      this.responsavel = responsavel;
      this.f.name.setValue(this.responsavel.name);
      this.f.email.setValue(this.responsavel.email);
      if(this.responsavel.phone.length > 10) {
        this.phoneMask = '(00) 00000-0000';
      } else {
        this.phoneMask = '(00) 0000-0000';
      }
      this.f.phone.setValue(this.responsavel.phone);
    });

    this.responsavelLegaisForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['',  [Validators.required, Validators.email]],
      phone: ['', Validators.required]
    });

    
  };

  // Se o responsavel sera criado ou atualizado
  saveContact() {
    if (this.responsavelLegaisForm.invalid) {
      return;
    }
    if (this.responsavelLegaisForm.valid) {
      const responsavel_content = this.responsavelLegaisForm.getRawValue() as ResponsavelModel;
      if(this.data.idResponsavel){
        this.update(responsavel_content, this.data.idResponsavel, this.data.idbase);
      }else{
        this.save(responsavel_content);
      };
    };
  };

  setPhoneMask() {
    if (this.phone) {
      this.phoneMask = this.phone.length > 10
        ? '(00) 00000-0000'
        : '(00) 00000-0000||(00) 0000-0000';
    }
  }

  get phone(): string {
    return this.responsavelLegaisForm.controls['phone'].value;
  }

  save(responsavel : ResponsavelModel){
    this.responsavelService.saveResponsavelPatch(responsavel).subscribe((resp: ResponseI) => { 
      this.errorMessageService.openDialog({
        message: 'SUCESSO',
        messageCode: 'Responsavel criado com sucesso.'
      });
      this.responsavelLegaisForm.reset({},{ onlySelf: false, emitEvent: false});
      this.dialogRef.close(true)
      console.log(resp);
    },
    (error: newReponseI) => {
      if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
        this.errorMessageService.openDialog({
          message: error.error.statusCustom.accessInfo.message,
          messageCode: error.error.statusCustom.profile
        });
        this.auth.logoutAndRemoveStorage();
      }
      if (error.status === 403) {
        this.errorMessageService.openDialog({
          message: error.error.statusCustom.accessInfo.message,
          messageCode: error.error.statusCustom.profile
        });
        this.dialogRef.close(true)
      }
      else {
        this.errorMessageService.openDialog({
          message: 'ERRO',
          messageCode: 'Ocorreu uma falha, contate o administrador.'
        });
      }
    }
    );
  };

  update(responsavel_content : ResponsavelModel, idResponsavel, idBase){
    this.responsavelService.updateResponsaveisPath(responsavel_content, idResponsavel, idBase).subscribe((resp: ResponseI) => {
      this.errorMessageService.openDialog({
        message: 'SUCESSO',
        messageCode: 'Responsavel atualizado com sucesso.'
      });
      this.dialogRef.close(true);
    },
    (error: newReponseI) => {
      if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
        this.errorMessageService.openDialog({
          message: error.error.statusCustom.accessInfo.message,
          messageCode: error.error.statusCustom.profile
        });
        this.auth.logoutAndRemoveStorage();
      }
      else if (error.status === 403) {
        this.errorMessageService.openDialog({
          message: error.error.statusCustom.accessInfo.message,
          messageCode: error.error.statusCustom.profile
        });
        this.dialogRef.close(true);
      }
      else {
        this.errorMessageService.openDialog({
          message: 'ERRO',
          messageCode: 'Ocorreu uma falha, contate o administrador.'
        });
      }
    }
    );
  };

  get f() {
    return this.responsavelLegaisForm.controls;
  };

  disabled() {
    this.hide = false;
  };

  // Fecha formulario
  close() {
    this.dialogRef.close();
  };

  // Limpa formulario
  cleanForm(form: NgForm){
    form.resetForm();
    this.responsavel = {} as ResponsavelModel;
  };
};
