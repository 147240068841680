import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepartmentsService } from 'src/app/services/departments.service';
import { LogService } from 'src/app/services/log.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';

import * as fileSaver from 'file-saver';

interface newReponseI {
  status: number,
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {
  logsForm: FormGroup;
  filtros = [];
  isDateStartInvalid: boolean = false;
  isDateEndInvalid: boolean = false;

  filtroName; 


  constructor(
    private formBuilder: FormBuilder,
    private errorMessageService: ErrorMessageService,
    private departmentsService: DepartmentsService,
    private auth: AuthService,
    private logService: LogService
  ) {
    this.buscaFiltros();
  }

  ngOnInit(): void {
    this.logsForm = this.formBuilder.group({
      filtro: ['', Validators.required],
      periodoInicio: ['', Validators.required],
      periodoFim: ['', Validators.required],
    });
  }

  get f() {
    return this.logsForm.controls;
  }

  downloadLogByDate() {
    var dataInicial = this.f.periodoInicio.value;
    var dataFinal = this.f.periodoFim.value;
    var filter = this.f.filtro.value;

    this.filtros.forEach(e => {
      if(e.id === filter) {
        this.filtroName = e.name;
      }
    })
    this.logService.getLog(dataInicial, dataFinal, filter).subscribe(
      (response: any) => {
        let file = new Blob([response], {
          type: 'application/csv'
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file);
          return;
        }

        var blob = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = blob;
        fileSaver.saveAs(blob, 'parceirofast_log_' + this.filtroName + '_' + dataInicial + '_a_' + dataFinal + '.csv');

        this.logsForm.reset();
      },
      (error) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Erro no período de vigência (início/fim)'
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else if (error.status === 404){
          this.errorMessageService.openDialog({
            message: 'INFORMAÇÃO',
            messageCode: 'Filtro informado não possui dados.'
          });
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    );
  }

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  buscaFiltros() {
    this.logService.getFilterList().subscribe(
      success => {
        this.filtros = success;
      },
      (error) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Erro ao buscar filtros'
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    )
  }

    dataAtualFormatada(){
      var data = new Date(),
          dia  = data.getDate().toString(),
          diaF = (dia.length == 1) ? '0'+dia : dia,
          mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
          mesF = (mes.length == 1) ? '0'+mes : mes,
          anoF = data.getFullYear();
      return anoF+"-"+mesF+"-"+diaF;
  }

  validarData(event) {
    var dataAtual = this.dataAtualFormatada();
    var dataInicio = this.logsForm.get('periodoInicio').value;
    this.isDateStartInvalid = false;
    this.isDateEndInvalid = false;

    if(dataInicio > dataAtual) {
      this.isDateStartInvalid = true;
      this.logsForm.get('periodoInicio').setValue(null);
      return;
    }

    //VALIDAÇÕES DATA INICIO
    if (!this.logsForm.get('periodoInicio').value) {
      if (this.logsForm.get('periodoInicio').value > this.logsForm.get('periodoFim').value) {
        if (this.logsForm.get('periodoFim').value !== '') {
          this.isDateEndInvalid = true;
        }
        this.logsForm.get('periodoInicio').setValue(null);
        return;
      }
      this.isDateStartInvalid = true;
      this.logsForm.get('periodoInicio').setValue(null);
      return;
    }

    //VALIDAÇÕES DATA FIM
    if(this.logsForm.get('periodoFim').value > dataAtual) {
      this.isDateEndInvalid = true;
      this.logsForm.get('periodoFim').setValue(null);
      return;
    }

    if (this.logsForm.get('periodoInicio').value > this.logsForm.get('periodoFim').value) {
      if (this.logsForm.get('periodoFim').value !== '') {
        this.isDateEndInvalid = true;
      }
      this.logsForm.get('periodoFim').setValue(null);
      return;
    }
  }

  onSubmit() {
    if (this.logsForm.invalid) {
      console.log('form invalido')
      return;
    }
    if (this.logsForm.valid) {
      this.downloadLogByDate();
      // this.logsForm.reset();
    }
  }
}
