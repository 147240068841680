import { switchMap } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

//model
import { Integration } from '../../../models/integration.model'

//services
import { StorageService } from './../../../services/storage.service';
import { UrlIntegradorService } from '../../../services/urlIntegrador.service'
import { SettingsService } from '../../../services/settings.service'
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { CreateTokenFastService } from '../../../services/create-token-fast.service'
import { AuthService } from 'src/app/shared/auth/auth.service';
import { WebstoreService } from 'src/app/services/webstore.service';

interface erroResponse {
  error: {
    returnObject: {
      body: {
        code: string,
        message: string,
        messageCode: string
      },
      headers: {},
      statusCode: string,
      statusCodeValue: number,
    }
  }
}

interface newReponseI {
  status: number,
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-new-integracao',
  templateUrl: './new-integracao.component.html',
  styleUrls: ['./new-integracao.component.scss']
})

export class NewIntegracaoComponent implements OnInit, OnDestroy {
  getDataSourceEmpty() {
    return [
      { 'endpoint': 'OrderCreated', 'url': 'https://' },
      { 'endpoint': 'OrderUpdatedStatus', 'url': 'https://' },
      { 'endpoint': 'OrderCanceled', 'url': 'https://' },
      { 'endpoint': 'FreightSimulation', 'url': 'https://' },
      { 'endpoint': 'GetOrderVtex', 'url': 'https://' },
      { 'endpoint': 'GetProduct', 'url': 'https://' },
      { 'endpoint': 'GetSalesConditional', 'url': 'https://' }
    ];
  };

  integracaoForm: FormGroup;

  dataSource = this.getDataSourceEmpty();

  sourceInicial = this.dataSource;
  displayedColumns: string[] = ['endpoint', 'url'];
  integradoresBack = [];
  lastSellerId = '';
  lastIdLoja = '';
  lastPoliticaComercial = '';
  sellerId = this.serviceStorage.storage.getItem('sellerId');
  sellerIdExt = '';

  statusPublishSeller = 'Não encontrado';

  tabelaEditavel = false;

  subscriptions = new Subscription();

  @Input() integracaoInterno: Integration;
  @Input() allIntegrationsInterno;
  @Input() newIntegration: Integration;

  idIntegration: string = '';
  webstoresArray = [];

  itensUpdate = [];

  constructor(
    private formBuilder: FormBuilder,
    private urlIntegradorService: UrlIntegradorService,
    private settingsService: SettingsService,
    private errorMessageService: ErrorMessageService,
    private createTokenFastService: CreateTokenFastService,
    private auth: AuthService,
    private serviceStorage: StorageService,
    private webstoreService: WebstoreService,
  ) { }

  ngOnInit(): void {
    this.buscaWebstoresBySeller();
    this.getIntegradores();
    this.createIntegracaoForm();
    this.isRequireValidations(true);
    this.isEnabledInputs(false);
    this.isRequireAndEnableInput();
    this.verifyFormIsComplete();
    this.getIntegracao();
    setTimeout(() => {
      this.newNomesWebstores();}, 3000);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get integracaoFormControls() {
    return this.integracaoForm.controls;
  }

  get isUsedIntegrator() {
    return this.integracaoFormControls.flagUtilizarIntegrador.value;
  }

  createIntegracaoForm() {
    this.integracaoForm = this.formBuilder.group({
      id: [''],
      flagUtilizarIntegrador: [null, Validators.required],
      deadlineDispatch: ['1', Validators.required],
      integrador: [null],
      appKey: [null, Validators.required],
      appToken: [null, Validators.required],
      sellerIdExt: [null, Validators.required],
      idLoja: [null],
      politicaComercial: [null],
      vinculoPagseguro: [null, Validators.required],
      appKeyFastShop: [null],
      appTokenFastShop: [null],
      webStoreIDs: ['', Validators.required],
      //integração própria
      OrderCreated: [null],
      OrderUpdatedStatus: [null],
      OrderCanceled: [null],
      FreightSimulation: [null],
      GetOrderVtex: [null],
      GetProduct: [null],
      GetSalesConditional: [null]
    });
    
    this.nomesWebstores();
    this.integracaoFormControls.vinculoPagseguro.disable();
    this.integracaoFormControls.appKeyFastShop.disable();
    this.integracaoFormControls.appTokenFastShop.disable();
  }

  newNomesWebstores() {
    var webstoresIds = this.integracaoForm.get('webStoreIDs').value;
    if(webstoresIds){
      this.itensUpdate = this.webstoresArray.filter(webstore => {
        return webstoresIds.find(
          i => webstore.id === i
        )
      });
    }
  }

  nomesWebstores() {
    this.integracaoForm.get('webStoreIDs').valueChanges.subscribe(item => {
      console.log('itens nomesWebstores ids', item)
      this.itensUpdate = this.webstoresArray.filter(webstore => {
        return item.find(
          i => webstore.id === i
        )
      }
      );

    });
  }

  buscaWebstoresBySeller() {
    this.webstoreService.getWebstore().subscribe(
      success => {
        if (success.length > 0) {
          this.webstoresArray = success[0].webStores;
        }
      },
      error => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
      }
    )
  }

  isRequireAndEnableInput() {
    const subscription = this.integracaoFormControls.flagUtilizarIntegrador.valueChanges.subscribe(isUsed => {
      if (!isUsed) this.clearForm();
      this.isRequireValidations(isUsed);
      this.isEnabledInputs(isUsed);
      if(isUsed === false) {
        console.log('chamando integracao propria')
        this.tabelaEditavel = true;
        this.ativarDesativarIntegracaoPropria(true);
        this.dataSource = this.getDataSourceEmpty();
        this.integracaoFormControls.integrador['disable']();
        this.integracaoFormControls.integrador.setValue('Própria');

        this.integracaoFormControls.idLoja['disable']();
        this.integracaoFormControls.idLoja.setValue('');

        this.integracaoFormControls.politicaComercial['enable']();
        this.integracaoFormControls.appKey['enable']();
        this.integracaoFormControls.appToken['enable']();
        this.integracaoFormControls.sellerIdExt['enable']();

        // this.integracaoFormControls.appKey.setValidators(Validators.nullValidator);
        // this.integracaoFormControls.appToken.setValidators(Validators.nullValidator);
        // this.integracaoFormControls.politicaComercial.setValidators(Validators.nullValidator);
        // this.setUrls()
        
      }
    })
    this.subscriptions.add(subscription);
  }

  isEnabledInputs(option: boolean) {
    const disableOrEnable = option ? 'enable' : 'disable';
    this.integracaoFormControls.integrador[disableOrEnable]();
    this.integracaoFormControls.appKey[disableOrEnable]();
    this.integracaoFormControls.appToken[disableOrEnable]();
    this.integracaoFormControls.sellerIdExt[disableOrEnable]();
    this.integracaoFormControls.idLoja[disableOrEnable]();
    this.integracaoFormControls.politicaComercial[disableOrEnable]();
  }

  ativarDesativarIntegracaoPropria(option : boolean){
    const isRequired = option ? Validators.required : Validators.nullValidator;
    this.integracaoFormControls.OrderCreated.setValidators(isRequired);
    this.integracaoFormControls.OrderUpdatedStatus.setValidators(isRequired);
    this.integracaoFormControls.OrderCanceled.setValidators(isRequired);
    this.integracaoFormControls.FreightSimulation.setValidators(isRequired);
    this.integracaoFormControls.GetOrderVtex.setValidators(isRequired);
    this.integracaoFormControls.GetProduct.setValidators(isRequired);
    this.integracaoFormControls.GetSalesConditional.setValidators(isRequired);
    this.integracaoFormControls.appKey.setValidators(isRequired);
    this.integracaoFormControls.appToken.setValidators(isRequired);
    this.integracaoFormControls.politicaComercial.setValidators(isRequired);
    this.integracaoFormControls.OrderCreated.markAsTouched();
    this.integracaoFormControls.OrderUpdatedStatus.markAsTouched();
    this.integracaoFormControls.OrderCanceled.markAsTouched();
    this.integracaoFormControls.FreightSimulation.markAsTouched();
    this.integracaoFormControls.GetOrderVtex.markAsTouched();
    this.integracaoFormControls.GetProduct.markAsTouched();
    this.integracaoFormControls.GetSalesConditional.markAsTouched();
  }

  isRequireValidations(option: boolean) {
    const isRequired = option ? Validators.required : Validators.nullValidator;
    this.integracaoFormControls.integrador.setValidators(isRequired);
    this.integracaoFormControls.appKey.setValidators(isRequired);
    this.integracaoFormControls.appToken.setValidators(isRequired);
    this.integracaoFormControls.politicaComercial.setValidators(isRequired);
    if(isRequired == Validators.nullValidator){
      this.tabelaEditavel = false;
      this.ativarDesativarIntegracaoPropria(false);
    }
  }

  clearForm() {
    this.integracaoFormControls.integrador.reset();
    this.integracaoFormControls.appKey.reset();
    this.integracaoFormControls.appToken.reset();
    this.integracaoFormControls.politicaComercial.reset();
    this.integracaoFormControls.appKeyFastShop.reset();
    this.integracaoFormControls.appTokenFastShop.reset();
    this.integracaoFormControls.vinculoPagseguro.reset();
  }

  verifyFormIsComplete() {
    this.integracaoForm.statusChanges.subscribe(status => {

    });
  }

  getIntegradores() {
    this.urlIntegradorService.get().subscribe(
      ret => {
        this.integradoresBack = ret[0].integrations;
      },
      error => {
        console.log(error);
      }
    )
  }

  saveIntegration() {
    var setting: Integration;
    if (this.integracaoForm.invalid) {
      return;
    }
    if (this.integracaoForm.valid) {
      if (this.integracaoFormControls.id.value !== '') {
        this.update(setting);
      } else {
        this.save(setting);
      }
    }
  }

  save(setting: Integration) {
    setting = this.buildSetting();
    this.settingsService.save(setting).subscribe(
      success => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Integração criada com sucesso. '
        });
        this.getIntegracao();
      },
      (error: newReponseI) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.messageCode
          });
          this.getIntegracao();
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
            this.getIntegracao();
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.getIntegracao();
        }
      },
    );
  };

  update(setting: Integration) {
    setting = this.buildSetting();
    this.settingsService.update(setting, this.idIntegration).subscribe(
      () => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Integração atualizada com sucesso.'
        });
        this.getIntegracaoPorId();
      },
      (error: newReponseI) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.messageCode
          });
          this.getIntegracaoPorId();
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
            this.getIntegracaoPorId();
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.getIntegracaoPorId();
        }
      },
    );
  };

  setUrls() {
    if( this.integracaoFormControls != undefined && this.integracaoFormControls.integrador.value == 'Própria'){
      this.tabelaEditavel = true;
      this.ativarDesativarIntegracaoPropria(true);
      this.dataSource = this.getDataSourceEmpty();
    }else{
      this.tabelaEditavel = false;
      this.ativarDesativarIntegracaoPropria(false);
      this.dataSource = this.urlIntegradorService.getDataSource(this.integracaoFormControls.integrador.value, this.integradoresBack);
    }
    this.validaPoliticaComercial();
    this.validaIdLoja();

    if (this.integracaoFormControls.politicaComercial.value !== '') {
      this.setPoliticaComercialUrl();
    }

    if (this.integracaoFormControls.sellerIdExt.value !== '') {
      this.replaceSellerID();
    }
  }

  validaPoliticaComercial() {
    if (this.integracaoFormControls.integrador.value == 'Vtex') {
      this.integracaoFormControls.politicaComercial.setValue('');
      this.integracaoFormControls.politicaComercial.enable();
    } else {
      if (this.integracaoFormControls.integrador.value === null ||
        this.integracaoFormControls.integrador.value === '') {
        this.integracaoFormControls.politicaComercial.setValue('');
        this.integracaoFormControls.politicaComercial.enable();
      }
      else {
        this.integracaoFormControls.politicaComercial.setValue(1);
        this.integracaoFormControls.politicaComercial.disable();
      }
    }
  }

  validaIdLoja() {
    var integrador = this.integracaoFormControls.integrador.value ? this.integracaoFormControls.integrador.value.toUpperCase() : '';
  
    if (integrador == 'HUB2B' ||
      integrador == 'TRAY CORP' ||
      integrador == 'PLUGG.TO' ||
      integrador == 'CIASHOP' ||
      integrador == 'SHOPPING DE PREÇOS') {
      this.integracaoFormControls.idLoja.setValue('');
      this.integracaoFormControls.idLoja.enable();
      this.integracaoFormControls.idLoja.setValidators(Validators.required);
      this.integracaoFormControls.idLoja.updateValueAndValidity();
    } else {
      this.integracaoFormControls.idLoja.setValue('');
      this.integracaoFormControls.idLoja.disable();
      this.integracaoFormControls.idLoja.clearValidators();
      this.integracaoFormControls.idLoja.updateValueAndValidity();
    }
  }

  buildSetting() {
    const data = this.integracaoInterno;
    this.getIntegradores();
    const urls =  this.urlIntegradorService.getDataSourceBack(this.integracaoFormControls.integrador.value, this.integradoresBack,
      this.integracaoFormControls.sellerIdExt?.value, this.integracaoFormControls.idLoja?.value, this.integracaoFormControls.politicaComercial?.value,
     this.integracaoForm ) ;
    let settings = {
      integration: {
        deadlineDispatch: this.integracaoFormControls.deadlineDispatch.value,
        appKey: this.integracaoFormControls.appKey.value,
        appToken: this.integracaoFormControls.appToken.value,
        comercialPolitics: this.integracaoFormControls.politicaComercial.value,
        idSeller: this.integracaoFormControls.idLoja.value, // idSeller == idLoja
        isIntegration: this.isUsedIntegrator,
        urlPagSeguro: this.integracaoFormControls.vinculoPagseguro.value,
        urls: (urls == null) ? [] : [urls],
        webStoreIDs: this.integracaoFormControls.webStoreIDs.value,
      },
      nameIntegration: data.nameIntegration,
      sellerIdExt: this.integracaoFormControls.sellerIdExt.value
    };
    return settings as Integration;
  }

  getIntegracaoPorId() {
    return this.settingsService.getById(this.idIntegration).subscribe(
      (data: Integration) => {

        this.integracaoFormControls.flagUtilizarIntegrador.setValue(data.integration.isIntegration);
        this.integracaoFormControls.vinculoPagseguro.setValue(data.integration.urlPagSeguro);
        this.integracaoFormControls.id.setValue(data.id);

          //validar qual integrador p ativar/desativar campos
          //ativa/desativa campos
          this.integracaoFormControls.integrador.setValue(data.integration.urls[0].name);
          this.validaIdLoja();
          this.validaPoliticaComercial();
          this.integracaoFormControls.webStoreIDs.setValue(data.integration.webStoreIDs);
          this.integracaoFormControls.deadlineDispatch.setValue(data.integration.deadlineDispatch);
          this.integracaoFormControls.idLoja.setValue(data.integration.idSeller);
          this.integracaoFormControls.sellerIdExt.setValue(data.sellerIdExt);
          this.sellerIdExt = data.sellerIdExt;
          this.integracaoFormControls.appKey.setValue(data.integration.appKey);
          this.integracaoFormControls.appToken.setValue(data.integration.appToken);
          this.integracaoFormControls.politicaComercial.setValue(data.integration.comercialPolitics);
          // this.integracaoFormControls.webStoreIDs.setValue(data.integration.webStoreIDs);
          this.dataSource = this.urlIntegradorService.buildDataSource(data.integration.urls, data.integration.comercialPolitics, this.integracaoForm);
          this.lastSellerId = data.sellerIdExt;
          this.lastPoliticaComercial = data.integration.comercialPolitics;
        
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      },
    );
  };

  getIntegracao() {
    const data = this.integracaoInterno;
    if (!data) return;
    if (data.integration) {
      this.integracaoFormControls.flagUtilizarIntegrador.setValue(data.integration.isIntegration);
      this.integracaoFormControls.vinculoPagseguro.setValue(data.integration.urlPagSeguro);
      this.idIntegration = data.id;
      this.integracaoFormControls.id.setValue(data.id);
      //validar qual integrador p ativar/desativar campos
      this.integracaoFormControls.integrador.setValue(data.integration.urls[0].name);
      if( this.integracaoFormControls.integrador.value == 'Própria'){
        this.tabelaEditavel = true;
        this.ativarDesativarIntegracaoPropria(true);
      }else{
        this.tabelaEditavel = false;
        this.ativarDesativarIntegracaoPropria(false);
      }
      //ativa/desativa campos
      this.validaIdLoja();
      this.validaPoliticaComercial();
      this.integracaoFormControls.deadlineDispatch.setValue(data.integration.deadlineDispatch);
      this.integracaoFormControls.idLoja.setValue(data.integration.idSeller);
      this.integracaoFormControls.sellerIdExt.setValue(data.sellerIdExt);
      this.sellerIdExt = data.sellerIdExt;
      this.lastIdLoja = data.integration.idSeller;
      this.integracaoFormControls.appKey.setValue(data.integration.appKey);
      this.integracaoFormControls.appToken.setValue(data.integration.appToken);
      this.integracaoFormControls.politicaComercial.setValue(data.integration.comercialPolitics);
      this.integracaoFormControls.webStoreIDs.setValue(data.integration.webStoreIDs);
      this.dataSource = this.urlIntegradorService.buildDataSource(data.integration.urls, data.integration.comercialPolitics, this.integracaoForm );
      if(data.statusPublish) {
        this.statusPublishSeller = data.statusPublish.toUpperCase();
      }
      this.lastSellerId = data.sellerIdExt;
      this.lastPoliticaComercial = data.integration.comercialPolitics;
    }
    //Caso não tenha dados de integração, popular URL PagSeguro Fake
    else {
      this.integracaoFormControls.vinculoPagseguro.setValue('https://www1.fastshop.com.br/?notificationCode=NOTIFICATIONCODE123456789ABCD&publicKey=PUB123456789ABDCD')
    }
  }

  metodoCriaTokenJWT(token: string) {
    this.createTokenFastService.postTokenJWT(token,this.integracaoFormControls.sellerIdExt.value).subscribe(
      (sucess: any) => {
        this.integracaoFormControls.appKeyFastShop.setValue(sucess.keyHub);
        this.integracaoFormControls.appTokenFastShop.setValue(sucess.keyJWT);
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.messageCode
          });
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    )
  }

  gerarTokens() {
    this.createTokenFastService.getToken(this.integracaoFormControls.sellerIdExt.value)
      .subscribe(
        ret => {
          this.metodoCriaTokenJWT(ret.returnObject);
        },
        (error: newReponseI) => {
          if (error.status === 400) {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: error.error.returnObject.messageCode
            });
          }
          else if (error.status === 403) {
            if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile
              })
              .afterClosed().subscribe(() => {
                this.auth.logoutAndRemoveStorage();
              });
            }
            else {
              this.errorMessageService.openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile
              });
            }
          }
          else {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: 'Ocorreu uma falha, contate o administrador.'
            });
          }
        });
  }

  replaceSellerID() {
    if (this.integracaoFormControls.sellerIdExt.value !== '') {
      this.dataSource.forEach(e => {
        if (e.url !== null) {
          e.url = e.url.replace('{sellerid}', this.integracaoFormControls.sellerIdExt.value);
          if (this.lastSellerId !== '') {
            e.url = e.url.replace(this.lastSellerId, this.integracaoFormControls.sellerIdExt.value);
          }
        }
      });
    } else {
      if (this.lastSellerId !== '') {
        this.dataSource.forEach(e => {
          if (e.url !== null) {
            e.url = e.url.replace(this.lastSellerId, '{sellerid}');
          }
        });
      }
    }
    this.lastSellerId = this.integracaoFormControls.sellerIdExt.value;
  }

  replaceIdLoja() {
    if (this.integracaoFormControls.idLoja.value !== '') {
      this.dataSource.forEach(e => {
        if (e.url !== null) {
          e.url = e.url.replace('{idloja}', this.integracaoFormControls.idLoja.value);
          if (this.lastIdLoja !== '') {
            e.url = e.url.replace(this.lastIdLoja, this.integracaoFormControls.idLoja.value);
          }
        }
      });
    } else {
      if (this.lastIdLoja !== '') {
        this.dataSource.forEach(e => {
          if (e.url !== null) {
            e.url = e.url.replace(this.lastIdLoja, '{idloja}');
          }
        });
      }
    }
    this.lastIdLoja = this.integracaoFormControls.idLoja.value;
  }

  setPoliticaComercialUrl() {
    if (this.integracaoFormControls.politicaComercial.value !== '') {
      this.dataSource.forEach(e => {
        if (e.url !== null) {
          e.url = e.url.replace('{sc}', this.integracaoFormControls.politicaComercial.value);
          if (this.lastPoliticaComercial !== '') {
            e.url = e.url.replace(this.lastPoliticaComercial, this.integracaoFormControls.politicaComercial.value);
          }
        }
      });
    } else {
      if (this.lastPoliticaComercial !== '') {
        this.dataSource.forEach(e => {
          if (e.url !== null) {
            e.url = e.url.replace(this.lastPoliticaComercial, '{sc}');
          }
        });
      }
    }
    this.lastPoliticaComercial = this.integracaoFormControls.politicaComercial.value;
  }

}
