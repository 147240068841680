import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

//msg erro
import { MessageErrorComponent } from 'src/app/shared/components/message-error/message-error.component';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';


import { AddressService } from 'src/app/services/address.service';

import { AddressI } from './../../../models/address.model';
import { ESTADO } from './../../../models/estado.model';
import { AuthService } from 'src/app/shared/auth/auth.service';

interface newReponseI {
  status: number,
  error: {
    returnObject: {},
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

export interface DialogData {
  animal: 'panda' | 'unicorn' | 'lion';
}

@Component({
  selector: 'app-consulta-cep-modal',
  templateUrl: './consulta-cep-modal.component.html',
  styleUrls: ['./consulta-cep-modal.component.scss']
})


export class ConsultaCepModalComponent implements OnInit {

  displayedColumns: string[] = [
    'Endereco',
    'Bairro',
    'Cidade',
    'Estado',
    'CEP',
  ];

  consultaCepForm: FormGroup;
  public title: string = 'Consulte seu CEP';
  public subTitle: string =
    'Por favor, preencha todos os campos sem caracteres especiais.';


  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  cepsDataTable: any;
  estados: ESTADO[];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private addressService: AddressService,
    private auth: AuthService,
    private errorMessageService: ErrorMessageService,
    @Optional() private dialogRef: MatDialogRef<ConsultaCepModalComponent>,
  ) { }

  length = 100;
  pageIndex = 0;
  pageSize = 10;
  pagelength = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  pageEvent: PageEvent;

  atualizar(event: PageEvent) {
    console.log(event);
  }


  ngOnInit(): void {
    this.consultaCepForm = this.formBuilder.group({
      streetName: ['', Validators.required],
      district: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
    });
    this.buscaEstados();
  }

  consultaCep(consultaCepForm: AddressI) {
    if (this.consultaCepForm.invalid) {
      return;
    } else {
      consultaCepForm = this.consultaCepForm.value;
      this.addressService.getCepByAddress(consultaCepForm).subscribe(
        success => {
          this.cepsDataTable = success.findByAddressResponse.Data.Address;
          this.dataSource.data = this.cepsDataTable;
          this.length = this.cepsDataTable.length;
          console.log(this.cepsDataTable);
        },
        (error: newReponseI) => {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Endereço não encontrado, tente novamente'
          });
          this.dataSource.data = [];
        }
      )
    }
  }

  public buscaEstados() {
    this.addressService.getUFs().subscribe(
      (data) => {
        this.estados = data.sort(function (a, b) {
          return a.sigla.localeCompare(b.sigla);
        });
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
        }
      },
    );
  }

  close() {
    this.dialogRef.close();
  }

  selectCep(cep: string) {
    this.dialogRef.close(cep);
  }

}

