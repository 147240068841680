import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StorageService } from './storage.service';

import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class NotasRecService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }


  getLastNotes(): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/evaluation`,
      { headers: this.getNewHeaders() }
    )
    .map(response => {
      return response;
    })
    .catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getHistoricNotes(sellerIdExt?: string): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    const headerSettings: {[name: string]: string | any } = {};
    // headerSettings['token'] = this.storage.storage.getItem('key');
    headerSettings['seller-api-version'] = 'v1';
    headerSettings['sellerIdExt'] = sellerIdExt;
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/evaluationhistory`,
      { headers: headerSettings }
    )
    .map(response => {
      return response;
    })
    .catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }

}
