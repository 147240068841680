import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { NfeService } from '../../../services/nfe.service';
import { NfeModel } from '../../../models/nfe.model';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { log } from 'console';
import { AuthService } from '../../auth/auth.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface ResponseDefault{
  error: {
    code: number,
    message: string,
    messageCode: string
  }
}

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-adicionar-nfe',
  templateUrl: './adicionar-nfe.component.html',
  styleUrls: ['./adicionar-nfe.component.scss']
})
export class AdicionarNfeComponent implements OnInit {
  public title: string = 'Adicionar email para nota fiscal eletrônica';
  public subTitle: string =
    'Digite o nome e o email de um responsável que irá receber a nota fiscal eletrônica da sua empresa';

  nfe = {} as NfeModel;
  nfes: NfeModel;

  hide = true;

  nfeForm: FormGroup;
  nfeModel: NfeModel;

  constructor(
    @Optional() private dialogRef: MatDialogRef<AdicionarNfeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id },
    private formBuilder: FormBuilder,
    private nfeService: NfeService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService
  ) { }

  get f() {
    return this.nfeForm.controls;
  }

  nfe_null = []

  ngOnInit(): void {
    this.nfeForm = this.formBuilder.group({
      responsible: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  // Se o nfe_null sera criado ou atualizado
  saveContact() {
    if (this.nfeForm.invalid) {
      return;
    }
    if (this.nfeForm.valid) {
      const nfe_null = this.nfeForm.getRawValue() as NfeModel;
      if (nfe_null.id) {
        console.log('Update?');
        this.close();
      } else {
        this.save(nfe_null);
      }
    }
  }

  save(nfe_null: NfeModel) {
    this.nfeService.saveNfe(nfe_null).subscribe(
      (resp: ResponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Contato adicionado com sucesso.'
        });
        this.dialogRef.close(true);
      },
      (error: ResponseDefault) => {
        if (error.error.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.message,
            messageCode: error.error.message
          });
          this.auth.logoutAndRemoveStorage();
        }
        if (error.error.code === 403) {
          this.errorMessageService.openDialog({
            message: error.error.message,
            messageCode: error.error.message
          });
          this.close();
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.close();
        }
      },
    );
  }

  disabled() {
    this.hide = false;
  }

  // Fecha formulario
  close() {
    this.dialogRef.close();
  }

  // Limpa formulario
  cleanForm(form: NgForm) {
    form.resetForm();
    this.nfe = {} as NfeModel;
  }

}
