import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotasRecService } from 'src/app/services/notas-rec.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      body: {
        code: number;
        message: string;
        messageCode: string;
      },
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-historico-rec-modal',
  templateUrl: './historico-rec-modal.component.html',
  styleUrls: ['./historico-rec-modal.component.scss']
})
export class HistoricoRecModalComponent implements OnInit {

  displayedColumns: string[] = [
    'notaRec',
    'semana',
    'mes',
    'ano',
  ];

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  


  public title: string = 'Histórico de Avaliações';
  public subTitle: string = 'Veja o histórico de suas avaliações de nota REC (Resultado de Encantamento do Cliente).';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      sellerIdExt
    },
    private auth: AuthService,
    private errorMessageService: ErrorMessageService,
    private notasRecService: NotasRecService,
    @Optional() private dialogRef: MatDialogRef<HistoricoRecModalComponent>,
  ) {
    this.dataSource.data = [
        {
          notaRec: 'undefined',
          semana: 'undefined',
          mes: 'undefined',
          ano: 'undefined'
        }
    ];

   }

  length = 100;
  pageIndex = 0;
  pageSize = 5;
  pagelength = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  ngOnInit(): void {
    this.buscaHistoricoNotas();
    this.traducaoItensPaginacao();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  buscaHistoricoNotas() {
    console.log('sellerIdExt passado no historico',this.data.sellerIdExt);
    this.notasRecService.getHistoricNotes(this.data.sellerIdExt).subscribe(
      data => {
        //replace 
        data.forEach(e => {
          if (e.evaluation !== null) {
            e.evaluation = e.evaluation.toString().replace('.', ',');
          }
        });
        this.dataSource.data = data;
      },
      error => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
      }
    )
  }

  close() {
    this.dialogRef.close();
  }

  traducaoItensPaginacao() {
    this.paginator._intl.firstPageLabel = 'Primeira página';
    this.paginator._intl.lastPageLabel = 'Última página';
    this.paginator._intl.nextPageLabel = 'Proxima página';
    this.paginator._intl.previousPageLabel = 'Página anterior';
    this.paginator._intl.itemsPerPageLabel = 'Itens por página';
  }

}
