import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { PreCadastroComponent } from './pages/pre-cadastro/pre-cadastro.component';
import { EmpresaComponent } from './pages/empresa/empresa.component';
import { GestaoFreteComponent } from './pages/gestao-frete/gestao-frete.component';
import { ContatosComponent } from './pages/contatos/contatos.component';
import { WebstoreComponent } from './pages/webstore/webstore.component';
import { IntegracaoComponent } from './pages/integracao/integracao.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ContratosDocumentosComponent } from './pages/contrato-documentos/contratos-documentos.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { EquipeFastshopComponent } from './pages/equipe-fastshop/equipe-fastshop.component';
import { PainelComponent } from './pages/painel/painel.component';
import { BemVindoComponent } from './pages/bem-vindo/bem-vindo.component';
import { ParametrizacaoComponent } from './pages/parametrizacao/parametrizacao.component';
import { ComissionamentoComponent } from './pages/comissionamento/comissionamento.component';
import { ComissaoWebstoresPageComponent } from './pages/comissionamento/comissao-webstores-page/comissao-webstores-page.component';
import { LogComponent } from './pages/log-page/log.component';
import { NotasRecComponent } from './pages/notas-rec/notas-rec.component';
import { GestaoPedidosComponent } from './pages/gestao-pedidos/gestao-pedidos.component';
import { HealthcheckComponent } from './pages/healthcheck/healthcheck.component';
import { EquipeFastResolver } from './shared/resolvers/equipe-fast.resolver';
import { ProfilesResolver } from './shared/resolvers/profiles.resolver';


const routes: Routes = [
  {
    path: 'bem-vindo',
    component: BemVindoComponent,
    data: { title: 'Parceiro Fast | Bem Vindo' }
  },
  {
    path: 'home',
    component: LayoutComponent,
    data: { title: 'Parceiro Fast | Home' },
    children: [
      {
        path: 'pre-cadastro',
        component: PreCadastroComponent,
        data: { title: 'Parceiro Fast | Pré Cadastro' },
      },
      {
        path: 'parametrizacao',
        component: ParametrizacaoComponent,
        data: { title: 'Parceiro Fast | Parametrização' },
        canActivate: [AuthGuard],
      },
      {
        path: 'logs',
        component: LogComponent,
        data: { title: 'Parceiro Fast | logs' },
        canActivate: [AuthGuard],
      },

      {
        path: 'comissionamento',
        data: {
          title: ` comissionamento`,
        },
        loadChildren: () =>
          import(
            './pages/comissionamento/comissionamento.routing.module'
          ).then((m) => m.ComissionamentoRoutingModule),
      },
      {
        path: 'atividades',
        data: {
          title: `Atividades`,
        },
        // loadChildren: () => import('./pages/atividades/atividades.module').then((m) => m.AtividadesModule),
        loadChildren: () => import('./pages/atividades/atividades-routing.module').then((m) => m.AtividadesRoutingModule),
      },
      {
        path: 'equipe-fastshop',
        component: EquipeFastshopComponent,
        data: { title: 'Parceiro Fast | Equipe FastShop' },
        resolve: {
          equipeFast: EquipeFastResolver,
          profiles: ProfilesResolver
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'painel',
        component: PainelComponent,
        data: { title: 'Parceiro Fast | Painel' },
        canActivate: [AuthGuard],
      },
      {
        path: 'empresa',
        component: EmpresaComponent,
        data: { title: 'Parceiro Fast | Empresa' },
      },
      {
        path: 'contatos',
        component: ContatosComponent,
        data: { title: 'Parceiro Fast | Contatos' },
      },
      {
        path: 'webstore',
        component: WebstoreComponent,
        data: { title: 'Parceiro Fast | WebStore' },
      },
      {
        path: 'integracao',
        component: IntegracaoComponent,
        data: { title: 'Parceiro Fast | Integração' },
      },
      {
        path: 'usuarios',
        component: UsuariosComponent,
        data: { title: 'Parceiro Fast | Usuários' },
      },
      {
        path: 'contrato',
        component: ContratosDocumentosComponent,
        data: { title: 'Parceiro Fast | Contratos e Documentos' },
      },
      {
        path: 'gestao-frete',
        component: GestaoFreteComponent,
        data: { title: 'Parceiro Fast | Gestão de Frete' },
      },
      {
        path: 'gestao-pedidos',
        component: GestaoPedidosComponent,
        data: { title: 'Parceiro Fast | Gestão de Pedidos' },
      },
      {
        path: 'resumo',
        component: NotasRecComponent,
        data: { title: 'Parceiro Fast | Resumo' },
      },
      {
        path: 'healthcheck',
        component: HealthcheckComponent,
        data: { title: 'Parceiro Fast | Healthcheck' },
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingProdModule { }
