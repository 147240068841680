import { HttpClient, HttpHeaders, HttpBackend  } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginModel } from './../models/login.model';

export interface LoginDataI {
  oldpassword: string;
  userName: string;
  password: string;
}

@Injectable({
    providedIn: 'root'
  })

  export class LoginService {
    private httpClient: HttpClient;

    private headers = new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
    });

  enviarUsername = new EventEmitter<any>(); 

  constructor(
    handler: HttpBackend,
    private http: HttpClient, 
    private router: Router) {
      this.httpClient = new HttpClient(handler);
     }


  forgetPassword(userName: String): Observable<any> {
    const headerSettings: {[name: string]: string | any } = {};
    headerSettings['userName'] = userName;
    const newHeader = new HttpHeaders(headerSettings);
    return this.http.post(
      `${environment.urlApigee}/${environment.uri}/login/forgotPassword`,
      {},
      { headers: newHeader}
        )
      .map(response => {
        return response;
        console.log(this.headers);
      })
      .catch((error: any) => {
        return Observable.throw(error);
        console.log(this.headers);
      });
  }

  signIn(userName: String, Password: String ): Observable<any> {
    const headerSettings: {[name: string]: string | any } = {};
    headerSettings['userName'] = userName;
    headerSettings['Password'] = Password;
    const newHeader = new HttpHeaders(headerSettings);
    return this.http
      .post(`${environment.urlApigee}/${environment.uri}/login`, 
      {},
      {headers: newHeader})
      .pipe(
        map(user => {
          if (user) {
            localStorage.setItem('currentUser', JSON.stringify(user));
          }

          return user;
        })
      );
  }

  confirmNewPassword(login: LoginModel): Observable<any> {
    const headerSettings: {[name: string]: string | any } = {};
    headerSettings['userName'] = login.userName;
    headerSettings['Password'] = login.Password;
    headerSettings['ConformationCode'] = login.ConformationCode;
    const newHeader = new HttpHeaders(headerSettings);
    return this.http
      .post(`${environment.urlApigee}/${environment.uri}/login/confirmForgotPassword`, 
      {},
      {headers: newHeader})
      .pipe(
        map(user => {
          if (user) {
            localStorage.setItem('currentUser', JSON.stringify(user));
          }

          return user;
        })
      );
  }


  newPasswordRequired(loginForm: LoginModel): Observable<any> {
    const headerSettings: {[name: string]: string | any } = {};
    headerSettings['userName'] = loginForm.userName;
    headerSettings['Password'] = loginForm.Password;
    headerSettings['oldpassword'] = loginForm.oldpassword;
    const newHeader = new HttpHeaders(headerSettings);
    return this.http
      .post(`${environment.urlApigee}/${environment.uri}/login/newPswdRequired`,
      {},
      {headers: newHeader})
      .pipe(
        map(user => {
          if (user) {
            localStorage.setItem('currentUser', JSON.stringify(user));
          }

          return user;
        })
      );
    }



}