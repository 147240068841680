import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

//MODELS
import { UsuarioModel } from '../../models/usuario.model';

//SERVICES
import { UsuarioService } from '../../services/usuario.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { AuthService } from 'src/app/shared/auth/auth.service';

//MODALS
import { AdicionarUsuarioComponent } from '../../shared/modal/adicionar-usuario/adicionar-usuario.component';
import { EditarUsuarioComponent } from '../../shared/modal/editar-usuario/editar-usuario.component';
import { ReenviarSenhaUsuarioComponent } from '../../shared/modal/reenviar-senha/reenviar-senha.component';
import { DeleteUsuarioComponent } from '../../shared/modal/deletar-usuario/delete-usuario.component'

export class NgbdModalContent {
  @Input() name;

  constructor(
    public activeModal: NgbActiveModal,
    ) { }
}

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})

export class UsuariosComponent implements OnInit {

  usuario = {} as UsuarioModel;
  usuarios: UsuarioModel[];
  profiles: UsuarioModel[];

  isInvalid = false;
  isValid = false;
  controlTableUser = false;
  objetcUser: any = {};
  objectAll: any = {};

  constructor(
    private dialog: MatDialog,
    private usuariosService: UsuarioService,
    private modalService: NgbModal,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService

  ) { }

  ngOnInit(): void {
    this.getUsuario();
  }

  open() {
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.name = 'World';
  }

  // Chama o serviço de todos os Usuarios
  getUsuario() {
    this.usuariosService.getUsers().subscribe((usuarios: any) => {
      this.usuarios = usuarios;
      this.objetcUser = usuarios.returnObject;
      this.objetcUser = this.objetcUser.users;
      this.objectAll = usuarios.returnObject;

      if (this.objetcUser.length > 0) {
        this.controlTableUser = true;
      } else {
        this.controlTableUser = false;
      }
    },
    (error: any) => {
      if (error.status === 403) {
        if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          })
          .afterClosed().subscribe(() => {
            this.auth.logoutAndRemoveStorage();
          });
        }
        else {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
        }
      }
    }
    );
  };

  public openModalDialogEditUsuario(userId, objectId) {
    const dialogRef = this.dialog.open(EditarUsuarioComponent, {
      height: '400px',
      width: '600px',
      id: 'modalEditUser',
      data: {
        userId,
        objectId
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {this.getUsuario();}, 2000);
    })
  }

  public openModalDialogDeleteUsuario(idObjectUser, idUser) {
    const dialogRef = this.dialog.open(DeleteUsuarioComponent, {
      width: '30%',
      data: {
        idObjectUser,
        idUser
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {this.getUsuario();}, 1500);
    })
  }

  // Adicionar novo usuario
  public openModalDialogAddUsuario(id) {
    const dialogRef = this.dialog.open(AdicionarUsuarioComponent, {
      height: '400px',
      width: '600px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {this.getUsuario();}, 2000);
    });
  }

  //Reenviar senha de usuario(s)
  public openModalDialogReenviarSenha(id) {
    const dialogRef = this.dialog.open(ReenviarSenhaUsuarioComponent, {
      width: '40%',
      data: {
        id
      },
    });

    dialogRef.afterClosed().subscribe(resultReenviarSenha => {
      console.log(resultReenviarSenha)
      if (resultReenviarSenha) {
        this.isValid = true;
        setTimeout(() => { this.isValid = false }, 3000);
      } else {
        if (resultReenviarSenha == undefined) {
          this.isInvalid = false;
        } else {
          this.isInvalid = true;
          setTimeout(() => { this.isInvalid = false }, 3000);
        }
      }
    });
  };
};
