import { tap } from 'rxjs/operators';
import { element } from 'protractor';
import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AdicionarResponsavelComponent } from '../../shared/modal/adicionar-responsavel/adicionar-responsavel.component';
import { EditarResponsavelComponent } from '../../shared/modal/editar-responsavel/editar-responsavel.component';
import { utilsBr, fakerBr } from 'js-brasil';
import { validandoCNPJ } from '../../shared/validators/validate-cnpj';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { MessageErrorComponent } from 'src/app/shared/components/message-error/message-error.component';
import { AdicionarNfeComponent } from '../../shared/modal/adicionar-nfe/adicionar-nfe.component';
import { NfeService } from '../../services/nfe.service';
import { ResponsaveisService } from '../../services/responsaveis.service';
import { NfeModel } from '../../models/nfe.model';
import { ResponsavelModel } from '../../models/responsavel.model';
import { DeleteNfeComponent } from '../../shared/modal/deletar-nfe/delete-nfe.component';
import { DeleteResponsavelComponent } from '../../shared/modal/deletar-responsavel/delete-responsavel.component';

//Models
import { Account, BusinessInfo } from '../../models/account.model';
import { ESTADO } from '../../models/estado.model';
import { BANCO } from '../../models/banco.model';
import { ContatoModel } from '../../models/contact.model';
import { EditarNfeComponent } from '../../shared/modal/editar-nfe/editar-nfe.component';

//services
import { AddressService } from '../../services/address.service';
import { PreCadastroService } from '../../services/pre-cadastro.service';
import { stringify } from '@angular/compiler/src/util';

import { BancosService } from '../../services/bancos.service';
import { AccountService } from '../../services/account.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { from, Observable, Subscription } from 'rxjs';
import { AuthService } from '../../shared/auth/auth.service';
import { type } from 'os';
import { LocalStorage, StorageMap } from '@ngx-pwa/local-storage';
import { StorageService } from 'src/app/services/storage.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  };
}

interface newReponseI {
  status: number;
  error: {
    returnObject: {};
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      };
      profile: string;
    };
  };
}

interface successI {
  returnObject: {};
  statusCustom: {
    accessInfo: {
      message: string;
      status: string;
    };
    profile: string;
  };
}

export enum StatusPublicacaoEnum {
  error = 1,
  pendente = 2,
  concluido = 3,
}

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss'],
})
export class EmpresaComponent implements OnInit, OnDestroy {
  nfes: NfeModel[];
  responsavel: ResponsavelModel[];

  hide = true;
  empresaForm: FormGroup;
  titleEmpresa: string = 'Empresa';
  estados: ESTADO[];
  bancos: BANCO[];
  periodosFechamento = [
    {
      name: 'Fechamento quinzenal',
      value: 'BIWEEKLY',
    },
    {
      name: 'Fechamento mensal',
      value: 'MONTHLY',
    },
  ];
  cnpjNotFound: boolean;
  private subscriptions: Subscription[] = [];

  preCadastroData;
  companyData: Account;
  statusSeller: string = 'null';
  controlTableUser = false;

  estadoMask = 'sp';
  public MASKS = utilsBr.MASKS;
  estadosMask = [
    'ac',
    'al',
    'am',
    'ap',
    'ba',
    'ce',
    'df',
    'es',
    'go',
    'ma',
    'mg',
    'ms',
    'mt',
    'pa',
    'pb',
    'pe',
    'pi',
    'pr',
    'rj',
    'rn',
    'ro',
    'rr',
    'rs',
    'sc',
    'se',
    'sp',
    'to',
  ];

  dataSource: ContatoModel[] = [];
  dataContact: ContatoModel;
  displayedColumns: string[] = ['name', 'email', 'phone', 'actionButtons'];
  displayedColumnsNfe: string[] = ['responsible', 'email', 'actionButtons'];

  mainMarketplace: FormArray;
  mainMarketplaceValue: FormArray;
  isCompany: boolean = true;
  objectLegalContacts: any = {};

  controlsEnderecoFiscal;
  controlsEnderecoExpedicao;

  profile: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private addressService: AddressService,
    private preCadastroService: PreCadastroService,
    private bancosService: BancosService,
    private dialog: MatDialog,
    private accountService: AccountService,
    private errorMessageService: ErrorMessageService,
    private nfeService: NfeService,
    private responsaveisService: ResponsaveisService,
    private auth: AuthService,
    private storage: StorageService,
    @Optional() private dialogRef: MatDialogRef<EmpresaComponent>
  ) {
    window.addEventListener('storage', this.updateStorageSellerId.bind(this));
    this.profile = this.storage.storage.getItem(`profile`);
  }

  ngOnInit(): void {
    this.consultaDadosEmpresa();
    this.buscaEstados();
    this.buscaBancos();
    this.getNfe();
    this.getResponsaveis();
    this.empresaForm = this.formBuilder.group({
      allowSellToB2b: true,
      sellerTypeTransfer: ['', Validators.required],
      bankData: this.formBuilder.group({
        account: ['', [Validators.maxLength(10), Validators.required]],
        digitAccount: ['', Validators.required],
        ag: ['', Validators.required],
        digitAgency: ['', Validators.required],
        bank: ['', Validators.required],
        cnpj: ['', Validators.required],
      }),
      businessInfo: this.formBuilder.group({
        IE: ['', [Validators.required]],
        IM: [''],
        businessAddress: this.formBuilder.array([
          this.formBuilder.group({
            cellphone: [''],
            city: ['', Validators.required],
            complement: [''],
            country: ['Brasil'],
            district: ['', Validators.required],
            number: ['', Validators.required],
            state: ['', Validators.required],
            streetName: ['', Validators.required],
            telephone: [''],
            type: ['F'],
            zipCode: ['', Validators.required],
          }),
          this.formBuilder.group({
            cellphone: [''],
            city: ['', Validators.required],
            complement: [''],
            country: ['Brasil'],
            district: ['', Validators.required],
            number: ['', Validators.required],
            state: ['', Validators.required],
            streetName: ['', Validators.required],
            telephone: [''],
            type: ['E'],
            zipCode: ['', Validators.required],
          }),
        ]),
        cnpj: [
          '',
          [
            Validators.required,
            Validators.minLength(14),
            Validators.maxLength(18),
          ],
        ],
        codGan: '',
        fancyName: ['', Validators.required],
        companyName: ['', Validators.required],
        invoiceForlegalPerson: '',
        webSite: [''],
      }),
      contacts: this.formBuilder.array([
        this.formBuilder.group({
          email: [''],
          name: [''],
          observation: [''],
          phone: [''],
          responsibility: [['']],
        }),
      ]),
      legalContact: this.formBuilder.group({
        document: {
          number: '',
          type: '',
        },
        email: [''],
        name: [''],
        phone: [''],
      }),
      mainMarketplace: this.formBuilder.array([
        this.formBuilder.group({
          name: '',
        }),
      ]),
    });

    this.disabledAddressInputs(0);
    this.disabledAddressInputs(1);
  }

  updateStorageSellerId(e: StorageEvent) {
    if (e.key === 'sellerId') {
      window.localStorage.setItem('sellerId', e.newValue);
      this.consultaDadosEmpresa();
    }
  }

  public disableInputs() {
    this.f.businessInfo.get('cnpj').disable();
    this.f.businessInfo.get('codGan').disable();
    this.f.businessInfo.get('companyName').disable();
    this.f.bankData.get('cnpj').disable();
  }

  public enableInputs() {
    this.f.businessInfo.get('cnpj').enable();
    this.f.businessInfo.get('codGan').enable();
    this.f.businessInfo.get('companyName').enable();
    this.f.bankData.get('cnpj').enable();
  }

  public consultaDadosEmpresa() {
    this.accountService.get().subscribe(
      (data: Account) => {
        this.companyData = data;
        this.titleEmpresa = data.businessInfo.companyName;

        if (data.businessInfo !== null) {
          this.f.businessInfo.get('cnpj').setValue(data.businessInfo.cnpj);
          this.f.businessInfo.get('codGan').setValue(data.businessInfo.codGan);
          this.f.businessInfo
            .get('fancyName')
            .setValue(data.businessInfo.fancyName);
          this.f.businessInfo
            .get('companyName')
            .setValue(data.businessInfo.companyName);
          this.f.businessInfo
            .get('invoiceForlegalPerson')
            .setValue(data.businessInfo.invoiceForlegalPerson);
          this.f.businessInfo
            .get('IE')
            .setValue(this.IEMask(data.businessInfo.IE));
          this.f.businessInfo.get('IM').setValue(data.businessInfo.IM);
          this.f.bankData.get('cnpj').setValue(data.businessInfo.cnpj);
          if (
            this.profile === 'fastshop.admin.cadastroseller' ||
            this.profile === 'fastshop.admin.plus.gestaofinanceira' ||
            this.profile === 'fastshop.admin.gestaofinanceira'
          ) {
            this.f.businessInfo.get('cnpj').enable();
            this.f.businessInfo.get('codGan').enable();
            this.f.businessInfo.get('companyName').enable();
          } else {
            this.disableInputs();
          }
        }
        if (data.businessInfo.businessAddress !== null) {
          var a = this.f.businessInfo.get('businessAddress') as FormArray;
          for (let i = 0; i < a.length; i++) {
            const end = data.businessInfo.businessAddress[i];
            a.at(i).setValue({
              cellphone: '',
              city: end.city,
              complement: end.complement,
              country: 'Brasil',
              district: end.district,
              number: end.number,
              state: end.state,
              streetName: end.streetName,
              telephone: '',
              type: i > 0 ? 'E' : 'F',
              zipCode: end.zipCode,
            });
            this.disabledAddressInputs(0);
            this.disabledAddressInputs(1);
          }
        }
        this.f.businessInfo.get('webSite').setValue(data.businessInfo.webSite);
        if (data.bankData !== null) {
          this.f.bankData.get('bank').setValue(data.bankData.bank);
          this.f.bankData.get('account').setValue(data.bankData.account);
          this.f.bankData
            .get('digitAccount')
            .setValue(data.bankData.digitAccount);
          this.f.bankData.get('ag').setValue(data.bankData.ag);
          this.f.bankData
            .get('digitAgency')
            .setValue(data.bankData.digitAgency);
        }
        if (data.mainMarketplace !== null) {
          this.empresaForm.setControl(
            'mainMarketplace',
            this.formBuilder.array(
              data.mainMarketplace.map((m) =>
                this.formBuilder.group({ name: m })
              )
            )
          );
        }
        if (data.sellerTypeTransfer !== null) {
          this.empresaForm
            .get('sellerTypeTransfer')
            .setValue(data.sellerTypeTransfer);
        }
        if (data.statusPublishEnum !== null) {
          var status = data.statusPublishEnum;
          if (status === 'COMPLETE') {
            this.statusSeller = StatusPublicacaoEnum[3];
          } else if (status === 'ERROR') {
            this.statusSeller = StatusPublicacaoEnum[1];
          } else {
            this.statusSeller = StatusPublicacaoEnum[2];
          }
        }
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (
            error.error.statusCustom.accessInfo.message === 'Token Expirado'
          ) {
            this.errorMessageService
              .openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile,
              })
              .afterClosed()
              .subscribe(() => {
                this.auth.logoutAndRemoveStorage();
              });
          } else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile,
            });
          }
        } else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.',
          });
        }
      }
    );
  }

  addCompany() {
    this.enableAddressInputs(0);
    this.enableAddressInputs(1);
    this.enableInputs();
    this.empresaForm.value.mainMarketplace = this.f.mainMarketplace.value.map(
      (m) => m.name
    );
    const empresaData = this.empresaForm.value;
    empresaData.businessInfo.IE = empresaData.businessInfo.IE.replace(
      /\./g,
      ''
    );
    this.accountService.update(empresaData).subscribe(
      (success: successI) => {
        if (success.statusCustom.accessInfo.status === 'FORBIDDEN') {
          this.errorMessageService.openDialog({
            message: 'SUCESSO',
            messageCode:
              'Foram atualizados os dados exceto para as áreas abaixo não permitidas para o seu perfil: ' +
              success.statusCustom.profile,
          });
        } else {
          this.errorMessageService.openDialog({
            message: 'SUCESSO',
            messageCode: 'Empresa atualizada com sucesso.',
          });
        }
        this.consultaDadosEmpresa();
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (
            error.error.statusCustom.accessInfo.message === 'Token Expirado'
          ) {
            this.errorMessageService
              .openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile,
              })
              .afterClosed()
              .subscribe(() => {
                this.auth.logoutAndRemoveStorage();
              });
          } else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile,
            });
          }
        } else {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile,
          });
          this.consultaDadosEmpresa();
        }
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: [''],
    });
  }

  addItem(): void {
    this.mainMarketplace = this.empresaForm.get('mainMarketplace') as FormArray;
    this.mainMarketplace.push(this.createItem());
  }

  limpaMarketplace(i: number): void {
    if (i !== 0) {
      this.mainMarketplace = this.empresaForm.get(
        'mainMarketplace'
      ) as FormArray;
      this.mainMarketplace.removeAt(i);
    }
  }

  getPhoneMask(phone: string) {
    return phone.length > 10 ? '(00) 00000-0000' : '(00) 0000-0000';
  }

  get f() {
    return this.empresaForm.controls;
  }

  disabledAddressInputs(i) {
    var a = this.f.businessInfo.get('businessAddress') as FormArray;
    var endExpedicao = a.controls[i];
    endExpedicao.get('city').disable();
    endExpedicao.get('country').disable();
    endExpedicao.get('district').disable();
    endExpedicao.get('state').disable();
    endExpedicao.get('streetName').disable();
    if (i === 0) {
      this.controlsEnderecoFiscal = a.controls[i];
    }
    if (i === 1) {
      this.controlsEnderecoExpedicao = a.controls[i];
    }
  }

  enableAddressInputs(i) {
    var a = this.f.businessInfo.get('businessAddress') as FormArray;
    var endExpedicao = a.controls[i];
    endExpedicao.get('city').enable();
    endExpedicao.get('country').enable();
    endExpedicao.get('district').enable();
    endExpedicao.get('state').enable();
    endExpedicao.get('streetName').enable();
  }

  public consultaEnderecoPorCep(cep, i) {
    cep = cep.replace('.', '').replace('-', '');
    this.addressService.getCepAddress(cep).subscribe(
      (data) => {
        var address = data.findByZIPCodeResponse.Data.Address;
        var a = this.f.businessInfo.get('businessAddress') as FormArray;
        a.at(i).setValue({
          cellphone: '',
          city: address.City,
          complement: '',
          country: address.Country,
          district: address.District,
          number: '',
          state: address.State,
          streetName: address.StreetName,
          telephone: '',
          type: i > 0 ? 'E' : 'F',
          zipCode: address.ZIPCode,
        });
        if (address.StreetName !== null) {
          this.disabledAddressInputs(i);
        } else {
          this.enableAddressInputs(i);
        }
      },
      (error) => {
        console.error('ERRO -->', error);

        this.errorMessageService.openDialog({
          message: 'ERRO',
          messageCode: 'CEP não encontrado!',
        });
        this.enableAddressInputs(i);
      }
    );
  }

  public copiaEnderecoExpedicao(i) {
    this.enableAddressInputs(0);
    var a = this.f.businessInfo.get('businessAddress') as FormArray;
    var endExpedicao = a.value[0];
    a.at(i).setValue({
      cellphone: '',
      city: endExpedicao.city,
      complement: endExpedicao.complement,
      country: 'Brasil',
      district: endExpedicao.district,
      number: endExpedicao.number,
      state: endExpedicao.state,
      streetName: endExpedicao.streetName,
      telephone: '',
      type: i > 0 ? 'E' : 'F',
      zipCode: endExpedicao.zipCode,
    });
    this.disabledAddressInputs(0);
  }

  public buscaEstados() {
    this.addressService.getUFs().subscribe(
      (data) => {
        this.estados = data.sort(function (a, b) {
          return a.sigla.localeCompare(b.sigla);
        });
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (
            error.error.statusCustom.accessInfo.message === 'Token Expirado'
          ) {
            this.errorMessageService
              .openDialog({
                message: error.error.statusCustom.accessInfo.message,
                messageCode: error.error.statusCustom.profile,
              })
              .afterClosed()
              .subscribe(() => {
                this.auth.logoutAndRemoveStorage();
              });
          } else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile,
            });
          }
        } else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.',
          });
        }
      }
    );
  }

  public buscaBancos() {
    this.bancos = this.bancosService.getBancos();
  }

  public setInscricaoEstadual() {
    this.estadoMask = this.f.uf.value.toLowerCase();
  }

  // Responsaveis

  getResponsaveis() {
    this.responsaveisService.getResponsaveis().subscribe((responsavel: any) => {
      this.responsavel = responsavel;
      this.objectLegalContacts = responsavel.legalContacts;

      if (this.objectLegalContacts.length > 0) {
        this.controlTableUser = true;
      } else {
        this.controlTableUser = false;
      }
    });
  }

  public openModalDialogAddResponsavel(ResponsavelIndex: number) {
    const dialogRef = this.dialog.open(AdicionarResponsavelComponent, {
      width: '40%',
      data: {
        responsavelList: this.dataSource,
        index: ResponsavelIndex,
      },
    });

    const subscription = dialogRef.afterClosed().subscribe((result: any[]) => {
      if (result) {
        this.dataSource = [...result];
        this.getResponsaveis();
      }
      setTimeout(() => {
        this.getResponsaveis();
      }, 1500);
    });
    this.subscriptions.push(subscription);
  }

  public openModalDialogEditResponsavel(idResponsavel, idbase) {
    const dialogRef = this.dialog.open(EditarResponsavelComponent, {
      width: '40%',
      data: {
        idResponsavel,
        idbase,
      },
    });

    const subscription = dialogRef.afterClosed().subscribe((result: any[]) => {
      setTimeout(() => {
        this.getResponsaveis();
      }, 1500);
    });
    this.subscriptions.push(subscription);
  }

  public openModalDialogDeleteResponsavel(idResponsavel) {
    const dialogRef = this.dialog.open(DeleteResponsavelComponent, {
      width: '40%',
      data: {
        idResponsavel,
      },
    });
    const subscription = dialogRef.afterClosed().subscribe((result: any[]) => {
      setTimeout(() => {
        this.getResponsaveis();
      }, 1000);
    });
    this.subscriptions.push(subscription);
  }

  // Nota fiscal
  // Chama o serviço de todos os NFES
  getNfe() {
    this.nfeService.getNfes().subscribe((nfes: any) => {
      this.nfes = nfes.emails;
    });
  }

  public openModalDialogAddNfe() {
    const dialogRef = this.dialog.open(AdicionarNfeComponent, {
      width: '30%',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getNfe();
      }
    });
  }

  public openModalDialogEditNfe(numberInclusion) {
    const dialogRef = this.dialog.open(EditarNfeComponent, {
      width: '30%',
      data: {
        numberInclusion,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getNfe();
      }
    });
  }

  public openModalDialogDeleteNfe(numberInclusion) {
    const dialogRef = this.dialog.open(DeleteNfeComponent, {
      width: '30%',
      data: {
        numberInclusion,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getNfe();
      }
    });
  }

  phoneMask(phone: string) {
    return phone.length <= 10 ? '(00) 0000-0000' : '(00) 00000-0000';
  }

  IEMask(value: string) {
    const newValue = value.replace(/\./g, '');
    const isNumber = newValue.match(/[0-9]/);
    if (isNumber) {
      if (newValue.length <= 6) {
        return newValue.replace(/(\d{3})(\d+)/, '$1.$2');
      } else if (newValue.length <= 9) {
        return newValue.replace(/(\d{3})(\d{3})(\d+)/, '$1.$2.$3');
      } else {
        return newValue.replace(/(\d{3})(\d{3})(\d{3})(\d+)/, '$1.$2.$3.$4');
      }
    } else {
      return newValue;
    }
  }
}
