import { Integration } from './../models/integration.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { DOCUMENT } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class UrlIntegradorService {

  constructor(private http: HttpClient, private storage: StorageService, @Inject(DOCUMENT) private document: Document) { }

  public get(): Observable<any> {
    return this.http
      .get<any>(`${environment.urlApigee}${environment.pathSc}/integrationurls`, {
        headers: this.getNewHeaders(),
      })
      .map((response) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getDataSource(
    integrador: string,
    integradores: any[]
  ): { endpoint: string; url: string }[] {
    var int = integradores.find((i) => i.name === integrador);

    if (int) {
      return [
        { endpoint: 'OrderCreated', url: int.orderCreated },
        { endpoint: 'OrderUpdatedStatus', url: int.orderUpdatedStatus },
        { endpoint: 'OrderCanceled', url: int.orderCanceled },
        { endpoint: 'FreightSimulation', url: int.freightSimulation },
        { endpoint: 'GetOrderVtex', url: int.getOrderVtex },
        {
          endpoint: 'GetProduct',
          url: int.getProduct == null ? '' : int.getProduct,
        },
        { endpoint: 'GetSalesConditional', url: int.getSalesConditional },
      ];
    } else {
      return [
        { endpoint: 'OrderCreated', url: 'https://' },
        { endpoint: 'OrderUpdatedStatus', url: 'https://' },
        { endpoint: 'OrderCanceled', url: 'https://' },
        { endpoint: 'FreightSimulation', url: 'https://' },
        { endpoint: 'GetOrderVtex', url: 'https://' },
        { endpoint: 'GetProduct', url: 'https://' },
        { endpoint: 'GetSalesConditional', url: 'https://' },
      ];
    }
  }

  getDataSourceBack(
    integrador: string,
    integradores: any[],
    sellerId: string = '',
    idLoja: string = '',
    politicaComercial: string = '',
    intFormControls: FormGroup
  ): any {
    if (integrador == 'Própria') {
      var int = integradores[0];
      var form = intFormControls.controls;
      int.name = integrador;
      int.orderCreated = form.OrderCreated.value;
      int.orderUpdatedStatus = form.OrderUpdatedStatus.value;
      int.orderCanceled = form.OrderCanceled.value;
      int.freightSimulation = form.FreightSimulation.value;
      int.getOrderVtex = form.GetOrderVtex.value;
      int.getProduct = form.GetProduct.value;
      int.getSalesConditional = form.GetSalesConditional.value;

    } else {
      var int = integradores.find((i) => i.name === integrador);

      int.orderCreated = (int.orderCreated === null) ? int.orderCreated : int.orderCreated
        .replace('{sellerid}', sellerId)
        .replace('{idloja}', idLoja)
      int.orderUpdatedStatus = (int.orderUpdatedStatus === null) ? int.orderUpdatedStatus : int.orderUpdatedStatus
        .replace('{sellerid}', sellerId)
        .replace('{idloja}', idLoja)
      int.orderCanceled = (int.orderCanceled === null) ? int.orderCanceled : int.orderCanceled
        .replace('{sellerid}', sellerId)
        .replace('{idloja}', idLoja)
      int.freightSimulation = (int.freightSimulation === null) ? int.freightSimulation : int.freightSimulation
        .replace('{sellerid}', sellerId)
        .replace('{idloja}', idLoja)
      int.getOrderVtex = (int.getOrderVtex === null) ? int.getOrderVtex : int.getOrderVtex
        .replace('{sellerid}', sellerId)
        .replace('{idloja}', idLoja)
      int.getProduct = (int.getProduct === null) ? int.getProduct : int.getProduct
        .replace('{sellerid}', sellerId)
        .replace('{idloja}', idLoja)
      int.getSalesConditional = (int.getSalesConditional === null) ? int.getSalesConditional : int.getSalesConditional
        .replace('{sellerid}', sellerId)
        .replace('{idloja}', idLoja)
    }

    if (int) {
      return int;
    } else {
      return null;
    }
  }

  buildDataSource(urls: any, politicaComercial: string, intFormControls: FormGroup): { endpoint: string; url: string }[] {
    var u = urls[0];
    if (u.name == 'Própria') {
      var int = intFormControls.controls;
      int.OrderCreated.setValue(u.orderCreated);
      int.OrderUpdatedStatus.setValue(u.orderUpdatedStatus);
      int.OrderCanceled.setValue(u.orderCanceled);
      int.FreightSimulation.setValue(u.freightSimulation);
      int.GetOrderVtex.setValue(u.getOrderVtex);
      int.GetProduct.setValue(u.getProduct);
      int.GetSalesConditional.setValue(u.getSalesConditional);
    }
    return [
      { endpoint: 'OrderCreated', url: u.orderCreated == null ? '' : u.orderCreated.replace('{politicacomercial}', politicaComercial) },
      { endpoint: 'OrderUpdatedStatus', url: u.orderUpdatedStatus == null ? '' : u.orderUpdatedStatus.replace('{politicacomercial}', politicaComercial) },
      { endpoint: 'OrderCanceled', url: u.orderCanceled == null ? '' : u.orderCanceled.replace('{politicacomercial}', politicaComercial) },
      { endpoint: 'FreightSimulation', url: u.freightSimulation == null ? '' : u.freightSimulation.replace('{politicacomercial}', politicaComercial) },
      { endpoint: 'GetOrderVtex', url: u.getOrderVtex == null ? '' : u.getOrderVtex.replace('{politicacomercial}', politicaComercial) },
      { endpoint: 'GetProduct', url: u.getProduct == null ? '' : u.getProduct.replace('{politicacomercial}', politicaComercial) },
      { endpoint: 'GetSalesConditional', url: u.getSalesConditional == null ? '' : u.getSalesConditional.replace('{politicacomercial}', politicaComercial) },
    ];
  }

  getIntegratorSettings() {
    const { integration } = JSON.parse(window.localStorage.getItem('currentUser'));
    return integration;
  }

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }
}
