import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ContatoService } from '../../../services/contato.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { AuthService } from '../../auth/auth.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-delete-contato',
  templateUrl: './delete-contact.component.html',
  styleUrls: ['./delete-contact.component.scss']
})
export class DeleteContatoComponent implements OnInit {

  constructor(
    @Optional() private dialogRef: MatDialogRef<DeleteContatoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id },
    private contatoService: ContatoService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
  }

  // Se o contato sera criado ou atualizado
  deleteContact(id) {
    if (this.data.id) {
      this.deleteContactsById(id);
    } else {
      this.close();
    }
  }

  // Chama o serviço de todos os contatos
  deleteContactsById(id) {
    this.contatoService.deleteContact(this.data.id).subscribe((ResponseI) => {
      this.errorMessageService.openDialog({
        message: 'SUCESSO',
        messageCode: 'Contato deletado com sucesso.'
      });
      this.dialogRef.close(true)
    },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
            this.close();
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.close();
        }
      },
    );
  };

  close() {
    this.dialogRef.close();
  }

}
