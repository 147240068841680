import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TooltipPosition } from '@angular/material/tooltip';
import { ESTADO } from 'src/app/models/estado.model';
import { ComissionamentoService } from 'src/app/services/comissionamento.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      body: {
        code: number;
        message: string;
        messageCode: string;
      },
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-historico-modal',
  templateUrl: './historico-modal.component.html',
  styleUrls: ['./historico-modal.component.scss']
})
export class HistoricoModalComponent implements OnInit {

  displayedColumns: string[] = [
    'commisionMartPlace',
    'commisionSalesMan',
    'termStart',
    'termEnd',
    'user',
    'dateOperation',
    'operation'
  ];

  isChannel: boolean = false;

  public title: string = 'Histórico de comissionamento';
  public subTitle: string =
    'Por favor, preencha todos os campos sem caracteres especiais.';


  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  cepsDataTable: any;
  estados: ESTADO[];
  existSubTitle: boolean = false;
  existSKU: boolean = false;
  subTitleName: string = '';
  subTitleDescription: string = '';
  skuName: string = 'LEO';



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      type,
      description, 
      skuName
    },
    private comissionamentoService: ComissionamentoService,
    private auth: AuthService,
    private errorMessageService: ErrorMessageService,
    @Optional() private dialogRef: MatDialogRef<HistoricoModalComponent>,
  ) {
    this.skuName = this.data.skuName;
   }

  length = 100;
  pageIndex = 0;
  pageSize = 5;
  pagelength = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  pageEvent: PageEvent;

  atualizar(event: PageEvent) {
    console.log(event);
  }

  ngOnInit(): void {
    console.log(this.data.type, this.data.description, this.data.skuName);
    this.verificaSubTitle(this.data.type, this.data.description);
  }

  verificaSubTitle(subtitle: string, description: string) {
    if(subtitle === 'SELLER' || subtitle === 'WEBSTORE') {
      this.existSubTitle = true;
      this.subTitleName = subtitle; 
      this.subTitleDescription = description;
      this.buscaHistoricoComissao();
    } 
    if(subtitle === 'CATEGORY') {
      this.existSubTitle = true;
      this.subTitleName = 'Categoria'; 
      this.subTitleDescription = description;
      this.buscaHistoricoComissaoPorNome();
    }
    if( subtitle === 'PRODUCT') {
      this.existSubTitle = true;
      this.existSKU = true;
      this.subTitleName = 'SKU Fast'; 
      this.subTitleDescription = description;
      this.buscaHistoricoComissaoPorNome();
    }
    if(subtitle === 'BRAND') {
      this.existSubTitle = true;
      this.subTitleName = 'Marca'; 
      this.subTitleDescription = description;
      this.buscaHistoricoComissaoPorNome();
    }
    if(subtitle === 'CHANNEL') {
      this.isChannel = true;
      this.displayedColumns  = [
        'channel',
        'commisionMartPlace',
        'commisionSalesMan',
        'termStart',
        'termEnd',
        'user',
        'dateOperation',
        'operation'
      ];
      this.existSubTitle = true;
      this.subTitleName = 'Canal de Venda'; 
      this.subTitleDescription = description;
      this.buscaHistoricoComissaoPorNome();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.length = this.dataSource.filteredData.length;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public buscaHistoricoComissaoPorNome() {
    this.comissionamentoService.getCommissionHistoricByName(this.data.type, this.data.description).subscribe(
      (data) => {
        console.log(data.returnObject);
        this.dataSource.data = data.returnObject;
        this.dataSource.data.forEach(e => {
          var dateStart = e.termStart.split(' ');
          var dateEnd = e.termEnd.split(' ');
          // var dateOperation = e.dateOperation.split(' ');
          e.termStart = dateStart[0];
          e.termEnd = dateEnd[0];
          // e.dateOperation = dateOperation[0];
        });
      },
      (error: newReponseI) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.body.messageCode
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      },
    );
  }

  public buscaHistoricoComissao() {
    this.comissionamentoService.getCommissionHistoric(this.data.type).subscribe(
      (data) => {
        this.dataSource.data = data.returnObject;
        this.dataSource.data.forEach(e => {
          var dateStart = e.termStart.split(' ');
          var dateEnd = e.termEnd.split(' ');
          // var dateOperation = e.dateOperation.split(' ');
          e.termStart = dateStart[0];
          e.termEnd = dateEnd[0];
          // e.dateOperation = dateOperation[0];
        });
      },
      (error: newReponseI) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.body.messageCode
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      },
    );
  }

  close() {
    this.dialogRef.close();
  }

}
