import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductBranchCategoryService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

   getListSellersExId() {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/${sellerId}/seller/status`,
      {headers: this.getNewHeadersSellercenter()})
      .map(response => { return response; })
      .catch((error: any) => { return Observable.throw(error); });
  }

  // TODO - Arrumar a chamada paginada para trazer as categorias totais
  getCategoryByName(categoryName?: string) {
    var categoryParams = '';
    if(categoryName) {
      categoryParams = '&categoryName=' + categoryName; 
    }
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathShopMpc}${environment.pathShopMpcCategories}/simplified?&pageIndex=0&pageSize=1000${categoryParams}`,
      {headers: this.getNewHeaders()})
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getSku(sellerExId: string, skuFast?: string) {
    var skuParams = '';
    if(skuFast) {
      skuParams = skuFast; 
    }
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathShopMpc}${environment.pathShopMpcItensSkus}/simplified?pageIndex=0&pageSize=1000&skuFast=${skuParams}&sellerId=${sellerExId}`,
      {headers: this.getNewHeaders()})
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getBrands(brand?: string): Observable<any> {
    var brandParams = '';
    if(brand) {
      brandParams = 'brand=' + brand; 
    }
    console.log(brand);
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathShopMpc}${environment.pathShopMpcItensBrands}?${brandParams}`,
      {headers: this.getNewHeaders()})
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }


  getNewHeadersSellercenter() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'mpc-api-version': 'v1',
    });
  }
}
