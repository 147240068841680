import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';

@Component({
  selector: 'app-outbound-table',
  templateUrl: './outbound-table.component.html',
  styleUrls: ['./outbound-table.component.scss']
})
export class OutboundTableComponent implements OnInit {

  displayedColumns: string[] = [
    'indicadores',
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez',
    'semana1',
    'semana2',
    'semana3',
    'semana4',
    'dmenos1'
  ];

  displayedColumns1: string[] = [
    'indicadores',
  ];

  displayedColumns2: string[] = [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez',
  ];

  displayedColumns3: string[] = [
    'semana1',
    'semana2',
    'semana3',
    'semana4',
    'dmenos1'
  ];
  

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  dataSource2: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator2: MatPaginator;

  public title: string = 'Histórico de Avaliações';
  public subTitle: string = 'Veja o histórico de suas avaliações de nota REC (Resultado de Encantamento do Cliente).';

  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: {
    //   data
    // },
    private auth: AuthService,
    private errorMessageService: ErrorMessageService,
    @Optional() private dialogRef: MatDialogRef<OutboundTableComponent>,
  ) {
    this.dataSource.data = [
      {
        indicadores: 'XPTO',
        jan: 'XPTO',
        fev: 'XPTO',
        mar: 'XPTO',
        abr: 'XPTO',
        mai: 'XPTO',
        jun: 'XPTO',
        jul: 'XPTO',
        ago: 'XPTO',
        set: 'XPTO',
        out: 'XPTO',
        nov: 'XPTO',
        dez: 'XPTO',
        semana1: 'XPTO',
        semana2: 'XPTO',
        semana3: 'XPTO',
        semana4: 'XPTO',
        dmenos1: 'XPTO'
      },
      {
        indicadores: 'XPTO',
        jan: 'XPTO',
        fev: 'XPTO',
        mar: 'XPTO',
        abr: 'XPTO',
        mai: 'XPTO',
        jun: 'XPTO',
        jul: 'XPTO',
        ago: 'XPTO',
        set: 'XPTO',
        out: 'XPTO',
        nov: 'XPTO',
        dez: 'XPTO',
        semana1: 'XPTO',
        semana2: 'XPTO',
        semana3: 'XPTO',
        semana4: 'XPTO',
        dmenos1: 'XPTO'
      },
      {
        indicadores: 'XPTO',
        jan: 'XPTO',
        fev: 'XPTO',
        mar: 'XPTO',
        abr: 'XPTO',
        mai: 'XPTO',
        jun: 'XPTO',
        jul: 'XPTO',
        ago: 'XPTO',
        set: 'XPTO',
        out: 'XPTO',
        nov: 'XPTO',
        dez: 'XPTO',
        semana1: 'XPTO',
        semana2: 'XPTO',
        semana3: 'XPTO',
        semana4: 'XPTO',
        dmenos1: 'XPTO'
      },
      {
        indicadores: 'XPTO',
        jan: 'XPTO',
        fev: 'XPTO',
        mar: 'XPTO',
        abr: 'XPTO',
        mai: 'XPTO',
        jun: 'XPTO',
        jul: 'XPTO',
        ago: 'XPTO',
        set: 'XPTO',
        out: 'XPTO',
        nov: 'XPTO',
        dez: 'XPTO',
        semana1: 'XPTO',
        semana2: 'XPTO',
        semana3: 'XPTO',
        semana4: 'XPTO',
        dmenos1: 'XPTO'
      },
  ];
  this.dataSource2.data = [
    {
      indicadores: 'XPTO',
      jan: 'XPTO',
      fev: 'XPTO',
      mar: 'XPTO',
      abr: 'XPTO',
      mai: 'XPTO',
      jun: 'XPTO',
      jul: 'XPTO',
      ago: 'XPTO',
      set: 'XPTO',
      out: 'XPTO',
      nov: 'XPTO',
      dez: 'XPTO',
      semana1: 'XPTO',
      semana2: 'XPTO',
      semana3: 'XPTO',
      semana4: 'XPTO',
      dmenos1: 'XPTO'
    },
    {
      indicadores: 'XPTO',
      jan: 'XPTO',
      fev: 'XPTO',
      mar: 'XPTO',
      abr: 'XPTO',
      mai: 'XPTO',
      jun: 'XPTO',
      jul: 'XPTO',
      ago: 'XPTO',
      set: 'XPTO',
      out: 'XPTO',
      nov: 'XPTO',
      dez: 'XPTO',
      semana1: 'XPTO',
      semana2: 'XPTO',
      semana3: 'XPTO',
      semana4: 'XPTO',
      dmenos1: 'XPTO'
    },
    {
      indicadores: 'XPTO',
      jan: 'XPTO',
      fev: 'XPTO',
      mar: 'XPTO',
      abr: 'XPTO',
      mai: 'XPTO',
      jun: 'XPTO',
      jul: 'XPTO',
      ago: 'XPTO',
      set: 'XPTO',
      out: 'XPTO',
      nov: 'XPTO',
      dez: 'XPTO',
      semana1: 'XPTO',
      semana2: 'XPTO',
      semana3: 'XPTO',
      semana4: 'XPTO',
      dmenos1: 'XPTO'
    },
    {
      indicadores: 'XPTO',
      jan: 'XPTO',
      fev: 'XPTO',
      mar: 'XPTO',
      abr: 'XPTO',
      mai: 'XPTO',
      jun: 'XPTO',
      jul: 'XPTO',
      ago: 'XPTO',
      set: 'XPTO',
      out: 'XPTO',
      nov: 'XPTO',
      dez: 'XPTO',
      semana1: 'XPTO',
      semana2: 'XPTO',
      semana3: 'XPTO',
      semana4: 'XPTO',
      dmenos1: 'XPTO'
    },
];
  }

  length = 100;
  pageIndex = 0;
  pageSize = 5;
  pagelength = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  ngOnInit(): void {
    // this.traducaoItensPaginacao();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource2.paginator = this.paginator2;

  }

  close() {
    this.dialogRef.close();
  }

  // traducaoItensPaginacao() {
  //   this.paginator._intl.firstPageLabel = 'Primeira página';
  //   this.paginator._intl.lastPageLabel = 'Última página';
  //   this.paginator._intl.nextPageLabel = 'Proxima página';
  //   this.paginator._intl.previousPageLabel = 'Página anterior';
  //   this.paginator._intl.itemsPerPageLabel = 'Itens por página';
  // }

}
