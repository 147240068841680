import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class LogoService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
    ) { }

  public saveLogo(id: String, webstoreId: String, file: File) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    const formdata = new FormData();
    formdata.append('file', file);
    return this.http.post(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/webstores/${id}/logos/${webstoreId}`,
      formdata,
      { headers: this.getNewHeadersUpload() }
    );
  }

  public getLogo(): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(`${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/webstores/logos/`,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  deleteLogo(id: String, webstoreId: String) {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.delete(`${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/webstores/${id}/logos/${webstoreId}`,
    { headers: this.getNewHeaders() })
    .map(Response => {
      return Response;
    })
    .catch((error: any) => {
      return Observable.throw(error);
    })
  }

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }

  getNewHeadersUpload() {
    return new HttpHeaders({
      'accept': '*/*',
      'seller-api-version': 'v1',
    });
  }

}
