import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ComissionamentoService } from 'src/app/services/comissionamento.service';
import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ConfirmarAcaoService } from 'src/app/shared/modal/confirmar-acao/confirmar-acao.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      body: {
        code: number;
        message: string;
        messageCode: string;
      },
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}
@Component({
  selector: 'app-comissao-webstore',
  templateUrl: './comissao-webstore.component.html',
  styleUrls: ['./comissao-webstore.component.scss']
})

export class ComissaoWebstoreComponent implements OnInit {
  displayedColumnsComissaoWebstores: string[] = [
    'webstores',
    'comissaoFast',
    'comissaoVendedor',
    'inicioVigencia',
    'fimVigencia',
    'buttons',
  ];

  length = 100;
  pageIndex = 0;
  pageSize = 10;
  pagelength = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  dataSourceComissaoWebstore: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginatorComissaoWebstore: MatPaginator;


  leadZeroDateTime: boolean = false;
  listSellerCommissionsData;

  dataTable = [
  {    
    standardCommission: '',
    webStore: '',
    commission: [
          {        
            id: '',
            commisionMartPlace: '',
            commisionSalesMan: '',
            termStart: '',
            termEnd: '',
            brand: '',
            category: '',
            channel: '',
            dateOfExclusion: '',
            sku: '',
            skuName: '',
            type: 'SELLER',
            user:'',
            webStore: ''
          }
        ]
      }
  ]


  constructor(
    private auth: AuthService,
    private errorMessageService: ErrorMessageService,
    private comissionamentoService: ComissionamentoService,
    private confirmarAcao: ConfirmarAcaoService,
    private router: Router,
    private storageService: StorageService
  ) { 
    this.dataSourceComissaoWebstore.data = this.dataTable;
  }

  ngOnInit(): void {
    this.buscarComissao();
    this.traducaoItensPaginacao();
  }

  ngAfterViewInit() {
    this.dataSourceComissaoWebstore.paginator = this.paginatorComissaoWebstore;
  }

  goToWebstore(webstore: string) {
    this.storageService.storage.setItem('webstoreActive', webstore);
  }


  buscarComissao() {
    this.comissionamentoService.getCommission().subscribe(
      success => {
        this.listSellerCommissionsData = success.returnObject.listWebStoreCommissions;
        console.log(this.listSellerCommissionsData);
        this.dataSourceComissaoWebstore.data = this.listSellerCommissionsData;
      },
      (error: newReponseI) => {
        if (error.status === 400) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.error.returnObject.body.messageCode
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    )
  }

  changeValue(event: Event) {
    console.log('Mundança de valor', event);
  }

  traducaoItensPaginacao() {
    this.paginatorComissaoWebstore._intl.firstPageLabel = 'Primeira página';
    this.paginatorComissaoWebstore._intl.lastPageLabel = 'Última página';
    this.paginatorComissaoWebstore._intl.nextPageLabel = 'Proxima página';
    this.paginatorComissaoWebstore._intl.previousPageLabel = 'Página anterior';
    this.paginatorComissaoWebstore._intl.itemsPerPageLabel = 'Itens por página';
  }

}
