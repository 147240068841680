import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PreCadastroI } from './../models/pre-cadastro.model';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class PreCadastroService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
    ) { }


  save(preCadastro: PreCadastroI) {
    return this.http.post(
      `${environment.urlApigee}${environment.pathSc}/pre-accounts`,
      preCadastro,
      { headers: this.getNewHeaders() }
    );
  }

  update(preCadastro: PreCadastroI) {
    return this.http.put(
      `${environment.urlApigee}${environment.pathSc}/pre-accounts/${preCadastro.id}`,
      preCadastro,
      { headers: this.getNewHeaders() }
    );
  }

  public getCnpj(cnpj: String): Observable<any> {
    return this.http.get<any>(`${environment.urlApigee}${environment.pathSc}/pre-accounts/cnpj/${cnpj}`,
      { headers: this.getNewHeaders() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getPreAccount() {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(`${environment.urlApigee}${environment.pathSc}/pre-accounts/${sellerId}`,
    {headers: this.getNewHeaders()})
    .map(response => {
      return response;
    })
    .catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }
}
