import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

//Service
import { GestaoFreteService } from '../../../services/gestao-frete.service'
import { ErrorMessageService } from '../../../../../src/app/shared/modal/error-message/error-message.service';
import { AuthService } from '../../../../../src/app/shared/auth/auth.service';


interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number;
  error: {
    error: string,
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-sobrescrever-dados',
  templateUrl: './sobrescrever-dados.component.html',
  styleUrls: ['./sobrescrever-dados.component.scss']
})
export class SobrescreverDadosComponent implements OnInit {

  file: File;

  constructor(
    @Optional() private dialogRef: MatDialogRef<SobrescreverDadosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      fileData
    },
    private gestaoFreteService: GestaoFreteService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService
  ) { }

  ngOnInit(): void { 
    
  }

  confirmSubmit(){
    this.postUploadContingencyButton();
  }

  postUploadContingencyButton() {
    const sso = true
    
    this.gestaoFreteService.postUploadContingency(sso).subscribe(
      (resp: ResponseI) => {
        console.log(resp);
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: "Upload realizado com sucesso !"
        });
        this.close('successUpload');
      },
      (error: newReponseI) => {
        this.close('failedUpload');
        if (error.error.error === 'Internal Server Error') {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
        else if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        } 
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        };
      },
    );
  };

  close(data) {
    this.dialogRef.close(data);
  };
}
