export interface WebstoreI {
    id?: number;
    activateSeller?: boolean;
    flagUtilizarIntegrador?: boolean;
    activateAutoSaleProduct?: boolean;
    deadlineDispatch?: number;
    commissioning: string;
    deliveryPolicy:	string;
    description: string;
    installment: BigInteger;
    logoUrl: string;
    name: string;
    returnPolicy: string;
    transferModel: string;
    token?: string;
    cep?: string;
}


export const transferModelMock = [
    {
        name: 'antecipado',
        title: 'Antecipado' 
    },
    {
        name: 'parcelado',
        title: 'Parcelado' 
    }
]