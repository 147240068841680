import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';

//msg erro
import { MessageErrorComponent } from 'src/app/shared/components/message-error/message-error.component';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';

import { ResponsavelModel } from 'src/app/models/responsavel.model';

import { ResponsaveisService } from 'src/app/services/responsaveis.service'

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-adicionar-responsavel',
  templateUrl: './adicionar-responsavel.component.html',
  styleUrls: ['./adicionar-responsavel.component.scss'],
})

export class AdicionarResponsavelComponent implements OnInit {
  public title: string = 'Adicionar um responsável pelo contrato';
  public subTitle: string = 'Crie aqui uma lista de responsáveis por este contrato. Os dados podem ser editados e novos responsáveis podem ser inseridos a qualquer momento.';
  responsavelForm: FormGroup;
  phoneMask = '(00) 00000-0000||(00) 0000-0000';
  responsavelData: any = this.data.responsavelList[this.data.index] || {};
  responsavel;
  objectLegalContacts: any = {};
  controlTableResponsavel = false;

  constructor(
    private dialogRef: MatDialogRef<AdicionarResponsavelComponent>,
    private formBuilder: FormBuilder,
    private errorMessageService: ErrorMessageService,
    private responsavelService: ResponsaveisService,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      responsavelList: [];
      index: number;
    }
  ) { }

  ngOnInit() {
    this.getResponsaveis();
    this.responsavelForm = this.formBuilder.group({
      name: [this.responsavelData.name, Validators.required],
      email: [this.responsavelData.email, [Validators.required, Validators.email],],
      phone: [this.responsavelData.phone, Validators.required],
    });
    this.setPhoneMask();
  }

  getResponsaveis() {
    this.responsavelService.getResponsaveis().subscribe(
      data => {
        this.responsavel = data;
        this.objectLegalContacts = data.legalContacts;
      },
      error => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        }
      } 
    );
  };

  saveResponsavel() {
    if (this.responsavelForm.invalid) {
      return;
    }
    if (this.responsavelForm.valid) {
      const responsavel = this.responsavelForm.getRawValue() as ResponsavelModel;
      if (responsavel.id) {
        console.log('Update ?');
      } else {
        if (this.responsavel === undefined) {
          this.saveResponsavelPost(responsavel);
          this.close();
        } else {
          this.saveResponsavelPatch(responsavel);
          this.close();
        }
      }
    }
  }

  saveResponsavelPost(responsavel: ResponsavelModel) {
    this.responsavelService.saveResponsaveisPost(responsavel).subscribe(
      (resp) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Contato adicionado com sucesso.'
        });
        this.dialogRef.close(true);
        this.getResponsaveis();
      },
      (error: newReponseI) => {
        if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
          this.auth.logoutAndRemoveStorage();
        }
        if (error.status === 403) {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
          this.dialogRef.close(true);
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    );
  }

  saveResponsavelPatch(responsavel: ResponsavelModel) {
    this.responsavelService.saveResponsavelPatch(responsavel).subscribe(
      (resp) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Contato adicionado com sucesso.'
        });
        this.dialogRef.close(true);
        this.getResponsaveis();
      },
      (error: newReponseI) => {
        if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
          this.auth.logoutAndRemoveStorage();
        }
        else if (error.status === 403) {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
          this.dialogRef.close(true);
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    );
  }

  isObjectEmpty(object): boolean {
    return Object.keys(object).length === 0
  }

  setPhoneMask() {
    if (this.phone) {
      this.phoneMask = this.phone.length > 10
        ? '(00) 00000-0000'
        : '(00) 00000-0000||(00) 0000-0000';
    }
  }

  get phone(): string {
    return this.responsavelForm.controls['phone'].value;
  }

  close() {
    this.dialogRef.close();
  }

  criarContato() {
    const reponsavelList = [
      ...this.data.responsavelList,
      this.responsavelForm.value,
    ];
    this.dialogRef.close(reponsavelList);
  };

  editarContato() {
    const responsavelList = this.data.responsavelList as any;
    responsavelList[this.data.index] = this.responsavelForm.value;
    this.dialogRef.close(responsavelList);
  };
};
