import {Component, OnInit, Optional, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

//MODEL
import { ReenviarSenhaModel } from '../../../models/reenviar-senha.model';

//SERVICES
import { UsuarioService } from '../../../services/usuario.service';
import { ErrorMessageService } from '../../modal/error-message/error-message.service';
import { ElementSchemaRegistry } from '@angular/compiler';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-reenviar-senha',
  templateUrl: './reenviar-senha.component.html',
  styleUrls: ['./reenviar-senha.component.scss']
})

export class ReenviarSenhaUsuarioComponent implements OnInit {

  sendReenviaEmail = {} as ReenviarSenhaModel;
  sendReenviaEmails: ReenviarSenhaModel;
  
  hide = true;

  sendEmailForm: FormGroup;
  sendEmailModel: ReenviarSenhaModel;

  constructor(
    @Optional() private dialogRef: MatDialogRef<ReenviarSenhaUsuarioComponent>,
    @Inject(MAT_DIALOG_DATA)public data:{id},
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private errorMessageService: ErrorMessageService,
  ) { };

  get f() {
    return this.sendEmailForm.controls;
  };

  sendEmailRes = [];
  usuarios: ReenviarSenhaModel[];
  users:any = {};
  userObject:any = {};
  
  ngOnInit(): void {
    
    this.getUsuariosFunction();
    
    this.sendEmailForm = this.formBuilder.group({
      email:['', Validators.required]
    });
  };

  getUsuariosFunction(){

    type NewType = any

    this.usuarioService.getUsers().subscribe((usuarios: NewType) => {
      this.usuarios = usuarios;
      this.userObject = usuarios.returnObject;
      this.userObject = this.userObject.users; 
    });
  };

  // Se o contato sera criado ou atualizado
  saveContact() {
    if (this.sendEmailForm.invalid) {
      return;
    }
    if (this.sendEmailForm.valid) {
      const nameUser = this.sendEmailForm.value;
      if(nameUser.id){
        console.log('UPDATE ?')
      }else{
        this.sendEmail(nameUser.email);
      };
    };
  };

  sendEmail(sendEmailRes : ReenviarSenhaModel){
    this.usuarioService.sendEmail(sendEmailRes).subscribe(
      (resp: ResponseI) => { 
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Senha reenviada com sucesso.'
        });
        this.dialogRef.close(true);
      },
      (error: ResponseI) => {
        this.dialogRef.close(false);
        this.errorMessageService.openDialog({
          message: error.error.message,
          messageCode: error.error.messageCode
        });
      }
    );
  };

  disabled() {
    this.hide = false;
  };

  // Fecha formulario
  close() {
    this.dialogRef.close();
  };

  // Limpa formulario
  cleanForm(form: NgForm){
    form.resetForm();
    this.sendReenviaEmail = {} as ReenviarSenhaModel;
  };
};
