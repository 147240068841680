import { Component, OnInit, ViewChild } from '@angular/core';

import { WebstoreI, transferModelMock } from './../../models/webstore.model';
import { WebstoreService } from './../../services/webstore.service';
import { ErrorMessageService } from './../../shared/modal/error-message/error-message.service';
import { MatTabGroup } from '@angular/material/tabs';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { StorageService } from 'src/app/services/storage.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-webstore',
  templateUrl: './webstore.component.html',
  styleUrls: ['./webstore.component.scss']
})
export class WebstoreComponent implements OnInit {

  transferModeOption: any;
  installmentOption: any;

  id: String = "";

  finalPositionArray;

  allWebstoresData;
  allWebStores;
  validArrayWebstore = false;
  newWebstoreClick = false;

  profile: string = 'null';

  newWebStore: WebstoreI = new Object() as WebstoreI;

  @ViewChild("tabs", { static: false }) tabs: MatTabGroup;

  constructor(
    private webstoreService: WebstoreService,
    private errorMessageService: ErrorMessageService,
    private auth: AuthService,
    private storage: StorageService
  ) { 
    this.profile = this.storage.storage.getItem(`profile`);

  }

  ngOnInit(): void {
    this.transferModeOption = transferModelMock;
    this.getAllWebstores();
  }

  async getAllWebstores() {
    const data = this.webstoreService.getWebstore().subscribe(
      (data) => {
        // pega o id se existir
        let id = null;
        this.id = null;
        if (data.length > 0) {
          id = data[0].id;
          this.finalPositionArray = (data[0].webStores.length + 1);
          this.id = id;
          this.getWebstoreById(id);
          return;
        }
        else {
          this.getWebstoreById();
        }
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
          }
        } 
        else {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
        }
      }
    );
    
  }

  getWebstoreById(id: string = '') {
    this.webstoreService.getWebstoreById(id).subscribe(data => {
      this.allWebstoresData = data;
      this.allWebStores = this.allWebstoresData.webStores;
      if (this.allWebStores === undefined || this.allWebStores.length === 0) {
        this.validArrayWebstore = false;

      } else {
        this.validArrayWebstore = true;
      }

    },
    (error: newReponseI) => {
      if (error.status === 403) {
        if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          })
          .afterClosed().subscribe(() => {
            this.auth.logoutAndRemoveStorage();
          });
        }
        else {
          this.errorMessageService.openDialog({
            message: error.error.statusCustom.accessInfo.message,
            messageCode: error.error.statusCustom.profile
          });
        }
      } 
      else {
        this.errorMessageService.openDialog({
          message: error.error.statusCustom.accessInfo.message,
          messageCode: error.error.statusCustom.profile
        });
      }
    }

    );
  }

  newWebstore() {
    const tabGroup = this.tabs;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
    this.newWebstoreClick = true;
    this.allWebStores.push({
      id: this.finalPositionArray,
      name: 'Nova Webstore',
    } as WebstoreI)
    const tabCount = tabGroup._tabs.length;
    tabGroup.selectedIndex = (tabCount + 1);
  }

  createUpdateWebstores() {
    if (this.validArrayWebstore) {
      // Passa o array atualizado
      this.update(this.allWebStores);
    }
    else {
      // Cria um array com o objeto newWebStore
      this.save([this.newWebStore]);
    }
  }

  save(webstore: WebstoreI[]) {
    this.webstoreService.save(webstore).subscribe(
      (resp: ResponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Webstore cadastrada com sucesso. '
        })
        .afterClosed().subscribe(() => {
          this.getAllWebstores();
          document.querySelector("mat-sidenav-content").scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        });
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.getAllWebstores();
              document.querySelector("mat-sidenav-content").scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    );
  }


  update(webstore: WebstoreI[]) {
    this.webstoreService.update(webstore, this.id).subscribe(
      (resp: ResponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'WebStore atualizada com sucesso.'
        })
          .afterClosed().subscribe(() => {
            this.getAllWebstores();
            document.querySelector("mat-sidenav-content").scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          });

      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.getAllWebstores();
              document.querySelector("mat-sidenav-content").scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            });
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
        }
      }
    );
  }

}
