import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { ContatoModel } from '../../../models/contact.model';
import { DEPARTAMENT } from '../../../models/departaments.model';

//services
import { ContatoService } from '../../../services/contato.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { DepartmentsService } from '../../../services/departments.service';
import { AuthService } from '../../auth/auth.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

interface newReponseI {
  status: number;
  error: {
    returnObject: {
      code: number;
      message: string;
      messageCode: string;
    },
    statusCustom: {
      accessInfo: {
        message: string;
        status: string;
      }
      profile: string;
    }
  }
}

@Component({
  selector: 'app-editar-contato',
  templateUrl: './editar-contato.component.html',
  styleUrls: ['./editar-contato.component.scss']
})
export class EditarContatoComponent implements OnInit {

  contact = {} as ContatoModel;
  contacts: ContatoModel;

  hide = true;

  contatoForm: FormGroup;
  contatoModel: ContatoModel;
  departaments: DEPARTAMENT[];

  constructor(
    @Optional() private dialogRef: MatDialogRef<EditarContatoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      idContact,
      idObjectContact,
    },
    private formBuilder: FormBuilder,
    private contatoService: ContatoService,
    private errorMessageService: ErrorMessageService,
    private departmentsService: DepartmentsService,
    private auth: AuthService
  ) { }

  contato = []
  itensUpdate = []
  phoneMask = '';

  ngOnInit(): void {

    this.getDepartaments();
    this.getContactsById();


    this.contatoForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      observation: [''],
      phone: ['', Validators.required],
      responsibility: ['', Validators.required]
    });

    this.contatoForm.get('responsibility').valueChanges.subscribe(item => {
      this.itensUpdate = item
    })
  }

  setPhoneMask() {
    if (this.phone) {
      this.phoneMask = this.phone.length > 10
        ? '(00) 00000-0000'
        : '(00) 00000-0000||(00) 0000-0000';
    }
  }

  get phone(): string {
    return this.contatoForm.controls['phone'].value;
  }

  // Se o contato sera criado ou atualizado
  saveContact(idContact, idObjectContact) {
    if (this.contatoForm.invalid) {
      return;
    }
    if (this.contatoForm.valid) {
      const contato = this.contatoForm.getRawValue() as ContatoModel;
      if (this.data.idContact || this.data.idObjectContact) {
        this.updateContact(contato);
        this.close();
      } else {
        this.close();
      }
    }
  }

  updateContact(contato: any) {
    this.contatoService.updateContact(contato, this.data.idContact, this.data.idObjectContact).subscribe(
      (resp: ResponseI) => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Contato atualizado com sucesso.'
        });
        this.contatoForm.reset({}, { onlySelf: false, emitEvent: false });
      },
      (error: newReponseI) => {
        if (error.status === 403) {
          if (error.error.statusCustom.accessInfo.message === 'Token Expirado') {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            })
            .afterClosed().subscribe(() => {
              this.auth.logoutAndRemoveStorage();
            });
          }
          else {
            this.errorMessageService.openDialog({
              message: error.error.statusCustom.accessInfo.message,
              messageCode: error.error.statusCustom.profile
            });
            this.close();
          }
        }
        else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Ocorreu uma falha, contate o administrador.'
          });
          this.close();
        }
      },
    );
  }

  get f() {
    return this.contatoForm.controls;
  }

  // Chama o serviço de todos os contatos
  getContactsById() {
    this.contatoService.getContactById(this.data.idContact, this.data.idObjectContact).subscribe((contact: ContatoModel) => {
      this.contact = contact;
      this.f.name.setValue(contact.name);
      this.f.email.setValue(contact.email);
      this.f.observation.setValue(contact.observation);
      if(this.contact.phone.length > 10) {
        this.phoneMask = '(00) 00000-0000';
      } else {
        this.phoneMask = '(00) 0000-0000';
      }
      this.f.phone.setValue(contact.phone);
      this.f.responsibility.setValue(contact.responsibility);
    });
  };

  getDepartaments() {
    this.departmentsService.getAllDepartaments().subscribe(dados => {
      this.departaments = dados;
    },
      error => {
        console.log(error);
      }
    );
  }

  disabled() {
    this.hide = false;
  }

  // Fecha formulario
  close() {
    this.dialogRef.close();
  }

  // Limpa formulario
  cleanForm(form: NgForm) {
    form.resetForm();
    this.contact = {} as ContatoModel;
  }

}
