import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, tap, delay, take, retry, catchError } from 'rxjs/operators';
import { ContratoDocumentosModel } from './../models/contratos-documentos.model';

import { environment } from '../../environments/environment';
import { observable, Observable, throwError } from 'rxjs';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class ContratoDocumentosService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
    ) { }

  getContratosDocSign(): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/docusign`,
      { headers: this.getNewHeaders() }
    )
    .map(response => {
      return response;
    })
    .catch((error: any) => {
      return Observable.throw(error);
    });
  };

  getAllUploadDocuments(): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get<any>(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/documents`,
      { headers: this.getNewHeaders() }
    )
    .map(response => {
      return response;
    })
    .catch((error: any) => {
      return Observable.throw(error);
    });
  };

  uploadDocuments(filetype: string, file: File): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    const formdata = new FormData();
    formdata.append('file', file);
    return this.http.post(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/documents/${filetype}`,
      formdata,
      { headers: this.getNewHeadersUpload() })
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  };

  downloadDocuments(id: string): Observable<any> {
    var sellerId = this.storage.storage.getItem(`sellerId`);
    return this.http.get(
      `${environment.urlApigee}${environment.pathSc}/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/documents/${id}`,
      { headers: this.getNewHeaders(), responseType: 'blob' },
      )
      .map(response => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
      
  };

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }

  getNewHeadersUpload() {
    return new HttpHeaders({
      'accept': '*/*',
      'seller-api-version': 'v1',
    });
  }

}
